
import Vue, { computed, defineComponent, getCurrentInstance, onMounted, reactive } from 'vue';
import Draggable from 'vuedraggable';
import { setPageName } from 'src/hooks/displayPageNameHook';
import { wrappedMapGetters } from 'src/hooks/storeHook';
import { vvGetError, vvGetErrorObject, vvReset } from 'src/util/vee_validate';
import { ensureUserRefreshAndMasters } from 'src/hooks/masterHook';
import {
  Report,
  REPORT_MAX_SECTIONS,
  REPORT_MAX_COMPONENTS,
  isReportContainsMaxOrMoreSections,
  isReportContainsMaxOrMoreComponents,
  constructEmptyReport,
} from 'src/models/new/report';
import { notifyError1, notifySuccess1 } from 'src/hooks/notificationHook';
import reportApi from 'src/apis/masters/report';
import sectionApi from 'src/apis/masters/section';
import componentApi from 'src/apis/masters/component';
import { requestTimeout } from 'src/util/request_animation_frame';
import { GridItem, GridLayout } from 'vue-grid-layout';
import { ERROR_GROUP_SYSTEM, ERROR_GROUP_USER, ErrorGroup } from 'src/consts';
import workplaceApi from 'src/apis/masters/workplace';
import MetricsCardFormModal from 'src/views/Dashboard/Settings/Reports/Form/components/MetricsCardFormModal/index.vue';
import CommentFormModal from 'src/views/Dashboard/Settings/Reports/Form/components/CommentFormModal/index.vue';
import MetricsGraphFormModal from 'src/views/Dashboard/Settings/Reports/Form/components/MetricsGraphFormModal/index.vue';
import MetricsListFormModal from 'src/views/Dashboard/Settings/Reports/Form/components/MetricsListFormModal/index.vue';
import MetricsTableFormModal from 'src/views/Dashboard/Settings/Reports/Form/components/MetricsTableFormModal/index.vue';
import { Workplace } from 'src/models/new/workplace';
import SectionView from 'src/views/Dashboard/Settings/Reports/Form/components/SectionView.vue';
import ComponentTypeSelectModal from 'src/views/Dashboard/Settings/Reports/Form/components/ComponentTypeSelectModal.vue';
import { getTemporaryRecordId } from 'src/util/recordId';
import { Component } from 'src/models/new/component';
import { constructEmptySection, Section } from 'src/models/new/section';
import { isComponentTypeMetricsList } from 'src/models/new/Component/MetricsComponent/metricsListComponent';
import { isComponentTypeMetricsCard } from 'src/models/new/Component/MetricsComponent/metricsCardComponent';
import { DATA_HEADER_KEY_WORD_DAY_HEADER } from 'src/util/dataHeader';
import { isComponentTypeMetricsPieChart } from 'src/models/new/Component/MetricsComponent/GraphMetricsComponent/metricsPieChartComponent';
import { isComponentTypeMetricsGraph } from 'src/models/new/Component/MetricsComponent/metricsGraphComponent';
import { isComponentTypeMetricsTransitionGraph } from 'src/models/new/Component/MetricsComponent/GraphMetricsComponent/metricsTransitionGraphComponent';
import { DATE_FNS_DATE_SHORT_SLASH_FORMAT } from 'src/util/Datetime/format';
import { isComponentTypeComment } from 'src/models/new/Component/commentComponent';
import { isComponentTypeMetricsBarGraph } from 'src/models/new/Component/MetricsComponent/GraphMetricsComponent/metricsBarGraphComponent';
import { isComponentTypeMetricsGroupedGraph } from 'src/models/new/Component/MetricsComponent/GraphMetricsComponent/metricsGroupedGraphComponent';
import { isComponentTypeMetricsTable } from 'src/models/new/Component/MetricsComponent/metricsTableComponent';
import AppLink from 'src/components/UIComponents/AppLink.vue';
import ConfirmDeleteSectionModal from './components/ConfirmDeleteSectionModal.vue';
import ConfirmDeleteComponentModal from './components/ConfirmDeleteComponentModal.vue';
import CopyComponentModal from './components/CopyComponentModal.vue';
import InputError from 'src/components/InputError.vue';
import TooltipButton from 'src/components/UIComponents/Tooltip/TooltipButton.vue';
import { TooltipMenuSettings } from 'src/components/UIComponents/Tooltip/TooltipContent/TooltipContent.vue';
import { useWorkplacesProvider } from 'src/composables/asyncResources/useWorkplaces';

interface State {
  pageName: string | null;
  userId: number | null;
  reportId: number | null;
  hasReportGtManagerRole: boolean;
  report: Report;
  reportTooltipMenus: TooltipMenuSettings[];
  selectedSection: Section | null;
  showDeleteSectionModal: boolean;
  selectedComponent: Component | null;
  showComponentTypeSelectionModal: boolean;
  showMetricsCardFormModal: boolean;
  showCommentFormModal: boolean;
  showMetricsGraphFormModal: boolean;
  showMetricsTableFormModal: boolean;
  showMetricsListFormModal: boolean;
  showDeleteComponentModal: boolean;
  showCopyComponentModal: boolean;
  isLayoutEditMode: boolean;
  hasError: boolean;
  isProcessingResult: boolean;
  metricsNameSearchQuery: string | null;
  isLoaded: boolean;
  workplaceOptions: Workplace[];
  isTimeSpanFixed: boolean;
  // モーダルを展開中にモーダル外のフォームのバリデーションを無効化するフラグ
  // FIXME: 元のvee-validateではコンポーネント間でスコープを分けたりできるが、vue2のcomposition-apiではvmIdが
  // vee-validateの通常の利用時と挙動が異なるようで、それによる機能不全を回避するためにこのフラグを利用している
  suppressFormValidation: boolean;
}

const setupState = (root: Vue): State => {
  const state: State = reactive({
    ...wrappedMapGetters(root.$store, 'displayPageName', ['pageName']),
    userId: wrappedMapGetters(root.$store, 'user', ['id']).id,
    hasReportGtManagerRole: computed(() => root.$store.getters['user/hasReportGtManagerRole']),
    report: constructEmptyReport(),
    reportTooltipMenus: computed(() => {
      return [
        {
          text: 'リンク',
          iconClasses: 'fa fa-link',
          link: {
            to: {
              name: 'SettingsReportsEditLinks',
              params: { reportId: String(state.reportId) },
            },
          },
        },
      ];
    }),
    selectedSection: null,
    showDeleteSectionModal: false,
    selectedComponent: null,
    showComponentTypeSelectionModal: false,
    showMetricsCardFormModal: false,
    showCommentFormModal: false,
    showMetricsGraphFormModal: false,
    showMetricsTableFormModal: false,
    showMetricsListFormModal: false,
    showDeleteComponentModal: false,
    showCopyComponentModal: false,
    reportId: computed(() => (root.$route.params.reportId ? Number(root.$route.params.reportId) : null)),
    isLayoutEditMode: false,
    // vvHasErrorの引数はリアクティブではないため、直接参照対象を監視する
    hasError: computed(() => root.$validator.errors.items.length > 0),
    isProcessingResult: false,
    metricsNameSearchQuery: null,
    isLoaded: false,
    // アクセスグループ選択モーダル
    showAccessGroupSelectionModal: false,
    workplaceOptions: [],
    isTimeSpanFixed: computed(() => {
      // 新規の場合、1つ以上メトリクスが設定された時点で期間変更不可
      // これはコンポーネント内におけるメトリクスの期間制約要件によるので、要件によって変更の必要性がある
      return !!state.report.sections.find((section) => {
        return section.components?.find((component) => {
          if (isComponentTypeMetricsCard(component)) {
            // メトリクスカードは登録時に必ず1つのメトリクスが設定されるため、そのメトリクスで期間が固定される
            return true;
          } else if (isComponentTypeMetricsList(component)) {
            return component.data.layout.length > 0;
          } else if (isComponentTypeMetricsTable(component)) {
            return component.data.length > 0;
          } else if (isComponentTypeMetricsPieChart(component)) {
            return component.data.length > 0;
          } else if (isComponentTypeMetricsBarGraph(component)) {
            return component.data.bars.length > 0 || component.data.reference;
          } else if (isComponentTypeMetricsGroupedGraph(component)) {
            return (
              component.data.graphs.some((graph) => !!graph.plots.some((plot) => plot.metrics)) ||
              component.data.reference
            );
          } else if (isComponentTypeMetricsTransitionGraph(component)) {
            return component.data.graphs.some((graph) => !!graph.metrics) || component.data.reference;
          }
          return false;
        });
      });
    }),
    suppressFormValidation: computed(() => {
      return (
        state.showComponentTypeSelectionModal ||
        state.showMetricsCardFormModal ||
        state.showCommentFormModal ||
        state.showMetricsGraphFormModal ||
        state.showMetricsTableFormModal ||
        state.showMetricsListFormModal
      );
    }),
  });

  return state;
};

export default defineComponent({
  components: {
    AppLink,
    TooltipButton,
    Draggable,
    GridLayout,
    GridItem,
    MetricsCardFormModal,
    CommentFormModal,
    MetricsGraphFormModal,
    MetricsListFormModal,
    MetricsTableFormModal,
    SectionView,
    ComponentTypeSelectModal,
    ConfirmDeleteSectionModal,
    ConfirmDeleteComponentModal,
    CopyComponentModal,
    InputError,
  },
  setup() {
    const root = getCurrentInstance()!.proxy;
    const state = setupState(root);

    setPageName(root, 'レポート設定');

    const getError = (fieldName: string): string | null => vvGetError(root, fieldName);
    const getErrorObject = (fieldName: string): object | null => vvGetErrorObject(root, fieldName);
    const clearErrors = (): void => {
      vvReset(root);
    };

    // 現時点ではこのコンポーネント内のloadWorkplaceでworkplaceOptionsを管理しているが、provide/injectの仕組みに切りかえる
    // 2重にロードが走ってしまうが、コード変更の範囲がどんどん広がってしまうので妥協する
    // TODO: useWorkplacesProviderからworkplacesを取り出して使うパターンにリファクタリングする
    useWorkplacesProvider();
    const loadWorkplace = async (): Promise<void> => {
      state.workplaceOptions = await workplaceApi.index();
    };

    const loadReport = async (): Promise<void> => {
      state.report = await reportApi.show(state.reportId!);
      // セクションを表示順にソートする
      state.report.sections = state.report.sections!.sort((a, b) => a.sequentialOrder - b.sequentialOrder);
    };

    const addSection = async (): Promise<void> => {
      if (isReportContainsMaxOrMoreSections(state.report)) {
        notifyError1(root, `セクションの登録数は最大${REPORT_MAX_SECTIONS}個までです。`);
        return;
      }

      const sequentialOrder = state.report.sections!.reduce((max, el) => Math.max(el.sequentialOrder, max), 0) + 1;
      const section = {
        ...constructEmptySection(),
        sequentialOrder: sequentialOrder,
      };
      try {
        const { id } = await sectionApi.create(state.reportId!, section);
        section.id = id;
        state.report.sections.push(section);
        notifySuccess1(root, 'セクションを追加しました');
      } catch (err: any) {
        const errId = 'ERR00001';
        const errStatus = err.response.status;
        if ([403, 404].includes(errStatus)) {
          const msg = 'アクセスする権限がありません。管理者にお問合せください。';
          reportError(ERROR_GROUP_USER, msg, err, '');
        } else if (errStatus === 409) {
          const msg = 'セクションの追加に失敗しました。レポートが編集されています。';
          reportError(ERROR_GROUP_USER, msg, err, '');
        } else {
          const msg = 'セクションの追加に失敗しました。管理者に連絡してください。';
          reportError(ERROR_GROUP_SYSTEM, msg, err, errId);
        }
      }
    };

    const openCreateSkeletonModal = (targetSection: Section): void => {
      clearErrors();
      if (isReportContainsMaxOrMoreComponents(state.report)) {
        notifyError1(root, `コンポーネントの登録数は最大${REPORT_MAX_COMPONENTS}個までです`);
        return;
      }
      state.showComponentTypeSelectionModal = true;
      state.selectedSection = targetSection;
    };
    const onSelectNewComponentType = (component: Component): void => {
      clearErrors();
      // 周期が存在する場合、レポートの周期をコンポーネントにも設定する
      // 新規レポートで未選択の場合は値がないため、最も制限が緩い期間を設定する
      if (isComponentTypeMetricsCard(component)) {
        // 設定なし
      } else if (isComponentTypeMetricsGraph(component)) {
        // 設定なし
      } else if (isComponentTypeMetricsTable(component)) {
        // 設定なし
      } else if (isComponentTypeMetricsList(component)) {
        component.dateFormat = DATE_FNS_DATE_SHORT_SLASH_FORMAT;
        component.headers.head.layout.data = component.headers.head.layout.data.map((el) => {
          return el.level === 1 && el.position === 1 ? { ...el, value: DATA_HEADER_KEY_WORD_DAY_HEADER } : el;
        });
      } else if (isComponentTypeComment(component)) {
        // 設定なし
      }
      // レポート内でユニークになるようにダミーidを附番する
      component.id = getTemporaryRecordId(state.report.sections.flatMap((section) => section.components));

      editComponent(state.selectedSection!, component);
    };
    const closeCreateSkeletonModal = (): void => {
      state.showComponentTypeSelectionModal = false;
    };

    const mutateSection = (section: Section, next: Section | null): void => {
      state.report.sections = state.report.sections
        .map((el) => {
          return el.id === section.id ? next : el;
        })
        .filter((el) => el) as Section[];
    };

    const openDeleteSectionModal = (section: Section): void => {
      state.selectedSection = section;
      state.showDeleteSectionModal = true;
    };

    const closeDeleteSectionModal = (): void => {
      state.selectedSection = null;
      state.showDeleteSectionModal = false;
    };

    const deleteSection = async (): Promise<void> => {
      if (state.selectedSection === null) {
        return;
      }

      try {
        await sectionApi.delete(state.reportId!, state.selectedSection.id);
        mutateSection(state.selectedSection, null);
        notifySuccess1(root, 'セクションを削除しました');
        closeDeleteSectionModal();
      } catch (err: any) {
        const errStatus = err.response.status;
        if ([403, 404].includes(errStatus)) {
          const msg = 'アクセスする権限がありません。管理者にお問合せください。';
          reportError(ERROR_GROUP_USER, msg, err, '');
        } else {
          const msg = 'セクションの削除に失敗しました。管理者に連絡してください。';
          reportError(ERROR_GROUP_SYSTEM, msg, err, '');
        }
      }
    };

    const openDeleteComponentModal = (section: Section, component: Component): void => {
      state.selectedSection = section;
      state.selectedComponent = component;
      state.showDeleteComponentModal = true;
    };

    const closeDeleteComponentModal = (): void => {
      state.selectedSection = null;
      state.selectedComponent = null;
      state.showDeleteComponentModal = false;
    };

    const deleteComponent = async (): Promise<void> => {
      if (state.selectedSection === null || state.selectedComponent === null) {
        return;
      }
      if (state.selectedSection.components.every((el) => el.id !== state.selectedComponent?.id)) {
        return;
      }

      try {
        await componentApi.delete(state.reportId!, state.selectedComponent.id);
        mutateSection(state.selectedSection, {
          ...state.selectedSection,
          components: state.selectedSection.components.filter((el) => el.id !== state.selectedComponent?.id),
        });
        notifySuccess1(root, 'コンポーネントを削除しました');
        closeDeleteComponentModal();
      } catch (err: any) {
        const errStatus = err.response.status;
        if ([403, 404].includes(errStatus)) {
          const msg = 'アクセスする権限がありません。管理者にお問合せください。';
          reportError(ERROR_GROUP_USER, msg, err, '');
        } else {
          const msg = 'コンポーネントの削除に失敗しました。管理者に連絡してください。';
          reportError(ERROR_GROUP_SYSTEM, msg, err, '');
        }
      }
    };

    // componentの追加編集モーダル
    const editComponent = async (targetSection: Section, component: Component): Promise<void> => {
      clearErrors();
      if (isComponentTypeMetricsCard(component)) {
        state.showMetricsCardFormModal = true;
      } else if (isComponentTypeComment(component)) {
        state.showCommentFormModal = true;
      } else if (isComponentTypeMetricsGraph(component)) {
        state.showMetricsGraphFormModal = true;
      } else if (isComponentTypeMetricsTable(component)) {
        state.showMetricsTableFormModal = true;
      } else if (isComponentTypeMetricsList(component)) {
        state.showMetricsListFormModal = true;
      }
      state.selectedComponent = reactive(structuredClone(component));
      state.selectedSection = targetSection;
    };

    const closeEditModal = (): void => {
      clearErrors();
      state.showMetricsCardFormModal = false;
      state.showCommentFormModal = false;
      state.showMetricsGraphFormModal = false;
      state.showMetricsTableFormModal = false;
      state.showMetricsListFormModal = false;
      state.selectedSection = null;
      state.selectedComponent = null;
    };

    const onComponentUpdated = async (): Promise<void> => {
      const targetSection = state.selectedSection!;
      const targetComponent = state.selectedComponent!;

      // レポートオブジェクトに反映
      state.report = {
        ...state.report,
        sections: state.report.sections.map((section) => {
          if (section.id !== targetSection.id) {
            return section;
          }
          const components = section.components!.find((component) => component.id === targetComponent.id)
            ? section.components!.map((component) => {
                return component.id === targetComponent.id ? targetComponent : component;
              })
            : [...section.components, targetComponent];
          return { ...section, components: components };
        }),
      };

      closeEditModal();
    };

    const openCopyComponentModal = (section: Section, component: Component): void => {
      if (isReportContainsMaxOrMoreComponents(state.report)) {
        notifyError1(root, `コンポーネントの登録数は最大${REPORT_MAX_COMPONENTS}個までです`);
        return;
      }

      state.selectedSection = section;
      state.selectedComponent = component;
      state.showCopyComponentModal = true;
    };

    const closeCopyComponentModal = (): void => {
      state.selectedSection = null;
      state.selectedComponent = null;
      state.showCopyComponentModal = false;
    };

    const onCopyComponent = () => {
      notifySuccess1(root, 'コンポーネントをコピーしました');
      closeCopyComponentModal();
      loadReport();
    };

    const reportError = (errorGroup: ErrorGroup, message: string, error: any, errorId: string): void => {
      const formattedMessage =
        errorGroup === ERROR_GROUP_SYSTEM
          ? `(ERR: ${state.pageName} ${errorId ? ` ${errorId}` : ''}, user_id:${state.userId})`
          : message;

      notifyError1(root, formattedMessage, error);
    };

    const editLayout = (): void => {
      state.isLayoutEditMode = true;
    };

    const saveLayout = async (): Promise<void> => {
      // 二重処理防止
      if (state.isProcessingResult) {
        return;
      }

      state.isProcessingResult = true;
      requestTimeout(() => {
        state.isProcessingResult = false;
      }, 300);

      const params = {
        components: state.report.sections.flatMap((section) =>
          section.components.map((el) => ({
            id: el.id,
            abscissa: el.abscissa,
            ordinate: el.ordinate,
            width: el.width,
            height: el.height,
          })),
        ),
      };
      try {
        await componentApi.bulkUpdateLayout(state.reportId!, params);
        state.isLayoutEditMode = false;
        notifySuccess1(root, 'レイアウトを保存しました');
      } catch (err: any) {
        const errStatus = err.response.status;
        if ([403, 404].includes(errStatus)) {
          const msg = 'アクセスする権限がありません。管理者にお問合せください。';
          reportError(ERROR_GROUP_USER, msg, err, '');
        } else {
          const msg = 'レイアウトの保存に失敗しました。管理者に連絡してください。';
          reportError(ERROR_GROUP_SYSTEM, msg, err, '');
        }
      }
    };

    onMounted(async () => {
      // Vue 2x 暫定措置 3x系の場合はonUnmountedでフラグを戻す
      // Vue 2x ではonUnmountedがdestroyedに対するフックのエイリアスであるためonMountedの先頭に記述している
      state.isLoaded = false;
      // ログインユーザー情報をAPIで再取得
      await ensureUserRefreshAndMasters(root);

      // 権限がない場合
      if (!state.hasReportGtManagerRole) {
        location.href = '/settings/reports';
        return;
      }
      // EditでreportIdが渡ってこない場合(新規作成しててリロードしたような場合?)は一覧に戻る
      if (root.$route.name === 'SettingsReportsEdit' && !state.reportId) {
        await root.$router.replace({ name: 'SettingsReports' });
        return;
      }

      await Promise.all([loadReport(), loadWorkplace()]);

      state.isLoaded = true;
    });

    return {
      state,
      getError,
      getErrorObject,
      addSection,
      editComponent,
      closeEditModal,
      openCreateSkeletonModal,
      onSelectNewComponentType,
      closeCreateSkeletonModal,
      onComponentUpdated,
      mutateSection,
      openDeleteSectionModal,
      closeDeleteSectionModal,
      deleteSection,
      reportError,
      openDeleteComponentModal,
      closeDeleteComponentModal,
      deleteComponent,
      openCopyComponentModal,
      closeCopyComponentModal,
      onCopyComponent,
      editLayout,
      saveLayout,
    };
  },
});
