import { TimeSpan } from 'src/business/timeSpan'
import { Report } from 'src/models/new/report'

export type ReportCreateRequestParameters = {
  name: string
  time_span: TimeSpan
  is_enabled: boolean
  report_access_groups: {
    id: number
  }[]
}

export const convertToReportCreateRequestParameters = (report: Report): ReportCreateRequestParameters => {
  return {
    name: report.name,
    time_span: report.timeSpan,
    is_enabled: report.isEnabled,
    report_access_groups: report.accessGroupIds.map(el => {
      return { id: el }
    })
  }
}
