
import Vue, { defineComponent, getCurrentInstance, PropType, reactive, onMounted } from 'vue';
import { ERROR_REASON_INVALID, ERROR_GROUP_USER, ERROR_GROUP_SYSTEM } from 'src/consts';
import metricsValueApi from 'src/apis/metricsValue';
import { MetricsValueCheckAuthorizationRequestParameters } from 'src/models/api/Metrics/MetricsValue/metricsValueCheckAuthorizationRequestParameters';
import { MetricsValueUpsertRequestParameters } from 'src/models/api/Metrics/MetricsValue/metricsValueUpsertRequestParameters';
import { Metrics } from 'src/models/new/metrics';

type MutableMetricsValueAttributes = {
  value: number;
};

const DEFAULT_METRICS_VALUE = 0;

interface State {
  updateAttributes: MutableMetricsValueAttributes;
  authorized: boolean;
}

function setupState(root: Vue): State {
  const state: State = reactive({
    updateAttributes: { value: DEFAULT_METRICS_VALUE },
    authorized: false,
  });
  return state;
}

export default defineComponent({
  props: {
    metrics: {
      type: Object as PropType<Metrics>,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
  },
  emits: ['updated', 'close', 'shouldReport'],
  setup(props, { emit }) {
    const root = getCurrentInstance()!.proxy;
    const state = setupState(root);
    const name = 'UpdateMetricsValueModal';

    const close = (): void => {
      emit('close');
    };

    const update = async (): Promise<void> => {
      const reqParams: MetricsValueUpsertRequestParameters = {
        dt: props.metrics.closingDate!,
        metrics_id: props.metrics.id,
        value: state.updateAttributes.value,
      };
      try {
        await metricsValueApi.upsert(reqParams);
        emit('updated', `${props.label}のメトリクス値を更新しました。`);
      } catch (err: any) {
        const errStatus = err.response.status;
        const errRes = err.response.data || {};
        if ([403, 404].includes(errStatus)) {
          const msg = `${props.label}の書き込み権限がありません。管理者にお問合せください。`;
          emit('shouldReport', ERROR_GROUP_USER, msg, err);
        } else if (errStatus === 400 && errRes.reason === ERROR_REASON_INVALID) {
          emit('shouldReport', ERROR_GROUP_USER, errRes.message, err);
        } else {
          const msg = 'メトリクス値の更新に失敗しました。管理者に連絡してください。';
          emit('shouldReport', ERROR_GROUP_SYSTEM, msg, err);
        }
      }
    };

    const authenticate = async (): Promise<void> => {
      const reqParams: MetricsValueCheckAuthorizationRequestParameters = {
        metrics_id: props.metrics.id!,
      };
      try {
        await metricsValueApi.checkAuthorization(reqParams);
        state.authorized = true;
      } catch (err: any) {
        const errStatus = err.response?.status;
        if ([403, 404].includes(errStatus)) {
          const msg = `${props.label}の書き込み権限がありません。管理者にお問合せください。`;
          emit('shouldReport', ERROR_GROUP_USER, msg, err);
        } else {
          const msg = 'メトリクス値の更新に失敗しました。管理者に連絡してください。';
          emit('shouldReport', ERROR_GROUP_SYSTEM, msg, err);
        }
      }
    };

    onMounted(async () => {
      // 認証APIにリクエストし、認証されたら表示、認証されなければ閉じるシグナルを親に返却
      state.authorized = false;
      await authenticate();
      if (!state.authorized) close();
      // コンポーネントがノード追加されたタイミングで入力値をセット
      state.updateAttributes.value = props.metrics.value || DEFAULT_METRICS_VALUE;
    });

    return { name, state, props, close, update };
  },
});
