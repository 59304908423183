import { User } from 'src/models/new/user';
import {
  AccessGroupCoreParameters,
  isAccessGroupAdmin,
  isAccessGroupManager,
  isAccessGroupReadWrite,
} from 'src/models/new/accessGroup';
import { Report } from 'src/models/new/report';

export type LoginUser = User & {
  isSuperAdmin: boolean;
  reportAccessGroups: AccessGroupCoreParameters[];
  metricsAccessGroups: AccessGroupCoreParameters[];
  reportGrantedByAccessGroups: AccessGroupCoreParameters[];
};

export const isLoginUserReportAdmin = (loginUser: LoginUser): boolean => {
  return loginUser.reportAccessGroups.some((accessGroup) => isAccessGroupAdmin(accessGroup));
};

export const isLoginUserAuthorizedToEditReport = (loginUser: LoginUser, report: Report): boolean => {
  const effectiveAccessGroups = loginUser.reportAccessGroups.filter((accessGroup) => {
    return isAccessGroupAdmin(accessGroup) || isAccessGroupManager(accessGroup);
  });
  return effectiveAccessGroups.some((accessGroup) => report.accessGroupIds.includes(accessGroup.id));
};

export const isLoginUserAuthorizedToWriteCommentOnReport = (loginUser: LoginUser, report: Report): boolean => {
  const effectiveAccessGroups = loginUser.reportAccessGroups
    .concat(loginUser.reportGrantedByAccessGroups)
    .filter((accessGroup) => {
      return (
        isAccessGroupAdmin(accessGroup) || isAccessGroupManager(accessGroup) || isAccessGroupReadWrite(accessGroup)
      );
    });
  return effectiveAccessGroups.some((accessGroup) => report.accessGroupIds.includes(accessGroup.id));
};

export const isLoginUserMetricsAdmin = (loginUser: LoginUser): boolean => {
  return loginUser.metricsAccessGroups.some((accessGroup) => isAccessGroupAdmin(accessGroup));
};
