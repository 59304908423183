import dashboardRoutes from './dashboard'

const routes = [
  {
    path: '/',
    component: require('src/views/Dashboard.vue').default,
    children: dashboardRoutes,
  },
  {
    path: '/password-reset/:token',
    name: 'PasswordReset',
    component: require('src/views/PasswordReset.vue').default
  },
  // catch all
  {
    path: '*',
    redirect: { name: 'Login' },
  }
]

if (process.env.VUE_APP_USE_LOGI_AUTH !== 'true') {
  // can logged in without IDP only for development mode and when VUE_APP_USE_LOGI_AUTH is turned off.
  routes.splice(1, 0, {
    path: '/login',
    name: 'Login',
    component: require('src/views/Login.vue').default
  })
} else {
  // For non development environment, MUST login with IDP
  routes.splice(1, 0, {
    path: '/login',
    name: 'Login',
    component: require('src/views/GotoLogin.vue').default
  })
  // Just for development usage, can logged in directly from /manual-login URL.
  if (process.env.NODE_ENV === 'development') {
    routes.splice(2, 0, {
      path: '/manual-login',
      name: 'ManualLogin',
      component: require('src/views/Login.vue').default
    })
  }
}

export default routes
