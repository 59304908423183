import { ChartLegendLabelItem } from 'chart.js'
import { ChartScaleLabel } from 'src/components/UIComponents/Charts/shared'
import { ChartItem, getValueOnGraphFromGraphItem } from 'src/util/Chart/custom/shared'
import { ExtendedChartData } from 'src/util/Chart/shared'

export type CustomBarLineGraphItem = ChartItem & {
  originalColor: string
}

type CustomBarLineGraphSettings = {
  order: number
  label: string
  scale: string
}

export type CustomBarGraphDataDataset = {
  type: 'bar'
  data: number[]
  actualData: Array<number | null>
  order: number
  label: string
  yAxisID: string
  backgroundColor: string[]
  borderColor: string[]
  borderWidth: number
  fill: boolean
  barPercentage: number
  categoryPercentage: number
  // 凡例の色を条件付き書式によって上書きされないために、Chart.jsの型には存在しない拡張プロパティを設定して使用する
  originalColor: string[]
}

export type CustomLineGraphDataDataset = {
  type: 'line'
  data: number[]
  actualData: Array<number | null>
  order: number
  label: string
  yAxisID: string
  borderColor: string
  borderWidth: number
  fill: boolean
  lineTension: number
  pointBackgroundColor: string[]
  pointBorderColor: string[]
}

type BarLineChartDatasets = CustomBarGraphDataDataset | CustomLineGraphDataDataset

type BarLineChart = Chart & {
  data?: ExtendedChartData & {
    datasets?: BarLineChartDatasets[]
  }
}

export type CustomBarLineGraphOptions = {
  legend: {
    display: true,
    labels: {
      generateLabels: (chart: BarLineChart) => ChartLegendLabelItem[]
      usePointStyle: true
    }
  }
}

const BORDER_WIDTH = 3
const BAR_PERCENTAGE = 0.8
const CATEGORY_PERCENTAGE = 0.6
const LINE_TENSION = 0
const LINE_LEGEND_WIDTH = 3

export const getOptionForBarLineGraph = (): CustomBarLineGraphOptions => {
  return {
    legend: {
      display: true,
      labels: {
        generateLabels: function(chart: BarLineChart): ChartLegendLabelItem[] {
          return chart.data.datasets!.map((datasets: BarLineChartDatasets, index: number) => {
            const chartType = datasets.type
            const legendColor = (
              'originalColor' in datasets
                ? datasets.originalColor
                : datasets.pointBackgroundColor
            )[0] || ''

            return {
              datasetIndex: index,
              text: datasets.label!,
              hidden: !chart.isDatasetVisible(index),
              fillStyle: legendColor,
              strokeStyle: chartType === 'line' ? legendColor : 'transparent',
              lineWidth: LINE_LEGEND_WIDTH,
              pointStyle: chartType === 'line' ? 'line' : 'rect',
            }
          })
        },
        usePointStyle: true,
      },
    },
  }
}

export const getCustomScaleLabelsForBarLineGraph = (leftUnit: string | null, rightUnit: string | null): ChartScaleLabel[] => {
  return [
    {
      axisId: 'y-axis-1',
      label: leftUnit ? `(${leftUnit})` : '',
    },
    {
      axisId: 'y-axis-2',
      label: rightUnit ? `(${rightUnit})` : '',
    },
  ]
}

export const barDatasetFromBarGraphItem = (graphItems: CustomBarLineGraphItem[], options: CustomBarLineGraphSettings): CustomBarGraphDataDataset => {
  const chartValues = graphItems.map(el => getValueOnGraphFromGraphItem(el))
  const originalValues = chartValues.map((value, index) => graphItems[index].value !== null ? value : null)
  const colors = graphItems.map(el => `#${el.color.replaceAll('#', '')}`)
  const originalColor = graphItems.map(el => `#${el.originalColor.replaceAll('#', '')}`)

  return {
    type: 'bar',
    data: chartValues,
    actualData: originalValues,
    order: options.order,
    label: options.label,
    yAxisID: options.scale === 'left' ? 'y-axis-1' : 'y-axis-2',
    backgroundColor: colors,
    borderColor: colors,
    borderWidth: BORDER_WIDTH,
    fill: true,
    barPercentage: BAR_PERCENTAGE,
    categoryPercentage: CATEGORY_PERCENTAGE,
    originalColor: originalColor,
  }
}

export const lineDatasetFromBarGraphItem = (graphItems: CustomBarLineGraphItem[], options: CustomBarLineGraphSettings): CustomLineGraphDataDataset => {
  const chartValues = graphItems.map(el => getValueOnGraphFromGraphItem(el))
  const originalValues = chartValues.map((value, index) => graphItems[index].value !== null ? value : null)
  const colors = graphItems.map(el => `#${el.color.replaceAll('#', '')}`)
  const initialPlotColor = colors.length > 0 ? colors[0] : 'transparent'

  return {
    type: 'line',
    data: chartValues,
    actualData: originalValues,
    order: options.order,
    label: options.label,
    yAxisID: options.scale === 'left' ? 'y-axis-1' : 'y-axis-2',
    borderColor: initialPlotColor,
    borderWidth: BORDER_WIDTH,
    fill: false,
    lineTension: LINE_TENSION,
    pointBackgroundColor: colors,
    pointBorderColor: colors,
  }
}
