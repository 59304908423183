import { computed, type ComputedRef, type Ref } from 'vue';
import type { DataSourceKey } from 'src/models/dataSourceKey';
import dataSourceKeyApi from 'src/apis/masters/dataSourceKey';
import dataSourceParameterValueApi from 'src/apis/dataSourceParameterValue';
import { generateInjectionKey } from './logics/generateInjectionKey';
import { useAsyncResources, useAsyncResourcesContext, useAsyncResourcesProvider } from './useAsyncResources';
import type { DataSourceParameterValue } from 'src/models/dataSourceParameterValue';
import type { RecordId } from 'src/util/recordId';
import type { LogiCoredataBudgetGroup } from 'src/models/new/logiCoredataBudgetGroup';
import type { DataSourceParameter } from 'src/models/dataSourceParameter';

type ExtendedDataSourceParameter = DataSourceParameter & {
  dataSourceKeyId: RecordId;
};

type ExtendedDataSourceParameterValue = DataSourceParameterValue & {
  budgetGroupId: RecordId;
  dataSourceParameterId: RecordId;
};

type UseDataSourcesProviderArgs = {
  onLoad?: () => unknown;
};

type UseDataSourcesProviderResult = {
  dataSourceKeysRef: ReturnType<typeof useAsyncResourcesProvider<DataSourceKey>>['resources'];
  dataSourceParametersRef: ComputedRef<ExtendedDataSourceParameter[]>;
};

type UseDataSourcesResult = {
  dataSourceKeysRef: ReturnType<typeof useAsyncResourcesProvider<DataSourceKey>>['resources'];
  dataSourceParametersRef: ComputedRef<ExtendedDataSourceParameter[]>;
  dataSourceParameterValuesRef: Ref<ExtendedDataSourceParameterValue[]>;
  inquireEndpointByKeyId: (dataSourceKeyId: RecordId) => string | null;
  prepareDataSourceParameterValues: (
    budgetGroup: LogiCoredataBudgetGroup,
    dataSourceParameterId: RecordId,
  ) => Promise<void>;
};

const definitionKey = generateInjectionKey('dataSources');
const remoteValueKey = generateInjectionKey('dataSourcesParameterValues');

const fetchDataSources = async () => {
  const result = await dataSourceKeyApi.index();
  return result.sort((a: DataSourceKey, b: DataSourceKey) => (a.disp_order > b.disp_order ? 1 : -1));
};

const buildSourceList = (dataSourceKeys: DataSourceKey[]): ExtendedDataSourceParameter[] => {
  return dataSourceKeys
    .map((dataSourceKey) => {
      return dataSourceKey.data_source_parameters.map((dataSourceParameter) => {
        return {
          ...dataSourceParameter,
          dataSourceKeyId: dataSourceKey.id,
        };
      });
    })
    .flat();
};

export const useDataSourcesProvider = ({ onLoad }: UseDataSourcesProviderArgs = {}): UseDataSourcesProviderResult => {
  const { resources: dataSourceKeysRef } = useAsyncResourcesProvider<DataSourceKey>({
    key: definitionKey,
    fetcher: fetchDataSources,
    onLoad,
  });
  // 第2引数はダミー関数
  // useDataSourcesではデータロードをこのモジュール内でオーバーライドする為
  // 内部モジュールは正規のデータ取得関数を持つ必要が無い
  useAsyncResourcesContext<ExtendedDataSourceParameterValue>({ key: remoteValueKey, fetcher: async () => [] });

  const dataSourceParametersRef = computed(() => buildSourceList(dataSourceKeysRef.value));

  return {
    dataSourceKeysRef,
    dataSourceParametersRef,
  };
};

export const useDataSources = (): UseDataSourcesResult => {
  const { resources: dataSourceKeysRef } = useAsyncResources<DataSourceKey>({ key: definitionKey });

  const dataSourceParametersRef = computed(() => buildSourceList(dataSourceKeysRef.value));

  // 動的にデータをロードするが、ロードした値はキャッシュに保存して使い回す仕組みの為
  // useDynamicAsyncResourcesではなくuseAsyncResourcesを使用する
  // ロード部分はこのモジュール内に実装する
  const { resources: dataSourceParameterValuesRef } = useAsyncResources<ExtendedDataSourceParameterValue>({
    key: remoteValueKey,
  });

  const prepareDataSourceParameterValues = async (
    budgetGroup: LogiCoredataBudgetGroup,
    dataSourceParameterId: RecordId,
  ) => {
    // 既にロード済みと思われる場合はロードしない
    // バックエンドに該当データがない場合は判断が付かず再ロードしてしまうが、レアケースなので許容した
    if (
      dataSourceParameterValuesRef.value.some((el) => {
        return el.budgetGroupId === budgetGroup.id && el.dataSourceParameterId === dataSourceParameterId;
      })
    ) {
      return;
    }

    const result = await dataSourceParameterValueApi.index({
      workplace_id: budgetGroup.logiCoredataWorkplaceId,
      data_source_parameter_id: dataSourceParameterId,
      budget_group_id: budgetGroup.id,
    });
    // エラー回避: dataSourceParameterValueApi.indexがnullを返すことがあるためfalsyなら中断する
    if (result === null) {
      return;
    }
    const newData = result.map((el) => {
      return {
        ...el,
        budgetGroupId: budgetGroup.id,
        dataSourceParameterId: dataSourceParameterId,
      };
    });
    // id順のソートを期待するが、取得側ではソートを行わない
    // これはdataSourceParameterがis_holidayである時、期待される並び順とid順が逆である為

    dataSourceParameterValuesRef.value = [...dataSourceParameterValuesRef.value, ...newData];
  };

  const inquireEndpointByKeyId = (dataSourceKeyId: RecordId): string | null => {
    return dataSourceKeysRef.value.find((dataSourceKey) => dataSourceKey.id === dataSourceKeyId)?.endpoint ?? null;
  };

  return {
    dataSourceKeysRef,
    dataSourceParametersRef,
    dataSourceParameterValuesRef,
    inquireEndpointByKeyId,
    prepareDataSourceParameterValues,
  };
};
