
import { vvGetError, vvGetErrorObject, vvHasError, vvValidate } from 'src/util/vee_validate';
import { computed, defineComponent, getCurrentInstance, onMounted, PropType, reactive } from 'vue';
import { ReportAccessGroup } from 'src/models/new/AccessGroup/reportAccessGroup';
import {
  ERROR_GROUP_SYSTEM,
  ERROR_GROUP_USER,
  ERROR_REASON_ACCESS_GROUP_NOT_FOUND,
  ERROR_REASON_UNIQUE,
  TIME_SPANS,
} from 'src/consts';
import reportApi from 'src/apis/masters/report';
import SelectAccessGroupForm from 'src/components/NewSelectItemForm/SelectAccessGroupForm.vue';
import { requestTimeout } from 'src/util/request_animation_frame';
import { isAccessGroupAdmin } from 'src/models/new/accessGroup';
import { Report, REPORT_ACCESS_GROUP_MAX_COUNT } from 'src/models/new/report';
import { isRecordIdValid } from 'src/util/recordId';
import reportAccessGroupApi from 'src/apis/masters/reportAccessGroup';
import InputError from 'src/components/InputError.vue';
import { useSimpleEvent } from 'src/composables/useSimpleEvent';
import { useLoginUser } from 'src/composables/useLoginUser';

interface State {
  // リクエスト送信処理中であることを表すフラグ
  isRequesting: boolean;
  showReportAccessGroupSelectForm: boolean;
  validations: Record<string, Object>;
  hasError: boolean;
  report: Report;
  reportIsNew: boolean;
  userSelectReportAccessGroups: Array<ReportAccessGroup>;
  reportAccessGroupsOnReport: Array<ReportAccessGroup>;
  reportAccessGroupsOnReportWithoutAdmin: Array<ReportAccessGroup>;
  // 新規データの場合はnullになる
  reportAccessGroupOnReportWithAdmin: ReportAccessGroup | null;
}

const CONFIRM_ACCESS_GROUP_EVENT_KEY = 'confirmSelectAccessGroupForm';

export default defineComponent({
  components: {
    SelectAccessGroupForm,
    InputError,
  },
  props: {
    value: {
      type: Object as PropType<Report>,
      required: true,
    },
  },
  emits: ['input', 'updated', 'close', 'shouldReport'],
  setup(props, { emit }) {
    const root = getCurrentInstance()!.proxy;
    const state: State = reactive({
      isRequesting: false,
      showReportAccessGroupSelectForm: false,
      validations: {
        name: { required: true, max: 50 },
      },
      hasError: computed(() => vvHasError(root)),
      report: computed({
        get() {
          return props.value;
        },
        set(next) {
          emit('input', structuredClone(next));
        },
      }),
      reportIsNew: computed(() => !isRecordIdValid(state.report.id)),
      userSelectReportAccessGroups: computed({
        get(): Array<ReportAccessGroup> {
          return state.reportAccessGroupsOnReportWithoutAdmin;
        },
        set: (value: Array<ReportAccessGroup>) => {
          state.reportAccessGroupsOnReport = state.reportAccessGroupOnReportWithAdmin
            ? [state.reportAccessGroupOnReportWithAdmin, ...value]
            : value;
        },
      }),
      reportAccessGroupsOnReport: [],
      reportAccessGroupsOnReportWithoutAdmin: computed((): Array<ReportAccessGroup> => {
        return state.reportAccessGroupsOnReport.filter((el: ReportAccessGroup) => !isAccessGroupAdmin(el));
      }),
      reportAccessGroupOnReportWithAdmin: computed((): ReportAccessGroup | null => {
        return state.reportAccessGroupsOnReport.find((el) => isAccessGroupAdmin(el)) ?? null;
      }),
    });

    const { refreshLoginUser } = useLoginUser();

    const getError = (fieldName: string): string | null => vvGetError(root, fieldName);
    const getErrorObject = (fieldName: string): object | null => vvGetErrorObject(root, fieldName);

    const goToAccessGroupSelectForm = (): void => {
      state.showReportAccessGroupSelectForm = true;
    };
    const backToMainForm = (): void => {
      state.showReportAccessGroupSelectForm = false;
    };

    // See: SelectAccessGroupForm
    const setSelectedAccessGroups = (reportAccessGroups: Array<ReportAccessGroup>): void => {
      state.userSelectReportAccessGroups = reportAccessGroups;
      backToMainForm();
    };

    const { triggerer: confirmEventTriggerer } = useSimpleEvent(CONFIRM_ACCESS_GROUP_EVENT_KEY);

    const triggerConfirmEvent = () => {
      confirmEventTriggerer.trigger();
    };

    const setInitialReportAccessGroups = async (): Promise<void> => {
      state.reportAccessGroupsOnReport = await reportAccessGroupApi.byReport(state.report);
    };

    const close = (): void => emit('close');

    const save = async (): Promise<void> => {
      // 二重処理防止
      if (state.isRequesting) {
        return;
      }
      state.isRequesting = true;
      requestTimeout(() => {
        state.isRequesting = false;
      }, 300);

      if (!(await vvValidate(root))) {
        return;
      }

      const report: Report = {
        ...state.report,
        accessGroupIds: state.userSelectReportAccessGroups.map((reportAccessGroup) => reportAccessGroup.id),
      };
      const operationWord = state.reportIsNew ? '作成' : '更新';
      try {
        if (state.reportIsNew) {
          const response = await reportApi.create(report);
          root.$router.push({
            name: 'SettingsReportsEdit',
            params: {
              reportId: String(response.id),
            },
          });
        } else {
          await reportApi.update(report);
        }
        emit('updated', `${state.report.name} を${operationWord}しました。`);
      } catch (err: any) {
        const errId = 'ERR00001';
        const errStatus = err.response.status;
        const errRes = err.response.data || {};
        if ([403, 404].includes(errStatus)) {
          const msg = 'アクセスする権限がありません。管理者にお問合せください。';
          emit('shouldReport', ERROR_GROUP_USER, msg, err);
          await refreshLoginUser();
        } else if (errStatus === 400 && errRes.reason === ERROR_REASON_ACCESS_GROUP_NOT_FOUND) {
          const msg = '選択したレポートグループは存在しません。ページを更新し、再度お試しください';
          emit('shouldReport', ERROR_GROUP_USER, msg, err);
        } else if (errStatus === 400 && errRes.reason === ERROR_REASON_UNIQUE) {
          emit('shouldReport', ERROR_GROUP_USER, errRes.message, err);
        } else {
          const msg = `レポートの${operationWord}に失敗しました。管理者に連絡してください。`;
          // TODO: notifyのオプションと統合して、引数が長くならないように、ページ間で異ならないように統一する
          emit('shouldReport', ERROR_GROUP_SYSTEM, msg, err, errId);
        }
      }
    };

    onMounted(async () => {
      state.showReportAccessGroupSelectForm = false;
      await setInitialReportAccessGroups();
    });

    return {
      state,
      props,
      getError,
      getErrorObject,
      REPORT_ACCESS_GROUP_MAX_COUNT,
      TIME_SPANS,
      goToAccessGroupSelectForm,
      backToMainForm,
      setSelectedAccessGroups,
      CONFIRM_ACCESS_GROUP_EVENT_KEY,
      triggerConfirmEvent,
      save,
      close,
    };
  },
});
