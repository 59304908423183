
import Vue, { computed, defineComponent, getCurrentInstance, onMounted, provide, reactive } from 'vue'
import { setPageName } from 'src/hooks/displayPageNameHook'
import { wrappedMapGetters } from 'src/hooks/storeHook'
import { ensureUserRefreshAndMasters } from 'src/hooks/masterHook'
import { Report } from 'src/models/new/report'
import reportApi from 'src/apis/masters/report'
import workplaceApi from 'src/apis/masters/workplace'
import { Component } from 'src/models/new/component'
import ComponentElementSearchPanel, { ComponentElementSearchParams } from 'src/components/ComponentElementSearchPanel/index.vue'
import {
  UpdateMetricsTarget,
  UpdateMetricsTargetCategory,
  UpdateMetricsTargetCategoryIdentifier,
  convertUpdateTargetsToDeepUpdateMetricsRequestParameters,
  isSameUpdateMetricsTargetCategoryIdentifiers,
  updateMetricsTargetsFromComponent,
  isUpdateMetricsTargetWithImmutableTimeSpan,
} from 'src/views/Dashboard/Settings/Reports/ReplaceMetrics/logics/UpdateMetricsTarget'
import UpdateMetricsTargetListRow, {
  UpdateMetricsTargetListRowViewModel,
} from 'src/views/Dashboard/Settings/Reports/ReplaceMetrics/components/UpdateMetricsTargetListRow.vue'
import ReplaceMetricsModal from 'src/views/Dashboard/Settings/Reports/ReplaceMetrics/components/ReplaceMetricsModal.vue'
import AppLink from 'src/components/UIComponents/AppLink.vue'
import { notifyError1, notifySuccess1 } from 'src/hooks/notificationHook'
import {
  ERROR_GROUP_SYSTEM,
  ERROR_GROUP_USER,
  ERROR_REASON_INTEGRITY_VIOLATION,
  ErrorGroup,
} from 'src/consts'
import { Workplace } from 'src/models/new/workplace'
import { Metrics, metricsToPartialInformation } from 'src/models/new/metrics'
import SelectItemForm, { ITEM_TYPE_METRICS, SelectItemFormItem } from 'src/components/SelectItemForm/index.vue'
import {
  isComponentTypeMetricsList,
  specifyMetricsListComponentDominantTimeSpan,
} from 'src/models/new/Component/MetricsComponent/metricsListComponent'
import {
  isComponentTypeMetricsGroupedGraph,
  specifyMetricsGroupedGraphComponentDominantTimeSpan,
} from 'src/models/new/Component/MetricsComponent/GraphMetricsComponent/metricsGroupedGraphComponent'
import {
  isComponentTypeMetricsTransitionGraph,
  specifyMetricsTransitionGraphComponentDominantTimeSpan,
} from 'src/models/new/Component/MetricsComponent/GraphMetricsComponent/metricsTransitionGraphComponent'

type CheckboxStatus = {
  componentId: number
  category: UpdateMetricsTargetCategory
  positionIdentifier: UpdateMetricsTargetCategoryIdentifier
  isChecked: boolean
}

interface State {
  userId: number
  pageName: string
  isLoaded: boolean
  hasReportGtManagerRole: boolean

  urlParams: { reportId: number }

  workplaces: Workplace[]

  report: Report | null

  // 本当はreport.componentsにしたいが、他のコミットとの兼ね合いでReportモデルがまだ変更しにくいので一旦分けて記述する
  components: (Component & { sectionId: number })[]
  updateTargets: UpdateMetricsTarget<UpdateMetricsTargetCategoryIdentifier>[]

  isMasterCheckboxChecked: boolean
  checkboxStatuses: CheckboxStatus[]
  updateTargetListRowViewModels: UpdateMetricsTargetListRowViewModel[]

  showMetricsSelectModal: boolean
  selectedUpdateTarget: UpdateMetricsTarget<UpdateMetricsTargetCategoryIdentifier> | null

  showReplaceModal: boolean
  checkedUpdateTargets: UpdateMetricsTarget<UpdateMetricsTargetCategoryIdentifier>[]

  isUpdateValuePresent: boolean

  searchParams: ComponentElementSearchParams
}

function setupState(root : Vue): State {
  const state: State = reactive({
    ...wrappedMapGetters(root.$store, 'displayPageName', [
      'pageName',
    ]),
    userId: wrappedMapGetters(root.$store, 'user', ['id']).id,
    isLoaded: false,
    hasReportGtManagerRole: computed(() => root.$store.getters['user/hasReportGtManagerRole']),

    urlParams: computed(() => {
      return {
        reportId: Number(root.$route.params.reportId),
        componentId: Number(root.$route.params.componentId),
      }
    }),

    workplaces: [],

    report: null,
    components: [],
    updateTargets: [],

    isMasterCheckboxChecked: false,
    checkboxStatuses: [],
    isCheckedAny: computed(() => state.checkboxStatuses.some(el => el.isChecked)),
    updateTargetListRowViewModels: computed(() => {
      return state.updateTargets.map(el => {
        const checkboxStatus = state.checkboxStatuses.find(status => {
          return status.componentId === el.component.id &&
            status.category === el.category &&
            isSameUpdateMetricsTargetCategoryIdentifiers(status.positionIdentifier, el.positionIdentifier)
        })!
        const originalWorkplaceName = state.workplaces.find(workplace => workplace.id === el.originalValue.workplaceId)?.name ?? ''
        const updateWorkplaceName = state.workplaces.find(workplace => workplace.id === el.updateValue?.workplaceId)?.name ?? ''
        return { ...el, isChecked: checkboxStatus.isChecked, originalWorkplaceName, updateWorkplaceName }
      })
    }),

    showMetricsSelectModal: false,
    selectedUpdateTarget: null,

    showReplaceModal: false,
    checkedUpdateTargets: computed(() => {
      return state.updateTargets.filter(el => {
        const checkboxStatus = state.checkboxStatuses.find(status => {
          return status.componentId === el.component.id &&
            status.category === el.category &&
            isSameUpdateMetricsTargetCategoryIdentifiers(status.positionIdentifier, el.positionIdentifier)
        })!
        return checkboxStatus.isChecked
      })
    }),

    isUpdateValuePresent: computed(() => {
      return state.updateTargets.some(el => el.updateValue)
    }),

    searchParams: {
      sectionIds: [],
      componentIds: [],
      keyword: null,
    },

  })
  return state
}

export default defineComponent({
  components: {
    AppLink,
    ComponentElementSearchPanel,
    UpdateMetricsTargetListRow,
    SelectItemForm,
    ReplaceMetricsModal,
  },
  setup() {
    const root = getCurrentInstance()!.proxy
    const state = setupState(root)

    setPageName(root, 'レポート設定')

    provide('injectWorkplaces', () => state.workplaces)

    const arrangeUpdateTargetViewModels = (updateTargets: UpdateMetricsTarget<UpdateMetricsTargetCategoryIdentifier>[]) => {
      state.updateTargets = updateTargets

      state.isMasterCheckboxChecked = false

      const initialCheckState = false
      state.checkboxStatuses = state.updateTargets.map(el => {
        return {
          componentId: el.component.id,
          category: el.category,
          positionIdentifier: el.positionIdentifier,
          isChecked: initialCheckState,
        }
      })
    }

    const initializeUpdateTargetViewModels = (components: (Component & { sectionId: number })[]) => {
      const updateTargets = components.map(el => {
        const section = state.report!.sections.find(section => section.id === el.sectionId)!
        return updateMetricsTargetsFromComponent(el, section)
      }).flat()

      arrangeUpdateTargetViewModels(updateTargets)
    }

    const onSearch = () => {
      // 検索パラメータはいずれも未入力の場合は絞り込み条件に用いない
      const matchSection = (updateTarget: UpdateMetricsTarget<UpdateMetricsTargetCategoryIdentifier>): boolean => {
        if (state.searchParams.sectionIds.length === 0) return true
        // 内部はOR条件
        return state.searchParams.sectionIds.includes(updateTarget.section.id)
      }
      const matchComponent = (updateTarget: UpdateMetricsTarget<UpdateMetricsTargetCategoryIdentifier>): boolean => {
        if (state.searchParams.componentIds.length === 0) return true
        // 内部はOR条件
        return state.searchParams.componentIds.includes(updateTarget.component.id)
      }
      const matchKeyword = (updateTarget: UpdateMetricsTarget<UpdateMetricsTargetCategoryIdentifier>): boolean => {
        if (!state.searchParams.keyword) return true
        return (updateTarget.originalValue.name).includes(state.searchParams.keyword)
      }

      const updateTargets = state.components.map(el => {
        const section = state.report!.sections.find(section => section.id === el.sectionId)!
        return updateMetricsTargetsFromComponent(el, section)
      }).flat().filter(el => matchSection(el) && matchComponent(el) && matchKeyword(el))
      arrangeUpdateTargetViewModels(updateTargets)
    }

    const onCheckboxChange = (updateTarget: UpdateMetricsTarget<UpdateMetricsTargetCategoryIdentifier>, checked: boolean) => {
      const checkboxStatus = state.checkboxStatuses.find(status => {
        return status.componentId === updateTarget.component.id &&
          status.category === updateTarget.category &&
          isSameUpdateMetricsTargetCategoryIdentifiers(status.positionIdentifier, updateTarget.positionIdentifier)
      })!
      checkboxStatus.isChecked = checked

      state.isMasterCheckboxChecked = state.checkboxStatuses.every(el => el.isChecked)
    }
    const onMasterCheckboxChange = (checked: boolean) => {
      state.isMasterCheckboxChecked = checked
      state.checkboxStatuses = state.checkboxStatuses.map(el => {
        return { ...el, isChecked: checked }
      })
    }

    const onUpdateValueChange = (updateTarget: UpdateMetricsTarget<UpdateMetricsTargetCategoryIdentifier>, value: Metrics) => {
      state.updateTargets = state.updateTargets.map(el => {
        return el.component.id === updateTarget.component.id &&
          el.category === updateTarget.category &&
          isSameUpdateMetricsTargetCategoryIdentifiers(el.positionIdentifier, updateTarget.positionIdentifier)
          ? { ...el, updateValue: value }
          : el
      })
    }

    // invalidになる条件は、以下のケースで現在含まれるメトリクスの周期と異なる場合が該当
    // - メトリクスリストコンポーネントの主メトリクスを対象としている
    // - メトリクスグループグラフコンポーネントの主メトリクスを対象としている
    // - メトリクス遷移グラフコンポーネントの主メトリクスを対象としている
    const isMetricsTimeSpanInvalid = (item: SelectItemFormItem, currentItems: SelectItemFormItem[]): boolean => {
      if (!isUpdateMetricsTargetWithImmutableTimeSpan(state.selectedUpdateTarget!)) return false

      const component = state.components.find(el => el.id === state.selectedUpdateTarget!.component.id)!

      if (isComponentTypeMetricsList(component)) {
        const timeSpan = specifyMetricsListComponentDominantTimeSpan(component)
        // 通常は、timeSpanがnullになるケースでは画面に更新対象行が表示されていないので発生しない
        return !!timeSpan && item.timeSpan !== timeSpan
      } else if (isComponentTypeMetricsGroupedGraph(component)) {
        const timeSpan = specifyMetricsGroupedGraphComponentDominantTimeSpan(component)
        // 通常は、timeSpanがnullになるケースでは画面に更新対象行が表示されていないので発生しない
        return !!timeSpan && item.timeSpan !== timeSpan
      } else if (isComponentTypeMetricsTransitionGraph(component)) {
        const timeSpan = specifyMetricsTransitionGraphComponentDominantTimeSpan(component)
        // 通常は、timeSpanがnullになるケースでは画面に更新対象行が表示されていないので発生しない
        return !!timeSpan && item.timeSpan !== timeSpan
      }

      return false
    }

    const openMetricsSelectModal = (updateTarget: UpdateMetricsTarget<UpdateMetricsTargetCategoryIdentifier>) => {
      state.showMetricsSelectModal = true
      state.selectedUpdateTarget = updateTarget
    }
    const closeMetricsSelectModal = () => {
      state.showMetricsSelectModal = false
      state.selectedUpdateTarget = null
    }

    const onMetricsSelected = (metrics: Metrics[]) => {
      const selectedMetrics = metrics.length !== 0 ? metrics[0] : null
      state.updateTargets = state.updateTargets.map(el => {
        return el.component.id === state.selectedUpdateTarget!.component.id &&
          el.category === state.selectedUpdateTarget!.category &&
          isSameUpdateMetricsTargetCategoryIdentifiers(el.positionIdentifier, state.selectedUpdateTarget!.positionIdentifier)
          ? { ...el, updateValue: selectedMetrics ? metricsToPartialInformation(selectedMetrics) : null }
          : el
      })

      closeMetricsSelectModal()
    }

    const openReplaceModal = () => {
      state.showReplaceModal = true
    }
    const closeReplaceModal = () => {
      state.showReplaceModal = false
    }

    const onReplaced = (updateTargets: UpdateMetricsTarget<UpdateMetricsTargetCategoryIdentifier>[]) => {
      state.updateTargets = state.updateTargets.map(el => {
        const replacedUpdateTarget = updateTargets.find(target => {
          return el.component.id === target.component.id &&
            el.category === target.category &&
            isSameUpdateMetricsTargetCategoryIdentifiers(el.positionIdentifier, target.positionIdentifier)
        })
        return replacedUpdateTarget ?? el
      })
      closeReplaceModal()
    }

    const loadReport = async(): Promise<void> => {
      state.report = await reportApi.show(state.urlParams.reportId)
    }
    const loadComponents = async(): Promise<void> => {
      if (!state.report) return
      state.components = state.report!.sections.reduce((components, section) => {
        return components.concat(section.components.map(el => {
          return { ...el, sectionId: section.id }
        }))
      }, [] as (Component & { sectionId: number })[])
    }
    const loadModels = async(): Promise<void> => {
      await loadReport()
      await loadComponents()
    }
    const loadWorkplaces = async(): Promise<void> => {
      state.workplaces = await workplaceApi.index()
    }

    const update = async(): Promise<void> => {
      try {
        const params = convertUpdateTargetsToDeepUpdateMetricsRequestParameters(state.updateTargets, state.report!)
        await reportApi.deepUpdateMetrics(params)
        await loadModels()
        onSearch()
        notifySuccess1(root, '対象メトリクスを一括変更しました。')
      } catch (err: any) {
        const errStatus = err.response?.status
        const errData = err.response?.data
        if ([403, 404].includes(errStatus)) {
          const msg = '操作権限がありません。管理者にお問合せください。'
          reportError(ERROR_GROUP_USER, msg, err, '')
        } else if (errStatus === 409) {
          // 以下のケースが該当
          // - 表, リスト, グラフの主メトリクスを変更した際に、該当箇所の要素が存在しない
          const msg = '対象メトリクスの一括変更に失敗しました。編集された箇所があります。'
          await loadModels()
          onSearch()
          reportError(ERROR_GROUP_USER, msg, err, '')
        } else if (errStatus === 400 && errData?.reason === ERROR_REASON_INTEGRITY_VIOLATION) {
          // コンポーネント内でのメトリクスの重複を共用しないパターンがあり、それに違反した場合が該当
          // 実際に発生するとどこが該当なのか探すのが困難だが、ユースケース的に発生する可能性が極めて低い
          const msg = '対象メトリクスの一括変更に失敗しました。コンポーネントに許可されないメトリクスの重複があります。'
          reportError(ERROR_GROUP_USER, msg, err, '')
        } else {
          const msg = '対象メトリクスの一括変更に失敗しました。管理者に連絡してください。'
          reportError(ERROR_GROUP_SYSTEM, msg, err, '')
        }
      }
    }

    const reportError = async(errorGroup: ErrorGroup, message: string, error: any, errorId: string) => {
      const formattedMessage = errorGroup === ERROR_GROUP_SYSTEM
        ? `${message} (ERR: ${state.pageName} ${errorId ? ` ${errorId}` : ''}, user_id:${state.userId})`
        : message

      notifyError1(root, formattedMessage, error)
    }

    onMounted(async() => {
      // Vue 2x 暫定措置 3x系の場合はonUnmountedでフラグを戻す
      // Vue 2x ではonUnmountedがdestroyedに対するフックのエイリアスであるためonMountedの先頭に記述している
      state.isLoaded = false

      await Promise.all([
        // ログインユーザー情報をAPIで再取得
        ensureUserRefreshAndMasters(root),
        loadReport(),
        loadWorkplaces(),
      ])
      // 現状はレポートの中から取り出す処理になるのでレポートのロードと並行処理にはできない
      // 後のコミットでコンポーネントレベルで取得できるようになれば並行処理にできるはずである
      // 並行にする場合はloadModelsを並列処理に変更して、loadReportとloadComponentsの代わりにloadModelsを呼び出す
      await loadComponents()

      initializeUpdateTargetViewModels(state.components)

      state.selectedUpdateTarget = null
      state.showReplaceModal = false
      state.showMetricsSelectModal = false

      state.isLoaded = true
    })

    return {
      state,
      ITEM_TYPE_METRICS,
      onSearch,
      onCheckboxChange,
      onMasterCheckboxChange,
      onUpdateValueChange,
      isMetricsTimeSpanInvalid,
      openMetricsSelectModal,
      closeMetricsSelectModal,
      onMetricsSelected,
      openReplaceModal,
      closeReplaceModal,
      onReplaced,
      update,
    }
  },
})

