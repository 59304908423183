
import { vvGetError, vvGetErrorObject, vvHasError, vvValidate } from 'src/util/vee_validate';
import { computed, defineComponent, getCurrentInstance, onMounted, PropType, reactive } from 'vue';
import {
  ERROR_GROUP_SYSTEM,
  ERROR_GROUP_USER,
  ERROR_REASON_INVALID,
  ERROR_REASON_ACCESS_GROUP_NOT_FOUND,
  ERROR_REASON_LOGISCOPE_WORKPLACE_NOT_FOUND,
  ERROR_REASON_METRICS_NOT_FOUND,
} from 'src/consts';
import {
  TIME_SPAN_LOCAL_WORDS,
  TimeSpan,
  isTimeSpanDaily,
  isTimeSpanMonthly,
  isTimeSpanWeekly,
  isTimeSpanYearly,
  timeSpanToLocalWord,
} from 'src/business/timeSpan';
import {
  ReferenceMetrics,
  OffsetPeriodUnit,
  isReferenceMetricsOffsetPeriodYear,
  isReferenceMetricsOffsetPeriodMonth,
  isReferenceMetricsOffsetPeriodWeek,
} from 'src/models/new/Metrics/referenceMetrics';
import { MetricsAccessGroup } from 'src/models/new/AccessGroup/metricsAccessGroup';
import workplaceApi from 'src/apis/masters/workplace';
import metricsApi from 'src/apis/masters/metrics';
import SelectSingleMetricsOnMetricsForm from 'src/views/Dashboard/Settings/Metrics/components/shared/SelectSingleMetricsOnMetricsForm.vue';
import SelectAccessGroupForm from 'src/components/NewSelectItemForm/SelectAccessGroupForm.vue';
import MaxChainNotification from 'src/views/Dashboard/Settings/Metrics/components/shared/MaxChainNotification.vue';
import { requestTimeout } from 'src/util/request_animation_frame';
import { isRecordIdValid, validRecordIdOrNull } from 'src/util/recordId';
import { Metrics, metricsToTypeLocalWord, METRICS_ACCESS_GROUP_MAX_COUNT } from 'src/models/new/metrics';
import { Workplace } from 'src/models/new/workplace';
import { DayOfWeek, DAY_OF_WEEK_LOCAL_WORDS } from 'src/util/week';
import InputError from 'src/components/InputError.vue';
import MetricsInputErrors from 'src/components/MetricsInputErrors.vue';
import { useMetricsAccessGroupsOfMetrics } from 'src/views/Dashboard/Settings/Metrics/composables/useMetricsAccessGroupsOfMetrics';
import { useSimpleEvent } from 'src/composables/useSimpleEvent';
import { useScalingOptions } from 'src/composables/options/useScalingOptions';

type IOValues = null | 1;

type DowOption = {
  value: string;
  label: string;
};

const DOW_OPTIONS: DowOption[] = Object.keys(DAY_OF_WEEK_LOCAL_WORDS).map((key) => {
  return {
    value: key as DayOfWeek,
    label: DAY_OF_WEEK_LOCAL_WORDS[key as DayOfWeek],
  };
});

const TIME_SPAN_OPTIONS = Object.keys(TIME_SPAN_LOCAL_WORDS).map((el) => {
  return {
    value: el as TimeSpan,
    label: TIME_SPAN_LOCAL_WORDS[el as TimeSpan],
  };
});

interface State {
  // 各種マスタデータのロード完了を示すフラグ
  isLoaded: boolean;
  // リクエスト送信処理中であることを表すフラグ
  isRequesting: boolean;
  showMetricsAccessGroupSelectForm: boolean;
  showTargetMetricsSelectForm: boolean;
  // TODO: VeeValidate用のオプションを型付けする: 公式の型は個々のバリデーションタイプごとの型がなく、使うバリデーションタイプを列挙する必要がある
  validations: Record<string, Object>;
  hasError: boolean;
  metrics: ReferenceMetrics;
  metricsIsNew: boolean;
  metricsTypeLocalWord: string;
  userSelectMetrics: Metrics | null;
  userSelectMetricsAccessGroups: MetricsAccessGroup[];
  // 時間があればworkplaceも新モデルに置き換える
  // TODO: ページ全体で使いがちなので、Provide/Injectでの利用に切り替える
  workplaceOptions: Workplace[];
  // プロパティとv-modelの接続を調整する
  validWorkplaceIdOrNull: number | null;
  availablePeriodUnits: { key: OffsetPeriodUnit; inputLabel: string }[];
  defaultPeriodUnit: { key: OffsetPeriodUnit; inputLabel: string };
  useReferencePointMonth: IOValues;
  useReferencePointDay: IOValues;
  showReferencePointMonthInputField: boolean;
  showReferencePointDayInputField: boolean;
  showReferencePointDowInputField: boolean;
}

const CONFIRM_ACCESS_GROUP_EVENT_KEY = 'confirmSelectAccessGroupForm';
const CONFIRM_METRICS_EVENT_KEY = 'confirmSelectMetricsForm';

export default defineComponent({
  components: {
    SelectSingleMetricsOnMetricsForm,
    SelectAccessGroupForm,
    MaxChainNotification,
    MetricsInputErrors,
    InputError,
  },
  props: {
    value: {
      type: Object as PropType<ReferenceMetrics>,
      required: true,
    },
  },
  emits: ['input', 'updated', 'close', 'shouldReport'],
  setup(props, { emit }) {
    const root = getCurrentInstance()!.proxy;
    const state: State = reactive({
      isLoaded: false,
      isRequesting: false,
      showMetricsAccessGroupSelectForm: false,
      showTargetMetricsSelectForm: false,
      validations: computed(() => {
        return {
          name: { required: true, max: 50 },
          decimalPlaces: { required: true, min_value: 0, max_value: 2, numeric: true },
          workplaceId: { required: true, min_value: 1 },
          targetMetricsId: { required: true, min_value: 1 },
          // offsetPeriodUnitは通常空にならないので省略,
          offsetPeriodValue: { required: true, numeric: true, max_value: 999 },
          referencePointMonth:
            state.useReferencePointMonth || state.useReferencePointDay
              ? { required: true, numeric: true, max_value: 12 }
              : {},
          referencePointDay:
            state.useReferencePointDay || (state.metrics.offsetPeriod.unit === 'year' && state.useReferencePointMonth)
              ? { required: true, numeric: true, max_value: 31 }
              : {},
          // referencePointDowは選択可能な条件下で任意選択なので省略
          unit: { required: false, max: 10 },
          // 値を入力したらunitは必須
          useReferencePointDay: { required: !!state.metrics.referencePointDay },
          useReferencePointMonth: { required: !!state.metrics.referencePointMonth },
        };
      }),
      hasError: computed(() => vvHasError(root)),
      metrics: computed({
        get() {
          return props.value;
        },
        set(next) {
          emit('input', structuredClone(next));
        },
      }),
      metricsIsNew: computed(() => !isRecordIdValid(state.metrics.id)),
      metricsTypeLocalWord: computed(() => metricsToTypeLocalWord(state.metrics)),
      userSelectMetrics: null,
      userSelectMetricsAccessGroups: computed({
        get(): MetricsAccessGroup[] {
          return metricsAccessGroupsOnMetricsWithoutAdmin.value;
        },
        set(value: MetricsAccessGroup[]) {
          metricsAccessGroupsOnMetrics.value = metricsAccessGroupOnMetricsWithAdmin.value
            ? [metricsAccessGroupOnMetricsWithAdmin.value, ...value]
            : value;
        },
      }),
      validWorkplaceIdOrNull: computed({
        get() {
          return validRecordIdOrNull(state.metrics.workplaceId);
        },
        set(value) {
          state.metrics.workplaceId = value || 0;
        },
      }),
      workplaceOptions: [],
      // TODO: TimeSpanに対して取りうるOffsetPeriodのロジックはモデルに持たせるのがベター
      availablePeriodUnits: computed(() => {
        const year = { key: 'year' as OffsetPeriodUnit, inputLabel: '...年前' };
        const month = { key: 'month' as OffsetPeriodUnit, inputLabel: '...ヶ月前' };
        const week = { key: 'week' as OffsetPeriodUnit, inputLabel: '...週間前' };
        const day = { key: 'day' as OffsetPeriodUnit, inputLabel: '...日前' };
        const timeSpan = state.metrics.timeSpan;

        if (isTimeSpanYearly(timeSpan)) {
          return [year];
        }
        if (isTimeSpanMonthly(timeSpan)) {
          return [month, year];
        }
        if (isTimeSpanWeekly(timeSpan)) {
          return [week];
        }
        return [day, week, month, year];
      }),
      defaultPeriodUnit: computed(() => state.availablePeriodUnits[0]),
      useReferencePointMonth: null,
      useReferencePointDay: null,
      showReferencePointMonthInputField: computed(() => {
        return (
          (isTimeSpanDaily(state.metrics.timeSpan) || isTimeSpanMonthly(state.metrics.timeSpan)) &&
          isReferenceMetricsOffsetPeriodYear(state.metrics.offsetPeriod)
        );
      }),
      showReferencePointDayInputField: computed(() => {
        return (
          isTimeSpanDaily(state.metrics.timeSpan) &&
          (isReferenceMetricsOffsetPeriodYear(state.metrics.offsetPeriod) ||
            isReferenceMetricsOffsetPeriodMonth(state.metrics.offsetPeriod))
        );
      }),
      showReferencePointDowInputField: computed(() => {
        return (
          isTimeSpanDaily(state.metrics.timeSpan) && isReferenceMetricsOffsetPeriodWeek(state.metrics.offsetPeriod)
        );
      }),
    });

    const {
      metricsAccessGroupsOnMetrics,
      metricsAccessGroupsOnMetricsWithoutAdmin,
      metricsAccessGroupOnMetricsWithAdmin,
      loadMetricsAccessGroupsOnMetrics,
    } = useMetricsAccessGroupsOfMetrics();

    const { options: scalingOptions } = useScalingOptions();

    const getError = (fieldName: string): string | null => vvGetError(root, fieldName);
    const getErrorObject = (fieldName: string): object | null => vvGetErrorObject(root, fieldName);

    const setWorkplaceOptions = async () => {
      state.workplaceOptions = await workplaceApi.index();

      // 設定されてない場合は一番上のセンターを初期値とする
      if (state.workplaceOptions.length > 0 && !state.validWorkplaceIdOrNull) {
        state.validWorkplaceIdOrNull = state.workplaceOptions[0].id;
      }
    };

    const setTargetMetrics = async () => {
      if (!state.metrics.targetMetricsId) {
        return;
      }
      state.userSelectMetrics = await metricsApi.show(state.metrics.targetMetricsId);
    };

    const goToAccessGroupSelectForm = (): void => {
      state.showMetricsAccessGroupSelectForm = true;
    };
    const goToTargetMetricsSelectForm = (): void => {
      state.showTargetMetricsSelectForm = true;
    };
    const backToMainForm = (): void => {
      state.showMetricsAccessGroupSelectForm = false;
      state.showTargetMetricsSelectForm = false;
    };

    const setSelectedAccessGroups = (metricsAccessGroups: MetricsAccessGroup[]): void => {
      state.userSelectMetricsAccessGroups = metricsAccessGroups;
      backToMainForm();
    };

    const { triggerer: confirmAccessGroupEventTriggerer } = useSimpleEvent(CONFIRM_ACCESS_GROUP_EVENT_KEY);

    const triggerAccessGroupConfirmEvent = () => {
      confirmAccessGroupEventTriggerer.trigger();
    };

    const setSelectedMetrics = (metrics: Metrics | null): void => {
      state.metrics.targetMetricsId = metrics?.id ?? 0;
      state.userSelectMetrics = metrics;
      backToMainForm();
    };

    const { triggerer: confirmMetricsEventTriggerer } = useSimpleEvent(CONFIRM_METRICS_EVENT_KEY);

    const triggerMetricsConfirmEvent = () => {
      confirmMetricsEventTriggerer.trigger();
    };

    const onTimeSpanChange = (): void => {
      state.metrics = {
        ...state.metrics,
        ...{
          offsetPeriod: {
            // 初期値
            unit: state.defaultPeriodUnit.key as OffsetPeriodUnit,
            value: 1,
          },
          referencePointMonth: null,
          referencePointDay: null,
          referencePointDow: null,
        },
      };
      state.useReferencePointMonth = null;
      state.useReferencePointDay = null;
    };

    const onOffsetPeriodUnitChange = (): void => {
      // offsetPeriodUnitが変更されたら、後続の選択肢を初期化する
      state.metrics = {
        ...state.metrics,
        ...{
          referencePointMonth: null,
          referencePointDay: null,
          referencePointDow: null,
        },
      };
      state.useReferencePointMonth = null;
      state.useReferencePointDay = null;
    };

    const close = (): void => emit('close');

    const save = async (): Promise<void> => {
      // 二重処理防止
      if (state.isRequesting) {
        return;
      }
      state.isRequesting = true;
      requestTimeout(() => {
        state.isRequesting = false;
      }, 300);

      if (!(await vvValidate(root))) {
        return;
      }

      const metrics: ReferenceMetrics = {
        ...state.metrics,
        accessGroupIds: state.userSelectMetricsAccessGroups.map((metricsAccessGroup) => metricsAccessGroup.id),
      };
      const operationWord = state.metricsIsNew ? '作成' : '更新';
      try {
        if (state.metricsIsNew) {
          await metricsApi.create(metrics);
        } else {
          await metricsApi.update(metrics);
        }
        emit('updated', `${state.metrics.name} を${operationWord}しました。`);
      } catch (err: any) {
        const errId = state.metricsIsNew ? 'ERR00001' : 'ERR00002';
        const errStatus = err.response.status;
        const errRes = err.response.data || {};
        if ([403, 404].includes(errStatus)) {
          const msg = 'アクセスする権限がありません。管理者にお問合せください。';
          emit('shouldReport', ERROR_GROUP_USER, msg, err);
        } else if (errStatus === 400 && errRes.reason === ERROR_REASON_METRICS_NOT_FOUND) {
          const msg = '選択したメトリクスは存在しません。ページを更新し、再度お試しください';
          emit('shouldReport', ERROR_GROUP_USER, msg, err);
        } else if (errStatus === 400 && errRes.reason === ERROR_REASON_ACCESS_GROUP_NOT_FOUND) {
          const msg = '選択したメトリクスグループは存在しません。ページを更新し、再度お試しください';
          emit('shouldReport', ERROR_GROUP_USER, msg, err);
        } else if (errStatus === 400 && errRes.reason === ERROR_REASON_LOGISCOPE_WORKPLACE_NOT_FOUND) {
          const msg = '選択した集計用センターは存在しません。ページを更新し、再度お試しください';
          emit('shouldReport', ERROR_GROUP_USER, msg, err);
        } else if ((errStatus === 400 && errRes.reason === ERROR_REASON_INVALID) || errStatus === 409) {
          emit('shouldReport', ERROR_GROUP_USER, errRes.message, err);
        } else {
          const msg = `メトリクスの${operationWord}に失敗しました。管理者に連絡してください。`;
          // TODO: notifyのオプションと統合して、引数が長くならないように、ページ間で異ならないように統一する
          emit('shouldReport', ERROR_GROUP_SYSTEM, msg, err, errId);
        }
      }
    };

    onMounted(async () => {
      // Vue 2x 暫定措置 3x系の場合はonUnmountedでフラグを戻す
      // Vue 2x ではonUnmountedがdestroyedに対するフックのエイリアスであるためonMountedの先頭に記述している
      state.isLoaded = false;
      state.showMetricsAccessGroupSelectForm = false;

      await Promise.all([loadMetricsAccessGroupsOnMetrics(state.metrics), setWorkplaceOptions(), setTargetMetrics()]);

      state.useReferencePointMonth = state.metrics.referencePointMonth ? 1 : null;
      state.useReferencePointDay = state.metrics.referencePointDay ? 1 : null;

      state.isLoaded = true;
    });

    return {
      props,
      state,
      METRICS_ACCESS_GROUP_MAX_COUNT,
      DOW_OPTIONS,
      TIME_SPAN_OPTIONS,
      getError,
      getErrorObject,
      goToAccessGroupSelectForm,
      goToTargetMetricsSelectForm,
      backToMainForm,
      setSelectedAccessGroups,
      CONFIRM_ACCESS_GROUP_EVENT_KEY,
      triggerAccessGroupConfirmEvent,
      setSelectedMetrics,
      CONFIRM_METRICS_EVENT_KEY,
      triggerMetricsConfirmEvent,
      onTimeSpanChange,
      onOffsetPeriodUnitChange,
      save,
      close,
      metricsToTypeLocalWord,
      timeSpanToLocalWord,
      scalingOptions,
    };
  },
});
