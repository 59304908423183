
import { defineComponent, onMounted, reactive, ref } from 'vue'
import ComponentLinkList from 'src/views/Dashboard/Settings/Reports/Components/Links/components/ComponentLinkList/ComponentLinkList.vue'
import ComponentLinkDeleteConfirmModal from 'src/views/Dashboard/Settings/Reports/Components/Links/components/ComponentLinkDeleteConfirmModal/ComponentLinkDeleteConfirmModal.vue'
import ComponentLinkFormModal from 'src/views/Dashboard/Settings/Reports/Components/Links/components/ComponentLinkFormModal/ComponentLinkFormModal.vue'
import { useReportsProvider } from 'src/composables/asyncResources/useReports'
import AppLink from 'src/components/UIComponents/AppLink.vue'
import { useComponentLinkFormModal } from 'src/views/Dashboard/Settings/Reports/Components/Links/composables/useComponentLinkFormModal'
import { useComponentLinkDeleteConfirmModal } from 'src/views/Dashboard/Settings/Reports/Components/Links/composables/useComponentLinkDeleteConfirmModal'
import { useUrlParams } from 'src/composables/useUrlParams'
import { useComponentLinks } from './composables/useComponentLinks'
import { usePageName } from 'src/composables/usePageName'
import { constructEmptyMetricsCardComponent } from 'src/models/new/Component/MetricsComponent/metricsCardComponent'
import { Component } from 'src/models/new/component'

const urlParamsTemplate = {
  reportId: 0,
  componentId: 0,
}

type State = {
  isLoaded: boolean
}

export default defineComponent({
  components: {
    AppLink,
    ComponentLinkList,
    ComponentLinkFormModal,
    ComponentLinkDeleteConfirmModal,
  },
  setup() {
    const {
      setPageName,
    } = usePageName()
    // TODO: このタイトルで良いか？条件付き書式も同様のタイトルになっている
    setPageName('レポート設定')

    useReportsProvider()

    const {
      enableUrlParams,
      urlParamsRef,
    } = useUrlParams<typeof urlParamsTemplate>()
    enableUrlParams(urlParamsTemplate)

    const {
      componentLinksRef,
      loadComponentLinks,
      componentLinksState,
    } = useComponentLinks()

    const {
      isFormModalShown,
      createNewComponentLink,
      formTargetComponentLinkRef,
      openFormModalWithValue,
      closeFormModal,
    } = useComponentLinkFormModal()

    const editSelectedComponentLink = openFormModalWithValue

    const onUpdated = () => {
      loadComponentLinks()
      closeFormModal()
    }

    const {
      isDeleteConfirmModalShown,
      deleteTargetComponentLinkRef,
      openDeleteConfirmModalWithValue,
      closeDeleteConfirmModal,
    } = useComponentLinkDeleteConfirmModal()

    const deleteSelectedComponentLink = openDeleteConfirmModalWithValue

    const onDeleted = () => {
      loadComponentLinks()
      closeDeleteConfirmModal()
    }

    const onOrderUpdated = () => {
      loadComponentLinks()
    }

    const onFailed = () => {
      loadComponentLinks()
    }

    const state: State = reactive({
      isLoaded: false,
    })

    const componentRef = ref<Component>(constructEmptyMetricsCardComponent())

    onMounted(async() => {
      // Vue 2x 暫定措置 3x系の場合はonUnmountedでフラグを戻す
      // Vue 2x ではonUnmountedがdestroyedに対するフックのエイリアスであるためonMountedの先頭に記述している
      state.isLoaded = false

      closeFormModal()
      closeDeleteConfirmModal()

      // コンポーネントの取得APIが開発延期の為、暫定的にWebストレージ経由でコンポーネントを取得する
      try {
        componentRef.value = JSON.parse(sessionStorage.getItem('Tmp._component') ?? '')
      } catch {
        location.href = '/settings/reports'
      }

      await loadComponentLinks()

      state.isLoaded = true
    })

    return {
      state,
      urlParamsRef,
      componentLinksRef,
      componentLinksState,
      componentRef,
      onOrderUpdated,
      createNewComponentLink,
      isFormModalShown,
      formTargetComponentLinkRef,
      editSelectedComponentLink,
      closeFormModal,
      isDeleteConfirmModalShown,
      deleteTargetComponentLinkRef,
      deleteSelectedComponentLink,
      closeDeleteConfirmModal,
      onUpdated,
      onDeleted,
      onFailed,
    }
  },
})
