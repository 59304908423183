
import { computed, defineComponent, nextTick, onMounted, PropType, reactive, watch } from 'vue';
import {
  LogiSystemDataMetrics,
  LOGI_SYSTEM_DATA_METRICS_MAX_QUERY_PARAMETERS,
} from 'src/models/new/Metrics/BasicMetrics/logiSystemDataMetrics';
import { isRecordIdValid } from 'src/util/recordId';
import InputError from 'src/components/InputError.vue';
import MetricsInputErrors from 'src/components/MetricsInputErrors.vue';
import { useTimeSpanOptions } from 'src/composables/options/useTimeSpanOptions';
import { useLogiSystemDataMetricsAggregateFunctionOptions } from 'src/composables/options/useLogiSystemDataMetricsAggregateFunctionOptions';
import { useScalingOptions } from 'src/composables/options/useScalingOptions';
import { useWorkplaceOptions } from 'src/composables/options/useWorkplaceOptions';
import { useMetricsAccessGroupsOfMetrics } from '../../composables/useMetricsAccessGroupsOfMetrics';
import { useRecordIdProxy } from 'src/composables/useRecordIdProxy';
import { useValidator } from 'src/composables/useValidator';
import { useLogiCoredataWorkplaceOptions } from 'src/composables/options/useLogiCoredataWorkplaceOptions';
import { useLogiCoredataBudgetGroups } from 'src/composables/asyncResources/useLogiCoredataBudgetGroups';
import { useDataSourceParameterInput } from './composables/useDataSourceParameterInput';
import { useDataSources } from 'src/composables/asyncResources/useDataSources';
import { useDataSourceOptions } from 'src/composables/options/useDataSourceOptions';
import { useLogiCoredataWorkplaceInput } from './composables/useLogiCoredataWorkplaceInput';
import { useSimpleEvent } from 'src/composables/useSimpleEvent';

interface State {
  metrics: LogiSystemDataMetrics;
  metricsIsNew: boolean;
}

export default defineComponent({
  components: {
    MetricsInputErrors,
    InputError,
  },
  props: {
    value: {
      type: Object as PropType<LogiSystemDataMetrics>,
      required: true,
    },
    onAccessGroupSelectClick: {
      type: Function as PropType<() => void>,
      default: () => {},
    },
    submitEventKey: {
      type: String,
      required: true,
    },
  },
  emits: ['input', 'confirmed'],
  setup(props, { emit }) {
    const state: State = reactive({
      metrics: computed({
        get() {
          return props.value;
        },
        set(next) {
          emit('input', next);
        },
      }),
      metricsIsNew: computed(() => !isRecordIdValid(state.metrics.id)),
    });

    const { options: aggregateFunctionOptions } = useLogiSystemDataMetricsAggregateFunctionOptions();

    const { options: scalingOptions } = useScalingOptions();

    const { options: timeSpanOptions } = useTimeSpanOptions();

    const { options: workplaceOptions } = useWorkplaceOptions();

    const { metricsAccessGroupsOnMetricsWithoutAdmin } = useMetricsAccessGroupsOfMetrics();

    const { options: logiCoredataWorkplaceOptions } = useLogiCoredataWorkplaceOptions();

    const { logiCoredataBudgetGroupsRef } = useLogiCoredataBudgetGroups();

    const { inquireEndpointByKeyId: inquireDataSourceEndpointByKeyId, prepareDataSourceParameterValues } =
      useDataSources();

    const {
      endpointOptions: dataSourceEndpointOptions,
      selectedEndpoint: selectedDataSourceEndpoint,
      keyOptions: dataSourceKeyOptions,
      selectEndpoint: selectDataSourceEndpoint,
      getDataSourceParameterValueOptions,
    } = useDataSourceOptions();

    const { proxyValue: workplaceIdProxy, bind: bindWorkplaceIdToProxy } = useRecordIdProxy();
    bindWorkplaceIdToProxy(state.metrics, 'workplaceId');

    const { proxyValue: budgetGroupIdProxy, bind: bindBudgetGroupIdToProxy } = useRecordIdProxy();
    bindBudgetGroupIdToProxy(state.metrics, 'budgetGroupId');

    const { proxyValue: logiDataSourceIdProxy, bind: bindLogiDataSourceIdToProxy } = useRecordIdProxy();
    bindLogiDataSourceIdToProxy(state.metrics, 'logiDataSourceId');

    const { validations, validationResult, ensureValidation, setValidation, unvalidate } = useValidator();
    setValidation({
      name: { required: true, max: 50 },
      workplaceId: { required: true },
      unit: { required: false, max: 10 },
      decimalPlaces: { required: true, min_value: 0, max_value: 2, numeric: true },
      logiDataSourceId: { required: true },
      aggrFunc: { required: true },
      budgetGroupId: { required: true },
    });

    const {
      inputs: dataSourceParameterInputs,
      fieldNames: DataSourceParameterInputFieldNames,
      // selectedKeyIdに関してはlogiDataSourceIdProxyを利用しているので取得していない
      initialize: initializeDataSourceParameterInput,
      clear: clearDataSourceParameterInput,
      pop: popDataSourceParameterInput,
      push: pushDataSourceParameterInput,
      reflect: reflectDataSourceParameterInput,
      selectKeyId: selectDataSourceKeyId,
      validationResult: dataSourceParameterInputValidationResult,
      ensureValidation: ensureDataSourceParameterInputValidation,
      onSelectDataSourceParameter: updateInputOnSelectDataSourceParameter,
      getDataSourceParameterOptions,
    } = useDataSourceParameterInput();
    // 画面上で変更されるlogiDataSourceIdProxyとuseDataSourceParameterInputの内部データを同期させる
    watch(logiDataSourceIdProxy, (newValue) => {
      selectDataSourceKeyId(newValue);
    });

    const {
      logiCoredataWorkplaceId,
      logiCoredataBudgetGroupOnWorkplaceOptions,
      initializeValue: initializeLogiCoredataWorkplaceId,
    } = useLogiCoredataWorkplaceInput();

    const onSelectLogiCoredataWorkplaceId = (): void => {
      state.metrics.budgetGroupId = 0;
      // 「取得条件」のところで選択した内容や候補してひっぱってくるマスターデータが影響を受けるので空っぽにする
      clearDataSourceParameterInput();
      nextTick(() => {
        unvalidate('budgetGroupId');
      });
    };
    const onSelectBudgetGroupId = (_id: number): void => {
      // 「取得条件」のところで選択した内容や候補してひっぱってくるマスターデータが影響を受けるので空っぽにする
      clearDataSourceParameterInput();
    };
    const onSelectDsEntryPoint = (entryPointCode: string): void => {
      state.metrics.logiDataSourceId = 0;
      selectDataSourceEndpoint(entryPointCode);
      clearDataSourceParameterInput();
      nextTick(() => {
        unvalidate('logiDataSourceId');
      });
    };

    const loadDataSourceParameterValues = async (dataSourceParameterId: number) => {
      const budgetGroup = logiCoredataBudgetGroupsRef.value.find((el) => el.id === state.metrics.budgetGroupId);
      if (budgetGroup === undefined) return;

      await prepareDataSourceParameterValues(budgetGroup, dataSourceParameterId);
    };

    const onSelectDataSourceParameter = (formIndex: number): void => {
      updateInputOnSelectDataSourceParameter(formIndex);
      const dataSourceParameterId = dataSourceParameterInputs.value[formIndex].parameterId;
      if (dataSourceParameterId === null) return;

      loadDataSourceParameterValues(dataSourceParameterId);
    };

    const { listener: submitEventListener } = useSimpleEvent(props.submitEventKey);

    submitEventListener.attach(async () => {
      if (!(await ensureValidation()) || !(await ensureDataSourceParameterInputValidation())) return;

      state.metrics.queryParameters = reflectDataSourceParameterInput();
      emit('confirmed', state.metrics);
    });

    onMounted(async () => {
      initializeLogiCoredataWorkplaceId(state.metrics);

      const initialEndpoint = inquireDataSourceEndpointByKeyId(state.metrics.logiDataSourceId);

      selectDataSourceEndpoint(initialEndpoint);
      selectDataSourceKeyId(logiDataSourceIdProxy.value);

      initializeDataSourceParameterInput(state.metrics.queryParameters);

      if (dataSourceParameterInputs.value.length === 0) pushDataSourceParameterInput();
    });

    const goToAccessGroupSelectForm = (): void => {
      state.metrics.queryParameters = reflectDataSourceParameterInput();
      props.onAccessGroupSelectClick();
    };

    return {
      props,
      state,
      aggregateFunctionOptions,
      onSelectLogiCoredataWorkplaceId,
      onSelectBudgetGroupId,
      onSelectDsEntryPoint,
      onSelectDataSourceParameter,
      pushDataSourceParameterInput,
      popDataSourceParameterInput,
      scalingOptions,
      timeSpanOptions,
      workplaceOptions,
      logiCoredataWorkplaceOptions,
      logiCoredataBudgetGroupOnWorkplaceOptions,
      dataSourceEndpointOptions,
      selectedDataSourceEndpoint,
      dataSourceKeyOptions,
      selectDataSourceKeyId,
      getDataSourceParameterOptions,
      getDataSourceParameterValueOptions,
      metricsAccessGroupsOnMetricsWithoutAdmin,
      LOGI_SYSTEM_DATA_METRICS_MAX_QUERY_PARAMETERS,
      goToAccessGroupSelectForm,
      logiCoredataWorkplaceId,
      workplaceIdProxy,
      budgetGroupIdProxy,
      logiDataSourceIdProxy,
      validations,
      validationResult,
      dataSourceParameterInputs,
      DataSourceParameterInputFieldNames,
      ensureDataSourceParameterInputValidation,
      dataSourceParameterInputValidationResult,
    };
  },
});
