import { ReportLink } from 'src/models/new/Link/reportLink'
import reportLinkApi from 'src/apis/masters/reportLink'
import { computed, reactive, ref } from 'vue'
import { useUrlParams } from 'src/composables/useUrlParams'
import { REPORT_MAX_REPORT_LINKS } from 'src/models/new/report'

const urlParamsTemplate = {
  reportId: 0,
}

export const useReportLinks = () => {
  const reportLinksRef = ref<ReportLink[]>([])

  const {
    enableUrlParams,
    urlParamsRef,
  } = useUrlParams<typeof urlParamsTemplate>()
  enableUrlParams(urlParamsTemplate)

  const loadReportLinks = async() => {
    const reportId = urlParamsRef.value.reportId
    if (reportId) {
      reportLinksRef.value = await reportLinkApi.index(reportId)
    } else {
      console.error('reportId is not in url params')
    }
  }

  const reportLinksState = reactive({
    isEmpty: computed(() => reportLinksRef.value.length === 0),
    isFull: computed(() => reportLinksRef.value.length >= REPORT_MAX_REPORT_LINKS),
  })

  return {
    reportLinksRef,
    loadReportLinks,
    reportLinksState,
  }
}
