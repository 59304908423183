
import { TIME_SPANS, TimeSpan } from 'src/consts'
import { formatDate } from 'src/util/datetime'
import { defineComponent, PropType, reactive, SetupContext, watch } from 'vue'
import { SYSTEM_DATETIME_FORMAT } from 'src/util/Datetime/format'

export type ReportSearchState = {
  name: string | null
  timeSpan: TimeSpan | null
  includeDisabled: boolean
}
type State = {
  search: ReportSearchState
}

export default defineComponent({
  props: {
    initParams: {
      type: Object as PropType<Partial<ReportSearchState>>,
      default: null,
    },
  },
  setup(props, context: SetupContext) {
    const now = new Date()
    const state: State = reactive({
      search: {
        name: '',
        timeSpan: null,
        date: formatDate(now, SYSTEM_DATETIME_FORMAT),
        includeDisabled: false,
      },
    })

    watch(() => [props.initParams], () => {
      if (!props.initParams) { return }
      if ('name' in props.initParams) { state.search.name = props.initParams.name! }
      if ('timeSpan' in props.initParams) { state.search.timeSpan = props.initParams.timeSpan! }
      if ('includeDisabled' in props.initParams) { state.search.includeDisabled = props.initParams.includeDisabled! }
    }, { immediate: true })

    const timeSpans = TIME_SPANS

    function onSearch(): void {
      context.emit('on-search', { ...state.search })
    }

    return {
      state,
      timeSpans,
      onSearch,
    }
  },
})
