import { TIME_SPAN_DAILY, TimeSpan } from 'src/business/timeSpan';
import { Section } from 'src/models/new/section';

export type Report = {
  id: number;
  name: string;
  timeSpan: TimeSpan;
  isEnabled: boolean;
  sections: Section[];
  // Sectionから移植予定
  // components: Component[]
  accessGroupIds: number[];
  // モデルの巨大化を防ぐため結合度の低いドメインはレポートの内部に取り込まない
  // リンクが存在するかフラグで管理し、存在する場合は別途APIで取得する
  includesLinks?: boolean;
  startingDate: Date | null;
  closingDate: Date | null;
};

export const REPORT_MAX_SECTIONS = 15;
export const REPORT_MAX_COMPONENTS = 60;
export const REPORT_MAX_REPORT_LINKS = 6;
export const REPORT_ACCESS_GROUP_MAX_COUNT = 10;

export const constructEmptyReport = (): Report => {
  return {
    id: 0,
    name: '',
    timeSpan: TIME_SPAN_DAILY,
    isEnabled: true,
    accessGroupIds: [],
    sections: [],
    // Sectionから移植予定
    // components: [],
    startingDate: null,
    closingDate: null,
  };
};

export const isReportContainsMaxOrMoreSections = (report: Report): boolean => {
  return report.sections.length >= REPORT_MAX_SECTIONS;
};
export const isReportContainsMaxOrMoreComponents = (report: Report): boolean => {
  const count = report.sections.reduce((total, section) => {
    return total + section.components.length;
  }, 0);
  return count >= REPORT_MAX_COMPONENTS;
};
