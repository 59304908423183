import ChartDataLabels from 'chartjs-plugin-datalabels'
import { ChartItem, formatChartValue, getValueOnGraphFromGraphItem } from 'src/util/Chart/custom/shared'
import { PieChartOptions } from '../pieChart'

export type PieChartItem = ChartItem & {
  isOrdered?: boolean
}

export type CustomPieChartOptions = PieChartOptions & {
  // ツールチップの型定義はPieChartOptionsの中で緩い定義になっているため、明確にする
  plugins: {
    datalabels: {
      formatter: (value: number | null) => string
    }
  }
}

// FIXME: 引数を与えなくても済むようにする
// ChartItemがunitを持ち、plugins.datalabels.formatterが第2引数にcontextを取って、そこから探索するように変更すればよい
export const getOptionForPieChart = (unit: string | null): CustomPieChartOptions => {
  return {
    plugins: {
      datalabels: {
        formatter: (value: number | null) => {
          const unitToDisplay = unit ? ` ${unit}` : ''
          return value ? `${formatChartValue(value)}${unitToDisplay}` : ''
        },
        font: {
          size: 20,
        },
      },
    },
  }
}

export const getOptionForRatePieChart = (): CustomPieChartOptions => {
  return {
    plugins: {
      datalabels: {
        formatter: (value: number | null) => value ? `${value} %` : '',
        font: {
          size: 20,
        },
      },
    },
  }
}

type Plugin = Chart.PluginServiceGlobalRegistration & Chart.PluginServiceRegistrationOptions

export const getPieChartPlugins = (isValueHidden: boolean): Plugin[] => {
  return isValueHidden ? [] : [ChartDataLabels]
}

export const getOrderedPieChartItems = (pieChartItems: PieChartItem[]): PieChartItem[] => {
  return [...pieChartItems].sort((a, b) => (b.value || 0) - (a.value || 0))
    .map(el => ({ ...el, isOrdered: true }))
}

export type CustomPieChartDataDataset = {
  data: number[]
  actualData: Array<number | null>
  borderColor: string[] | string
  backgroundColor: string[] | string
}

export const datasetFromPieChartItems = (pieChartItems: PieChartItem[]): CustomPieChartDataDataset => {
  if (pieChartItems.some(el => !el.isOrdered)) throw new Error('pieChartItems must be ordered')

  const chartValues = pieChartItems.map(el => getValueOnGraphFromGraphItem(el))
  const originalValues = chartValues.map((value, index) => pieChartItems[index].value !== null ? value : null)
  const colors = pieChartItems.map(el => `#${el.color.replaceAll('#', '')}`)

  return {
    data: chartValues,
    actualData: originalValues,
    borderColor: colors,
    backgroundColor: colors,
  }
}

export const rateDatasetFromPieChartItems = (pieChartItems: PieChartItem[]): CustomPieChartDataDataset => {
  if (pieChartItems.some(el => !el.isOrdered)) throw new Error('pieChartItems must be ordered')

  const sumOfValues = pieChartItems.reduce((sum, el) => sum + (el.value || 0), 0)
  const chartValues = pieChartItems.map(el => Math.round((el.value || 0) / sumOfValues * 100 || 0))

  if (chartValues.some(el => el !== 0)) {
    // 合計が100になるように最も大きな値を調整
    chartValues[0] = 100 - chartValues.slice(1).reduce((sum, el) => sum + el, 0)
  }

  const originalValues = chartValues.map((value, index) => pieChartItems[index].value !== null ? value : null)

  const colors = pieChartItems.map(el => `#${el.color.replaceAll('#', '')}`)

  return {
    data: chartValues,
    actualData: originalValues,
    borderColor: colors,
    backgroundColor: colors,
  }
}

export const legendLabelsFromPieChartItems = (pieChartItems: PieChartItem[]): string[] => {
  if (pieChartItems.some(el => !el.isOrdered)) throw new Error('pieChartItems must be ordered')

  return pieChartItems.map(el => el.label)
}
