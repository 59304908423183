import { ComponentResponseData, extractLastSearchedDate } from '../componentResponseData'
import { buildBaseComponentProperties } from './buildBaseComponentProperties'
import {
  MetricsTableComponent,
  MetricsTableComponentTableCell
} from 'src/models/new/Component/MetricsComponent/metricsTableComponent'
import { ComponentType, internal as componentInternal } from 'src/models/new/component'
import { convertFromMetricsResponseData } from 'src/models/api/Metrics/metricsResponseData'
import { HeaderStructure, MatrixHeaders } from 'src/util/dataHeader'
import {
  buildConditionalStatementProperties
} from 'src/models/api/Report/Component/propertyBuilders/buildConditionalStatementProperties'

const METRICS_TABLE = componentInternal.METRICS_TABLE

const buildHeaderStructure = (matrix: string[][]): HeaderStructure => {
  const isPositionFirst = (position: number) => position === 1
  const isIncludedInOtherCell = (value: string | null, position: number) => {
    return value === null && !isPositionFirst(position)
  }

  return {
    depth: matrix.length || 0,
    breadth: matrix[0]?.length || 0,
    data: matrix.map((layer, layerIndex) => {
      let currentSpan = 1
      // layerを直接逆順にしてしまうと、戻り値としては最後に再度reverseするので帳尻が合うが、
      // 再度のreverseは複製に対して行われるので反転したままになってしまう
      return [...layer].reverse().map((cellString, reverseIndex) => {
        const value = cellString !== '' ? cellString : null
        const position = matrix[0]!.length - reverseIndex
        const cell = {
          level: layerIndex + 1,
          position: position,
          span: isIncludedInOtherCell(value, position) ? 0 : currentSpan,
          value: value,
        }
        if (isIncludedInOtherCell(value, position)) {
          currentSpan++
        } else {
          currentSpan = 1
        }
        return cell
      }).reverse()
    }).flat() || []
  }
}

const buildHeaderHeadLayout = (data: ComponentResponseData): HeaderStructure => {
  return buildHeaderStructure(data.table_component!.header.cols)
}

const buildHeaderSideLayout = (data: ComponentResponseData): HeaderStructure => {
  const invertedHeaderRows = [[]] as string[][]
  data.table_component!.header.rows.forEach((cells) => {
    cells.forEach((cell, index) => {
      if (!invertedHeaderRows[index]) invertedHeaderRows[index] = []
      invertedHeaderRows[index].push(cell)
    })
  })
  return buildHeaderStructure(invertedHeaderRows)
}

const buildDataCells = (data: ComponentResponseData): MetricsTableComponentTableCell[] => {
  // 編集用にデータをロードした場合は存在しない
  // closingDateMapがnullの場合は編集用で、valueやclosingDateが存在しないものとして扱う
  const closingDateMap = data.table_component!.metrics_value_searched_dates_map ?? null

  return data.table_component!.metrics_table_components.map(el => {
    const metrics = convertFromMetricsResponseData(el.metrics, {})
    if (closingDateMap) {
      const valueOfMetrics = data.table_component!.metrics_values_idx_metrics_id[metrics.id]?.value ?? null
      metrics.value = valueOfMetrics !== null ? Number(valueOfMetrics) : null
      metrics.closingDate = extractLastSearchedDate(closingDateMap, metrics.timeSpan)
    }

    return {
      row: el.row_number,
      column: el.column_number,
      metrics: metrics,
      conditionalStatements: data.component_conditional_statements.filter(statement => {
        return statement.column_number === el.column_number && statement.row_number === el.row_number
      }).map(statement => buildConditionalStatementProperties(statement, data.table_component!.metrics_value_searched_dates_map))
    }
  })
}

export const buildMetricsTableComponentProperties = (data: ComponentResponseData, referenceDate: Date | null): MetricsTableComponent => {
  const componentType: ComponentType = METRICS_TABLE

  const headLayout = buildHeaderHeadLayout(data)
  const sideLayout = buildHeaderSideLayout(data)
  const computed = { depth: 1, breadth: 1, data: [] } // 利用しないので適当な値を入れておく
  const headers = {
    head: {
      layout: headLayout,
      computed: computed,
    },
    side: {
      layout: sideLayout,
      computed: computed,
    }
  } as MatrixHeaders
  const dataCells = buildDataCells(data)

  return {
    ...buildBaseComponentProperties(data, componentType),
    referenceDate,
    isUnitIndividualDisplayed: data.table_component!.is_unit_indivisual_displayed,
    headers,
    rows: 1, // TODO: 利用しなかったので必要かどうか確認する
    columns: 1, // TODO: 利用しなかったので必要かどうか確認する
    data: dataCells,
  }
}
