
import { computed, defineComponent, getCurrentInstance, onMounted, reactive, watch } from 'vue';
import Draggable from 'vuedraggable';
import { notifyError1, notifySuccess1 } from 'src/hooks/notificationHook';
import { setPageName } from 'src/hooks/displayPageNameHook';
import { wrappedMapGetters } from 'src/hooks/storeHook';
import { ensureUserAndMasters } from 'src/hooks/masterHook';
import reportApi from 'src/apis/report';
import reportLinkApi from 'src/apis/reportLink';
import { ERROR_GROUP_SYSTEM, ErrorGroup } from 'src/consts';
import { formatDate, parseYmdDate, isSameDay, isSameMonth, endOfToday, subDays } from 'src/util/datetime';
import UpdateMetricsValueModal from './components/UpdateMetricsValueModal.vue';
import UpdateCommentValueModal from './components/UpdateCommentValueModal.vue';
import SectionView from './components/SectionView.vue';
import type { Metrics } from 'src/models/new/metrics';
import { CommentComponent } from 'src/models/new/Component/commentComponent';
import {
  isLoginUserAuthorizedToEditReport,
  isLoginUserAuthorizedToWriteCommentOnReport,
} from 'src/models/new/User/loginUser';
import type { Report } from 'src/models/new/report';
import { timeSpanToLocalWord } from 'src/business/timeSpan';
import { SYSTEM_DATE_FORMAT } from 'src/util/Datetime/format';
import type { DatePickerOptions } from 'element-ui/types/date-picker';
import AppLink from 'src/components/UIComponents/AppLink.vue';
import { useReportValuesDetailPageContext } from 'src/views/Dashboard/ReportValues/Detail/composables/useReportValuesDetailPageContext';
import { TooltipMenuSettings } from 'src/components/UIComponents/Tooltip/TooltipContent/TooltipContent.vue';
import TooltipButton from 'src/components/UIComponents/Tooltip/TooltipButton.vue';
import type { ReportLink } from 'src/models/new/Link/reportLink';
import PrimaryTitleBadge from 'src/components/PrimaryTitleBadge.vue';
import { useActionLog } from 'src/composables/useActionLog';
import { VIEW_REPORT_ACTION } from 'src/models/api/actionLog/const';

interface State {
  isLoaded: boolean;

  pageName: string | null;
  date: Date;

  report: Report | null;
  reportLinks: ReportLink[];

  displayDateStr: string;
  reportLinkTooltipMenus: TooltipMenuSettings[];
  showEditButton: boolean;

  showEditValueModal: boolean;

  updateCommentComponentCandidate: CommentComponent | null;
  showEditCommentModal: boolean;

  metricsLayout: object[];
  editValueLabel: string | null;
  focusedMetrics: Metrics | null;
}

export default defineComponent({
  components: {
    AppLink,
    PrimaryTitleBadge,
    TooltipButton,
    SectionView,
    UpdateMetricsValueModal,
    UpdateCommentValueModal,
    Draggable,
  },
  setup() {
    const root = getCurrentInstance()!.proxy;
    const { pageContext } = useReportValuesDetailPageContext();
    setPageName(root, pageContext.pageName);

    const state: State = reactive({
      isLoaded: false,
      ...wrappedMapGetters(root.$store, 'displayPageName', ['pageName']),
      date: computed(() => {
        return root.$route.query.dt ? parseYmdDate(root.$route.query.dt as string) : subDays(new Date(), 1);
      }),
      report: null,
      reportLinks: [],
      displayDateStr: computed(() => {
        return buildDateString(state.report?.startingDate ?? null, state.report?.closingDate ?? null);
      }),
      reportLinkTooltipMenus: computed(() => {
        return state.reportLinks.map((el) => {
          return {
            text: el.name,
            link: {
              to: {
                path: el.url,
                query: { dt: root.$route.query.dt },
              },
              target: '_blank',
            },
          };
        });
      }),
      showEditButton: computed(() => {
        if (!state.report) {
          return false;
        }

        const loginUser = root.$store.getters['loginUser/data'];
        return isLoginUserAuthorizedToEditReport(loginUser, state.report);
      }),
      showEditValueModal: false,
      updateCommentComponentCandidate: null,
      showEditCommentModal: false,
      metricsLayout: [{ x: 0, y: 0, w: 280, h: 1, i: '0' }],
      editValueLabel: null,
      focusedMetrics: null,
    });

    const { sendActionLog: sendReportViewActionLog } = useActionLog(VIEW_REPORT_ACTION);

    const buildDateString = (startingDate: Date | null, closingDate: Date | null) => {
      if (!startingDate || !closingDate) {
        return '';
      }

      if (isSameDay(startingDate, closingDate)) {
        return formatDate(startingDate, 'yyyy年MM月dd日(E)');
      } else if (isSameMonth(startingDate, closingDate)) {
        const startingDateStr = formatDate(startingDate, 'yyyy年MM月dd日(E)');
        const closingDateStr = formatDate(closingDate, 'MM月dd日(E)');
        return `${startingDateStr} 〜 ${closingDateStr}`;
      } else {
        const startingDateStr = formatDate(startingDate, 'yyyy年MM月dd日(E)');
        const closingDateStr = formatDate(closingDate, 'yyyy年MM月dd日(E)');
        return `${startingDateStr} 〜 ${closingDateStr}`;
      }
    };

    const loadReportValue = async (): Promise<void> => {
      const id = pageContext.getReportId();
      if (!id) {
        return;
      }
      try {
        const params = {
          id,
          dt: formatDate(state.date, SYSTEM_DATE_FORMAT),
        };
        state.report = await reportApi.show(params);

        sendReportViewActionLog({ reportId: id, report_date: state.date });
      } catch (err: any) {
        pageContext.onFailedToGetReport(err);
      }
    };

    watch(
      () => root.$route.query,
      async () => {
        await loadReportValue();
      },
    );

    // 暫定実装: 全面的にcomposablesに移行する場合、useReportLinksを作成する
    const loadReportLinks = async (): Promise<void> => {
      const id = pageContext.getReportId();
      if (!id) {
        return;
      }
      state.reportLinks = (await reportLinkApi.index(id)).sort((a, b) => a.sequentialOrder - b.sequentialOrder);
    };

    const onReportLinkTooltipButtonClick = () => {
      loadReportLinks();
    };

    const openEditValueModal = (metrics: Metrics, editValueLabel: string = ''): void => {
      state.focusedMetrics = structuredClone(metrics);
      state.editValueLabel = editValueLabel;

      state.showEditValueModal = true;
      state.showEditCommentModal = false;
    };

    const closeEditValueModal = (): void => {
      state.showEditValueModal = false;
      state.editValueLabel = null;
      state.focusedMetrics = null;
    };

    const completeEditValue = (message: string): void => {
      loadReportValue();
      closeEditValueModal();
      notifySuccess1(root, message);
    };

    const openEditCommentModal = (commentComponent: CommentComponent): void => {
      if (!isLoginUserAuthorizedToWriteCommentOnReport(root.$store.getters['loginUser/data'], state.report!)) {
        notifyError1(root, 'コメントの変更権限がありません。管理者にお問合せください。');
        return;
      }

      state.updateCommentComponentCandidate = structuredClone(commentComponent);
      state.showEditCommentModal = true;
    };

    const closeEditCommentModal = (): void => {
      state.showEditCommentModal = false;
      state.updateCommentComponentCandidate = null;
    };

    const completeEditCommentValue = (message: string): void => {
      loadReportValue();
      closeEditCommentModal();
      notifySuccess1(root, message);
    };

    const editReport = async () => {
      await root.$router.push({
        // TODO: 戻ってくる側のリンクを変更する
        name: 'SettingsReportsEdit',
        params: {
          reportId: String(state.report?.id),
          fromRoute: 'ReportValuesDetail',
        },
      });
    };

    const reportError = async (errorGroup: ErrorGroup, message: string, error: any) => {
      const formattedMessage = errorGroup === ERROR_GROUP_SYSTEM ? message + `(ERR: ${state.pageName})` : message;

      notifyError1(root, formattedMessage, error);
    };

    const onDateInput = async (date: Date): Promise<void> => {
      if (!state.report) {
        return;
      }
      pageContext.onDateChange(date);
    };

    const initialize = async () => {
      if (!pageContext.getReportId()) {
        await root.$router.replace({ name: 'ReportValues' });
        return;
      }

      await Promise.all([ensureUserAndMasters(root), loadReportValue()]);
    };

    onMounted(async () => {
      // Vue 2x 暫定措置 3x系の場合はonUnmountedでフラグを戻す
      // Vue 2x ではonUnmountedがdestroyedに対するフックのエイリアスであるためonMountedの先頭に記述している
      state.isLoaded = false;

      await initialize();

      state.isLoaded = true;
    });

    const futureMax = endOfToday();
    const datePickerOptions: DatePickerOptions = {
      disabledDate: (date: Date) => date > futureMax,
    };

    return {
      state,
      pageContext,
      onReportLinkTooltipButtonClick,
      openEditValueModal,
      closeEditValueModal,
      completeEditValue,
      openEditCommentModal,
      closeEditCommentModal,
      editReport,
      reportError,
      completeEditCommentValue,
      timeSpanToLocalWord,
      datePickerOptions,
      onDateInput,
    };
  },
});
