
import Vue, { computed, defineComponent, getCurrentInstance, onMounted, reactive } from 'vue'
import Draggable from 'vuedraggable'
import { setPageName } from 'src/hooks/displayPageNameHook'
import workplaceApi from 'src/apis/masters/workplace'
import { Workplace } from 'src/models/new/workplace'
import { vvGetError, vvGetErrorObject, vvHasError, vvReset, vvValidate } from 'src/util/vee_validate'
import { notifyError1, notifySuccess1 } from 'src/hooks/notificationHook'
import { ensureUserRefreshAndMasters } from 'src/hooks/masterHook'
import { ERROR_REASON_IN_USE } from 'src/consts'
import { getGatedFuncGenerator } from 'src/util/timingControlUtil'
import InputError from 'src/components/InputError.vue'

const displayPageName = '集計用センター'
type OpType = 'create' | 'update' | 'delete' | 'update_disp_order'
const msgVars = { create: '作成', update: '編集', delete: '削除', update_disp_order: '表示順変更' }
const errIds = { create: 'ERR00001', update: 'ERR00002', delete: 'ERR00003', update_disp_order: 'ERR00004' }

type SaveCandidate = Partial<Workplace> & {
  isNew?: boolean
}
type DeleteCandidate = Workplace

interface State {
  userId: number
  hasMetricsGtManagerRole: boolean
  hasError: boolean
  validations: Record<string, object>
  workplaces: Workplace[],
  showSaveModal: boolean
  saveCandidate: SaveCandidate
  showDeleteModal: boolean
  deleteCandidate: DeleteCandidate | null
  hasPageAccessRole: boolean
}

function setupState(root: Vue): State {
  const state: State = reactive({
    userId: computed(() => root.$store.getters['user/id']),
    hasMetricsGtManagerRole: computed(() => root.$store.getters['user/hasMetricsGtManagerRole']),
    hasError: computed(() => vvHasError(root)),
    validations: {
      name: { required: true, max: 50 },
    },
    workplaces: [],
    showSaveModal: false,
    saveCandidate: { isNew: true },
    showDeleteModal: false,
    deleteCandidate: null,
    hasPageAccessRole: true,
  })
  return state
}

export default defineComponent({
  components: { Draggable, InputError },
  setup(_props) {
    const vueInstance = getCurrentInstance()!.proxy
    setPageName(vueInstance, displayPageName)

    const state = setupState(vueInstance)

    function getError(fieldName: string): string | null {
      return vvGetError(vueInstance, fieldName)
    }
    function getErrorObject(fieldName: string): object | null {
      return vvGetErrorObject(vueInstance, fieldName)
    }
    function clearErrors(): void {
      vvReset(vueInstance)
    }

    async function getList(): Promise<void> {
      try {
        state.workplaces = await workplaceApi.index()
      } catch (err: any) {
        const errStatus = err.response.status
        if (errStatus === 403) {
          state.hasPageAccessRole = false
        }
      }
    }

    function openCreateModal(): void {
      state.saveCandidate = {
        isNew: true,
        name: '',
      }
      state.showSaveModal = true
    }

    function openUpdateModal(item: SaveCandidate): void {
      state.saveCandidate = { ...item, isNew: false }
      state.showSaveModal = true
    }

    function closeSaveModal(): void {
      state.showSaveModal = false
      clearErrors()
    }

    function notifyCommonError(opType: OpType, err?: Error) {
      const msg = `${displayPageName}の${msgVars[opType]}に失敗しました。` +
        '管理者に連絡してください。' +
        `(ERR: ${displayPageName} ${errIds[opType]}, user_id:${state.userId})`
      notifyError1(vueInstance, msg, { err })
    }

    async function saveItem(): Promise<void> {
      const isValid = await vvValidate(vueInstance)
      if (!isValid) { return }

      if (state.saveCandidate.isNew) {
        await createItem(state.saveCandidate)
      } else {
        await updateItem(state.saveCandidate)
      }
    }

    function onSaveItemError(err: any, opType: OpType): void {
      const errStatus = err.response.status
      const errRes = err.response.data || {}
      if (errStatus === 400 && Array.isArray(errRes) && errRes.some(msg => msg.match(/既に使用されています/))) {
        const msg = 'その集計用センター名は既に使用されています。'
        notifyError1(vueInstance, msg, { timeout: 5 * 1000 })
      } else {
        notifyCommonError(opType, err as Error)
      }
    }

    async function createItem(saveCandidate: SaveCandidate): Promise<void> {
      const opType: OpType = 'create'
      const reqData = { name: saveCandidate.name! }
      try {
        await workplaceApi.create(reqData)
        await getList()
        closeSaveModal()
        notifySuccess1(vueInstance, `${displayPageName}を${msgVars[opType]}しました`)
      } catch (err: any) {
        onSaveItemError(err, opType)
      }
    }

    async function updateItem(saveCandidate: SaveCandidate): Promise<void> {
      const opType: OpType = 'update'
      const itemId = saveCandidate.id!
      const reqData = { name: saveCandidate.name! }
      try {
        await workplaceApi.update(itemId, reqData)
        await getList()
        closeSaveModal()
        notifySuccess1(vueInstance, `${displayPageName}を${msgVars[opType]}しました`)
      } catch (err: any) {
        onSaveItemError(err, opType)
      }
    }

    function openDeleteModal(item: DeleteCandidate): void {
      state.deleteCandidate = item
      state.showDeleteModal = true
    }

    function closeDeleteModal(): void {
      state.showDeleteModal = false
    }

    async function deleteItem(): Promise<void> {
      const opType: OpType = 'delete'
      if (!state.deleteCandidate) { return }
      try {
        await workplaceApi.destroy(state.deleteCandidate.id)
        await getList()
        closeDeleteModal()
        notifySuccess1(vueInstance, `${displayPageName}を${msgVars[opType]}しました`)
      } catch (err: any) {
        const errStatus = err.response.status
        const errRes = err.response.data || {}
        if (errStatus === 400 && errRes.reason === ERROR_REASON_IN_USE) {
          const msg = 'すでに使われているマスタです。削除できません。'
          notifyError1(vueInstance, msg, { timeout: 5 * 1000 })
        } else {
          notifyCommonError(opType, err as Error)
        }
      }
    }

    async function updateDispOrder(): Promise<void> {
      try {
        state.workplaces.forEach((e, i) => {
          e.sequentialOrder = i + 1
        })
        const reqData = {
          items: state.workplaces.map((e, i) => ({
            id: e.id,
            disp_order: i + 1,
          })),
        }
        state.workplaces = await workplaceApi.bulkUpdateDispOrder(reqData)
      } catch (err) {
        notifyCommonError('update_disp_order', err as Error)
      }
    }

    onMounted(async() => {
      await ensureUserRefreshAndMasters(vueInstance)
      await getList()
    })

    const gatedFuncGenerator = getGatedFuncGenerator()
    return {
      state,
      getError,
      openCreateModal,
      openUpdateModal,
      closeSaveModal,
      getErrorObject,
      openDeleteModal,
      closeDeleteModal,
      saveItem: gatedFuncGenerator.makeAsyncFuncGated(saveItem),
      deleteItem: gatedFuncGenerator.makeAsyncFuncGated(deleteItem),
      updateDispOrder: gatedFuncGenerator.makeAsyncFuncGated(updateDispOrder),
      dragOptions: {
        handle: '.grabbable',
        animation: 300,
      },
    }
  },
})

