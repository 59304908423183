
import { computed, defineComponent, onMounted, PropType, reactive } from 'vue';
import {
  ERROR_GROUP_SYSTEM,
  ERROR_GROUP_USER,
  ERROR_REASON_INVALID,
  ERROR_REASON_ACCESS_GROUP_NOT_FOUND,
  ERROR_REASON_LOGISCOPE_WORKPLACE_NOT_FOUND,
  ERROR_REASON_METRICS_NOT_FOUND,
} from 'src/consts';
import metricsApi from 'src/apis/masters/metrics';
import { LogiSystemDataMetrics } from 'src/models/new/Metrics/BasicMetrics/logiSystemDataMetrics';
import SelectAccessGroupForm from 'src/components/NewSelectItemForm/SelectAccessGroupForm.vue';
import { requestTimeout } from 'src/util/request_animation_frame';
import { isRecordIdValid } from 'src/util/recordId';
import { MetricsAccessGroup } from 'src/models/new/AccessGroup/metricsAccessGroup';
import { metricsToTypeLocalWord, METRICS_ACCESS_GROUP_MAX_COUNT } from 'src/models/new/metrics';
import LogiSystemDataMetricsBaseForm from 'src/views/Dashboard/Settings/Metrics/components/LogiSystemDataMetricsFormModal/LogiSystemDataMetricsBaseForm.vue';
import { useMetricsAccessGroupsOfMetrics } from 'src/views/Dashboard/Settings/Metrics/composables/useMetricsAccessGroupsOfMetrics';
import { useValidator } from 'src/composables/useValidator';
import { useDataSources } from 'src/composables/asyncResources/useDataSources';
import { useLogiCoredataBudgetGroups } from 'src/composables/asyncResources/useLogiCoredataBudgetGroups';
import { useSimpleEvent } from 'src/composables/useSimpleEvent';

interface State {
  // 各種マスタデータのロード完了を示すフラグ
  isLoaded: boolean;
  // リクエスト送信処理中であることを表すフラグ
  isRequesting: boolean;
  showMetricsAccessGroupSelectForm: boolean;
  metrics: LogiSystemDataMetrics;
  metricsIsNew: boolean;
  metricsTypeLocalWord: string;
  userSelectMetricsAccessGroups: Array<MetricsAccessGroup>;
}

const CONFIRM_ACCESS_GROUP_EVENT_KEY = 'confirmSelectAccessGroupForm';
const SUBMIT_EVENT_KEY = 'submitLogiSystemDataMetricsForm';

export default defineComponent({
  components: {
    SelectAccessGroupForm,
    LogiSystemDataMetricsBaseForm,
  },
  props: {
    value: {
      type: Object as PropType<LogiSystemDataMetrics>,
      required: true,
    },
  },
  emits: ['input', 'updated', 'close', 'shouldReport'],
  setup(props, { emit }) {
    const state: State = reactive({
      isLoaded: false,
      isRequesting: false,
      showMetricsAccessGroupSelectForm: false,
      metrics: computed({
        get() {
          return props.value;
        },
        set(next) {
          emit('input', next);
        },
      }),
      metricsIsNew: computed(() => !isRecordIdValid(state.metrics.id)),
      metricsTypeLocalWord: computed(() => metricsToTypeLocalWord(state.metrics)),
      userSelectMetricsAccessGroups: computed({
        get(): Array<MetricsAccessGroup> {
          return metricsAccessGroupsOnMetricsWithoutAdmin.value;
        },
        set(value: Array<MetricsAccessGroup>) {
          metricsAccessGroupsOnMetrics.value = metricsAccessGroupOnMetricsWithAdmin.value
            ? [metricsAccessGroupOnMetricsWithAdmin.value, ...value]
            : value;
        },
      }),
    });

    const {
      metricsAccessGroupsOnMetrics,
      metricsAccessGroupsOnMetricsWithoutAdmin,
      metricsAccessGroupOnMetricsWithAdmin,
      loadMetricsAccessGroupsOnMetrics,
    } = useMetricsAccessGroupsOfMetrics();

    const { validationResult } = useValidator();

    const goToAccessGroupSelectForm = (): void => {
      state.showMetricsAccessGroupSelectForm = true;
    };
    const backToMainForm = (): void => {
      state.showMetricsAccessGroupSelectForm = false;
    };

    // See: SelectAccessGroupForm
    const setSelectedAccessGroups = (metricsAccessGroups: Array<MetricsAccessGroup>): void => {
      state.userSelectMetricsAccessGroups = metricsAccessGroups;
      backToMainForm();
    };

    const { triggerer: confirmEventTriggerer } = useSimpleEvent(CONFIRM_ACCESS_GROUP_EVENT_KEY);

    const triggerConfirmEvent = () => {
      confirmEventTriggerer.trigger();
    };

    const { logiCoredataBudgetGroupsRef } = useLogiCoredataBudgetGroups();

    const { prepareDataSourceParameterValues } = useDataSources();

    const loadDataSourceParameterValues = async (dataSourceParameterId: number) => {
      const budgetGroup = logiCoredataBudgetGroupsRef.value.find((el) => el.id === state.metrics.budgetGroupId);
      if (budgetGroup === undefined) {
        return;
      }

      await prepareDataSourceParameterValues(budgetGroup, dataSourceParameterId);
    };

    const { triggerer: submitEventTriggerer } = useSimpleEvent(SUBMIT_EVENT_KEY);

    const triggerSubmitEvent = () => {
      submitEventTriggerer.trigger();
    };

    const close = (): void => emit('close');

    const save = async (): Promise<void> => {
      // 二重処理防止
      if (state.isRequesting) {
        return;
      }
      state.isRequesting = true;
      requestTimeout(() => {
        state.isRequesting = false;
      }, 300);

      const metrics: LogiSystemDataMetrics = {
        ...state.metrics,
        accessGroupIds: state.userSelectMetricsAccessGroups.map((metricsAccessGroup) => metricsAccessGroup.id),
      };
      const operationWord = state.metricsIsNew ? '作成' : '更新';
      try {
        if (state.metricsIsNew) {
          await metricsApi.create(metrics);
        } else {
          await metricsApi.update(metrics);
        }
        emit('updated', `${state.metrics.name} を${operationWord}しました。`);
      } catch (err: any) {
        const errId = state.metricsIsNew ? 'ERR00001' : 'ERR00002';
        const errStatus = err.response.status;
        const errRes = err.response.data || {};
        if ([403, 404].includes(errStatus)) {
          const msg = 'アクセスする権限がありません。管理者にお問合せください。';
          emit('shouldReport', ERROR_GROUP_USER, msg, err);
        } else if (errStatus === 400 && errRes.reason === ERROR_REASON_METRICS_NOT_FOUND) {
          const msg = '選択したメトリクスは存在しません。ページを更新し、再度お試しください';
          emit('shouldReport', ERROR_GROUP_USER, msg, err);
        } else if (errStatus === 400 && errRes.reason === ERROR_REASON_ACCESS_GROUP_NOT_FOUND) {
          const msg = '選択したメトリクスグループは存在しません。ページを更新し、再度お試しください';
          emit('shouldReport', ERROR_GROUP_USER, msg, err);
        } else if (errStatus === 400 && errRes.reason === ERROR_REASON_LOGISCOPE_WORKPLACE_NOT_FOUND) {
          const msg = '選択した集計用センターは存在しません。ページを更新し、再度お試しください';
          emit('shouldReport', ERROR_GROUP_USER, msg, err);
        } else if ((errStatus === 400 && errRes.reason === ERROR_REASON_INVALID) || errStatus === 409) {
          emit('shouldReport', ERROR_GROUP_USER, errRes.message, err);
        } else {
          const msg = `メトリクスの${operationWord}に失敗しました。管理者に連絡してください。`;
          // TODO: notifyのオプションと統合して、引数が長くならないように、ページ間で異ならないように統一する
          emit('shouldReport', ERROR_GROUP_SYSTEM, msg, err, errId);
        }
      }
    };

    onMounted(async () => {
      // Vue 2x 暫定措置 3x系の場合はonUnmountedでフラグを戻す
      // Vue 2x ではonUnmountedがdestroyedに対するフックのエイリアスであるためonMountedの先頭に記述している
      state.isLoaded = false;
      state.showMetricsAccessGroupSelectForm = false;

      const parameterIds = Array.from(new Set(state.metrics.queryParameters.map((el) => el.parameterId))).filter(
        (el) => el !== undefined,
      );

      await Promise.all([
        ...parameterIds.map((parameterId) => loadDataSourceParameterValues(parameterId)),
        loadMetricsAccessGroupsOnMetrics(state.metrics),
      ]);

      state.isLoaded = true;
    });

    return {
      props,
      state,
      METRICS_ACCESS_GROUP_MAX_COUNT,
      goToAccessGroupSelectForm,
      backToMainForm,
      setSelectedAccessGroups,
      CONFIRM_ACCESS_GROUP_EVENT_KEY,
      triggerConfirmEvent,
      SUBMIT_EVENT_KEY,
      triggerSubmitEvent,
      validationResult,
      save,
      close,
    };
  },
});
