
import Vue, { computed, defineComponent, getCurrentInstance, onMounted, reactive, ref } from 'vue';
import { setPageName } from 'src/hooks/displayPageNameHook';
import { wrappedMapGetters } from 'src/hooks/storeHook';
import { ensureUserRefreshAndMasters } from 'src/hooks/masterHook';
import reportApi from 'src/apis/report';
import userViewSettingApi from 'src/apis/masters/userViewSetting';
import { notifyError1 } from 'src/hooks/notificationHook';
import { ERROR_REASON_INVALID_PARAMS } from 'src/consts';
import SortButton from 'src/components/UIComponents/Sorter/SortButton.vue';
import { useSortContext } from 'src/components/UIComponents/Sorter/SortContext.vue';
import { SortSpec } from 'src/components/UIComponents/Sorter/types';
import { getGatedFuncGenerator } from 'src/util/timingControlUtil';
import { routerShowNextPage } from 'src/hooks/routeHook';
import ReportSearchPanel, { ReportSearchState } from 'src/components/ReportSearchPanel/index.vue';
import { compareFuncTimeSpan, TimeSpan, timeSpanToLocalWord } from 'src/business/timeSpan';
import { Report } from 'src/models/new/report';
import { UserViewSetting } from 'src/models/new/userViewSetting';
import { usePaginationContainer } from 'src/components/UIComponents/PaginationContainer.vue';
import { OrderElement } from 'src/models/api/shared/orderElement';

const ITEM_PER_PAGE = 50;

interface State {
  pageName: string | null;
  userId: number | null;
  hasPageAccessRole: boolean;
  hasReportGtManagerRole: boolean;
  // 検索パフォームのパラメータ
  // フォームの変更に対しては変化せず、フォームが送信されるタイミングで変化する
  searchParams: ReportSearchState;
  list: Report[];
  hasList: boolean;
  dataLoadState: { availableTotal: number; loadedSlices: number };
  isSortable: boolean;
  userViewSetting: UserViewSetting | null;
}

function setupState(root: Vue): State {
  const state: State = reactive({
    ...wrappedMapGetters(root.$store, 'displayPageName', ['pageName']),
    userId: wrappedMapGetters(root.$store, 'user', ['id']).id,
    hasPageAccessRole: true,
    hasReportGtManagerRole: computed(() => root.$store.getters['user/hasReportGtManagerRole']),
    searchParams: {
      name: null,
      timeSpan: null,
      includeDisabled: false,
      date: null,
    },
    list: [],
    hasList: computed(() => state.list.length > 0),
    dataLoadState: { availableTotal: 0, loadedSlices: 0 },
    isSortable: true,
    userViewSetting: null,
  });
  return state;
}

export default defineComponent({
  components: {
    ReportSearchPanel,
    SortButton,
    SortContext: useSortContext<Report>(),
    PaginationContainer: usePaginationContainer<Report>(),
  },
  setup() {
    const root = getCurrentInstance()!.proxy;
    setPageName(root, 'レポート');
    const state = setupState(root);

    const sortSpecs = ref<SortSpec[]>([]);
    function getDefaultSortSpecs(): SortSpec[] {
      return [{ key: 'name' }, { key: 'timeSpan', compareFunc: compareFuncTimeSpan }];
    }
    function resetToDefaultSortOrder(): void {
      sortSpecs.value = getDefaultSortSpecs();
    }
    resetToDefaultSortOrder();

    function getApiOrder(): Array<OrderElement<'name' | 'time_span'>> {
      return [
        {
          key: 'name',
          direction: 'asc',
        },
        {
          key: 'time_span',
          direction: 'asc',
        },
      ];
    }

    async function loadReports(): Promise<void> {
      try {
        state.dataLoadState.loadedSlices = 1;
        const response = await reportApi.index({
          name: state.searchParams.name,
          time_span: state.searchParams.timeSpan,
          include_disabled: state.searchParams.includeDisabled,
          page: state.dataLoadState.loadedSlices,
          order: getApiOrder(),
        });
        state.list = response.result;
        state.dataLoadState.availableTotal = response.pagination.total;
        state.isSortable = true;
        // 追加ロードが必要な場合はソート不可としソート条件をデフォルトに変更
        if (state.dataLoadState.availableTotal > response.pagination.limitValue) {
          state.isSortable = false;
          resetToDefaultSortOrder();
        }
      } catch (err: any) {
        const errStatus = err.response.status;
        const errRes = err.response.data || {};
        if (errStatus === 403) {
          state.hasPageAccessRole = false;
        } else if (errStatus === 400 && errRes.reason === ERROR_REASON_INVALID_PARAMS) {
          notifyError1(root, errRes.message, { err });
        } else {
          notifyError1(root, errRes, { err });
        }
      }
    }

    async function loadReportsNextSlice(): Promise<void> {
      try {
        state.dataLoadState.loadedSlices += 1;
        const response = await reportApi.index({
          name: state.searchParams.name,
          time_span: state.searchParams.timeSpan,
          include_disabled: state.searchParams.includeDisabled,
          page: state.dataLoadState.loadedSlices,
          order: getApiOrder(),
        });
        state.list = [...state.list, ...response.result];
        state.dataLoadState.availableTotal = response.pagination.total;
      } catch (err: any) {
        const errStatus = err.response.status;
        const errRes = err.response.data || {};
        if (errStatus === 403) {
          state.hasPageAccessRole = false;
        } else if (errStatus === 400 && errRes.reason === ERROR_REASON_INVALID_PARAMS) {
          notifyError1(root, errRes.message, { err });
        } else {
          notifyError1(root, errRes, { err });
        }
      }
    }

    async function onSearch(params: ReportSearchState) {
      state.searchParams = params;
      await loadReports();
    }

    async function openReportValueDetail(reportId: number, evt: MouseEvent | KeyboardEvent): Promise<void> {
      await routerShowNextPage(root, evt, {
        name: 'ReportValuesDetail',
        params: {
          reportId: reportId.toString(),
          // 以下のパラメータは廃止予定、URLにクエリパラメータで設定するように変更するとともに
          // 将来的に戻るボタンはhistory.back()相当に変更することでパラメータは不要になる
          name: state.searchParams.name || '',
          time_span: state.searchParams.timeSpan || '',
          include_disabled: state.searchParams.includeDisabled ? '1' : '0',
        },
      });
    }

    async function openReportsNew() {
      await root.$router.push({
        name: 'SettingsReports',
        query: { fromRoute: 'ReportValues' },
      });
    }

    // 初期設定パラメータ
    function setInitSearchParams(): void {
      let name: string | null = null;
      let timeSpan: TimeSpan | null = null;
      let includeDisabled: boolean = false;

      if (root.$route.params.date) {
        root.$route.params.date = '';
      }
      if (root.$route.params.time_span) {
        timeSpan = root.$route.params.time_span as TimeSpan;
        root.$route.params.time_span = '';
      }
      if (root.$route.params.name) {
        name = root.$route.params.name;
        root.$route.params.name = '';
      }
      if (root.$route.params.include_disabled) {
        includeDisabled = root.$route.params.include_disabled === '1';
        root.$route.params.include_disabled = '';
      }
      state.searchParams = {
        name,
        timeSpan,
        includeDisabled,
      };
    }

    onMounted(async () => {
      setInitSearchParams();

      await Promise.all([
        ensureUserRefreshAndMasters(root), // ログインユーザー情報をAPIで再取得
        loadReports(),
        loadUserViewSetting(),
      ]);
    });

    async function updateUserViewSetting(reportId: number | null): Promise<void> {
      if (!state.userViewSetting) {
        return;
      }

      try {
        await userViewSettingApi.upsert({
          ...state.userViewSetting,
          homeReportId: reportId,
        });
      } catch (err: any) {
        notifyError1(root, 'ホームレポートの設定に失敗しました。', { err });
      }

      await loadUserViewSetting();
    }

    async function clearUserViewSetting(): Promise<void> {
      updateUserViewSetting(null);
    }

    const loadUserViewSetting = async (): Promise<void> => {
      try {
        state.userViewSetting = await userViewSettingApi.show();
      } catch (err: any) {
        notifyError1(root, 'ホームレポートの読み込みに失敗しました。', { err });
      }
    };

    const gatedFuncGenerator = getGatedFuncGenerator();
    return {
      state,
      openReportValueDetail: gatedFuncGenerator.makeAsyncFuncGated(openReportValueDetail),
      sortSpecs,
      openReportsNew,
      onSearch,
      timeSpanToLocalWord,
      updateUserViewSetting,
      clearUserViewSetting,
      loadReportsNextSlice,
      ITEM_PER_PAGE,
    };
  },
});
