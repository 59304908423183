
import { vvGetError, vvGetErrorObject, vvHasError, vvValidate } from 'src/util/vee_validate';
import { computed, defineComponent, getCurrentInstance, onMounted, PropType, reactive, watch, nextTick } from 'vue';
import {
  ERROR_GROUP_SYSTEM,
  ERROR_GROUP_USER,
  ERROR_REASON_INVALID,
  ERROR_REASON_ACCESS_GROUP_NOT_FOUND,
  ERROR_REASON_LOGISCOPE_WORKPLACE_NOT_FOUND,
  ERROR_REASON_METRICS_NOT_FOUND,
  SYSTEM_MAX_DECIMAL_PLACES,
} from 'src/consts';
import { OPERATORS } from 'src/util/operator';
import { MetricsAccessGroup } from 'src/models/new/AccessGroup/metricsAccessGroup';
import metricsApi from 'src/apis/masters/metrics';
import workplaceApi from 'src/apis/masters/workplace';
import SelectAccessGroupForm from 'src/components/NewSelectItemForm/SelectAccessGroupForm.vue';
import SelectSingleMetricsOnMetricsForm from 'src/views/Dashboard/Settings/Metrics/components/shared/SelectSingleMetricsOnMetricsForm.vue';
import MaxChainNotification from 'src/views/Dashboard/Settings/Metrics/components/shared/MaxChainNotification.vue';
import { isRecordIdValid, validRecordIdOrNull } from 'src/util/recordId';
import { requestTimeout } from 'src/util/request_animation_frame';
import { omitString } from 'src/util/text_decorator';
import {
  CALCULATED_METRICS_MAX_OPERAND_VALUE,
  CALCULATED_METRICS_MIN_OPERAND_VALUE,
  CalculatedMetrics,
} from 'src/models/new/Metrics/calculatedMetrics';
import { Metrics, metricsToTypeLocalWord, METRICS_ACCESS_GROUP_MAX_COUNT } from 'src/models/new/metrics';
import { Workplace } from 'src/models/new/workplace';
import { TIME_SPAN_LOCAL_WORDS, TimeSpan, timeSpanToLocalWord } from 'src/business/timeSpan';
import InputError from 'src/components/InputError.vue';
import MetricsInputErrors from 'src/components/MetricsInputErrors.vue';
import { useMetricsAccessGroupsOfMetrics } from 'src/views/Dashboard/Settings/Metrics/composables/useMetricsAccessGroupsOfMetrics';
import { useSimpleEvent } from 'src/composables/useSimpleEvent';
import { useScalingOptions } from 'src/composables/options/useScalingOptions';

const TIME_SPAN_OPTIONS = Object.keys(TIME_SPAN_LOCAL_WORDS).map((el) => {
  return {
    value: el as TimeSpan,
    label: TIME_SPAN_LOCAL_WORDS[el as TimeSpan],
  };
});

type OperandNumber = 1 | 2 | 3;

const isOperandNumber = (value: number): value is OperandNumber => {
  return [1, 2, 3].includes(value);
};

interface State {
  // 各種マスタデータのロード完了を示すフラグ
  isLoaded: boolean;
  // リクエスト送信処理中であることを表すフラグ
  isRequesting: boolean;
  showMetricsAccessGroupSelectForm: boolean;
  showTargetMetricsSelectForm: boolean;
  showOperand3: boolean;
  validations: Record<string, Object>;
  hasError: boolean;
  metrics: CalculatedMetrics;
  metricsIsNew: boolean;
  metricsTypeLocalWord: string;
  editingOperandNumber: OperandNumber | null;
  userSelectMetrics: Metrics | null;
  // 新モデルにはメトリクスの下にメトリクスは持たせない（論理上、参照回数に応じてオブジェクトが肥大化する為）
  // 表示のためにメトリクスの実体を必要とするとき、idから値をロードしてoperandMetricsMapに格納する
  operandMetricsMap: Record<OperandNumber, Metrics | null>;
  userSelectMetricsAccessGroups: MetricsAccessGroup[];
  // 時間があればworkplaceも新モデルに置き換える
  workplaceOptions: Workplace[];
  // プロパティとv-modelの接続を調整する
  validWorkplaceIdOrNull: number | null;
  // 計算メトリクス直接入力のチェックボックスの状態
  isOperand1DirectInput: boolean;
  isOperand2DirectInput: boolean;
  isOperand3DirectInput: boolean;
  // プロパティとv-modelの接続を調整する
  validOperandMetricsId1OrNull: number | null;
  validOperandMetricsId2OrNull: number | null;
  validOperandMetricsId3OrNull: number | null;
}

const CONFIRM_ACCESS_GROUP_EVENT_KEY = 'confirmSelectAccessGroupForm';
const CONFIRM_METRICS_EVENT_KEY = 'confirmSelectMetricsForm';

export default defineComponent({
  components: {
    SelectAccessGroupForm,
    SelectSingleMetricsOnMetricsForm,
    MaxChainNotification,
    InputError,
    MetricsInputErrors,
  },
  props: {
    value: {
      type: Object as PropType<CalculatedMetrics>,
      required: true,
    },
  },
  emits: ['input', 'updated', 'close', 'shouldReport'],
  setup(props, { emit }) {
    const root = getCurrentInstance()!.proxy;
    const state: State = reactive({
      isLoaded: false,
      isRequesting: false,
      showMetricsAccessGroupSelectForm: false,
      showTargetMetricsSelectForm: false,
      showOperand3: computed((): boolean => {
        return state.metrics.operators.length > 1 && state.metrics.operators[1] !== null;
      }),
      validations: computed(() => {
        const operandValueRule = {
          decimal: SYSTEM_MAX_DECIMAL_PLACES,
          min_value: CALCULATED_METRICS_MIN_OPERAND_VALUE,
          max_value: CALCULATED_METRICS_MAX_OPERAND_VALUE,
        };
        const validations = {
          name: { required: true, max: 50 },
          workplaceId: { required: true },
          unit: { required: false, max: 10 },
          decimalPlaces: { required: true, min_value: 0, max_value: 2, numeric: true },
          operandMetricsId1: state.isOperand1DirectInput ? {} : { required: true, min_value: 0 },
          operandMetricsConstant1: state.isOperand1DirectInput ? { ...operandValueRule, required: true } : {},
          operator1: { required: true },
          operandMetricsId2: state.isOperand2DirectInput ? {} : { required: true, min_value: 0 },
          operandMetricsConstant2: state.isOperand2DirectInput ? { ...operandValueRule, required: true } : {},
        };
        if (state.showOperand3) {
          Object.assign(validations, {
            operandMetricsId3: state.isOperand3DirectInput ? {} : { required: true, min_value: 0 },
            operandMetricsConstant3: state.isOperand3DirectInput ? { ...operandValueRule, required: true } : {},
          });
        }
        return validations;
      }),
      hasError: computed(() => vvHasError(root)),
      metrics: computed({
        get(): CalculatedMetrics {
          return props.value;
        },
        set(next: CalculatedMetrics) {
          emit('input', structuredClone(next));
        },
      }),
      metricsIsNew: computed(() => !isRecordIdValid(state.metrics.id)),
      metricsTypeLocalWord: computed(() => metricsToTypeLocalWord(state.metrics)),
      editingOperandNumber: null,
      userSelectMetrics: computed({
        get(): Metrics | null {
          if (state.editingOperandNumber === null) {
            return null;
          }
          return state.operandMetricsMap[state.editingOperandNumber];
        },
        set(metrics: Metrics | null) {
          if (state.editingOperandNumber === null) {
            return;
          }
          const metricsId = metrics?.id ?? null;
          state.metrics.operands[state.editingOperandNumber - 1].metricsId = metricsId;
          state.metrics.operands[state.editingOperandNumber - 1].constant = null;
          state.operandMetricsMap[state.editingOperandNumber] = metrics;
        },
      }),
      operandMetricsMap: { 1: null, 2: null, 3: null },
      userSelectMetricsAccessGroups: computed({
        get(): MetricsAccessGroup[] {
          return metricsAccessGroupsOnMetricsWithoutAdmin.value;
        },
        set(value: MetricsAccessGroup[]) {
          metricsAccessGroupsOnMetrics.value = metricsAccessGroupOnMetricsWithAdmin.value
            ? [metricsAccessGroupOnMetricsWithAdmin.value, ...value]
            : value;
        },
      }),
      validWorkplaceIdOrNull: computed({
        get() {
          return validRecordIdOrNull(state.metrics.workplaceId);
        },
        set(value) {
          state.metrics.workplaceId = value || 0;
        },
      }),
      workplaceOptions: [],
      isOperand1DirectInput: false,
      isOperand2DirectInput: false,
      isOperand3DirectInput: false,
      validOperandMetricsId1OrNull: computed(() => state.metrics.operands[0].metricsId),
      validOperandMetricsId2OrNull: computed(() => state.metrics.operands[1].metricsId),
      validOperandMetricsId3OrNull: computed(() => state.metrics.operands[2].metricsId),
    });

    const {
      metricsAccessGroupsOnMetrics,
      metricsAccessGroupsOnMetricsWithoutAdmin,
      metricsAccessGroupOnMetricsWithAdmin,
      loadMetricsAccessGroupsOnMetrics,
    } = useMetricsAccessGroupsOfMetrics();

    const { options: scalingOptions } = useScalingOptions();

    const getError = (fieldName: string): string | null => vvGetError(root, fieldName);
    const getErrorObject = (fieldName: string): object | null => vvGetErrorObject(root, fieldName);

    const setWorkplaceOptions = async (): Promise<void> => {
      state.workplaceOptions = await workplaceApi.index();

      // 設定されてない場合は一番上のセンターを初期値とする
      if (state.workplaceOptions.length > 0 && !state.validWorkplaceIdOrNull) {
        state.validWorkplaceIdOrNull = state.workplaceOptions[0].id;
      }
    };

    const setOperandMetricsMap = async (): Promise<void> => {
      state.operandMetricsMap[1] = state.metrics.operands[0].metricsId
        ? await metricsApi.show(state.metrics.operands[0].metricsId)
        : null;
      state.operandMetricsMap[2] = state.metrics.operands[1].metricsId
        ? await metricsApi.show(state.metrics.operands[1].metricsId)
        : null;
      state.operandMetricsMap[3] = state.metrics.operands[2].metricsId
        ? await metricsApi.show(state.metrics.operands[2].metricsId)
        : null;
    };

    const hasOperandId = (): boolean => {
      return state.metrics.operands.some((el) => !!el.metricsId);
    };

    const goToAccessGroupSelectForm = (): void => {
      state.showMetricsAccessGroupSelectForm = true;
    };
    const goToTargetMetricsSelectForm = (): void => {
      state.showTargetMetricsSelectForm = true;
    };
    const setEditingOperandNumber = (operandNumber: OperandNumber): void => {
      state.editingOperandNumber = operandNumber;
    };
    const editOperandMetricsAt = (number: number): void => {
      if (!isOperandNumber(number)) {
        return;
      }
      setEditingOperandNumber(number);
      goToTargetMetricsSelectForm();
    };
    const backToMainForm = (): void => {
      state.showMetricsAccessGroupSelectForm = false;
      state.showTargetMetricsSelectForm = false;
      state.editingOperandNumber = null;
    };

    const setSelectedAccessGroups = (metricsAccessGroups: MetricsAccessGroup[]): void => {
      state.userSelectMetricsAccessGroups = metricsAccessGroups;
      backToMainForm();
    };

    const { triggerer: confirmAccessGroupEventTriggerer } = useSimpleEvent(CONFIRM_ACCESS_GROUP_EVENT_KEY);

    const triggerAccessGroupConfirmEvent = () => {
      confirmAccessGroupEventTriggerer.trigger();
    };

    const setSelectedMetrics = (metrics: Metrics | null): void => {
      state.userSelectMetrics = metrics;
      backToMainForm();
    };

    const { triggerer: confirmMetricsEventTriggerer } = useSimpleEvent(CONFIRM_METRICS_EVENT_KEY);

    const triggerMetricsConfirmEvent = () => {
      confirmMetricsEventTriggerer.trigger();
    };

    // メトリクス3が非表示になったとき、値をクリアする
    watch(
      () => state.showOperand3,
      () => {
        if (state.showOperand3) {
          return;
        }

        // vee-validateで"Validating a non-existent field"エラーを抑制するため
        // state.showOperand3の変更がDOMに反映されるのを待ち、その後operands[2].metricsIdに変更を与える
        nextTick(() => {
          state.metrics.operands[2].metricsId = null;
        });
        state.metrics.operands[2].constant = null;
        state.isOperand3DirectInput = false;
        state.operandMetricsMap[3] = null;
      },
    );

    const close = (): void => emit('close');

    const save = async (): Promise<void> => {
      // 二重処理防止
      if (state.isRequesting) {
        return;
      }
      state.isRequesting = true;
      requestTimeout(() => {
        state.isRequesting = false;
      }, 300);

      if (!(await vvValidate(root))) {
        return;
      }

      // メトリクスIDと直接入力の値はどちらか一方のみ
      const directInputs = [state.isOperand1DirectInput, state.isOperand2DirectInput, state.isOperand3DirectInput];
      const operandInputs = state.metrics.operands.map((operand, index) => {
        return {
          metricsId: directInputs[index] ? null : operand.metricsId,
          constant: directInputs[index] ? operand.constant : null,
        };
      });

      const metrics: CalculatedMetrics = {
        ...state.metrics,
        operands: operandInputs,
        accessGroupIds: state.userSelectMetricsAccessGroups.map((metricsAccessGroup) => metricsAccessGroup.id),
      };
      const operationWord = state.metricsIsNew ? '作成' : '更新';
      try {
        if (state.metricsIsNew) {
          await metricsApi.create(metrics);
        } else {
          await metricsApi.update(metrics);
        }
        emit('updated', `${state.metrics.name} を${operationWord}しました。`);
      } catch (err: any) {
        const errId = state.metricsIsNew ? 'ERR00001' : 'ERR00002';
        const errStatus = err.response.status;
        const errRes = err.response.data || {};
        if ([403, 404].includes(errStatus)) {
          const msg = 'アクセスする権限がありません。管理者にお問合せください。';
          emit('shouldReport', ERROR_GROUP_USER, msg, err);
        } else if (errStatus === 400 && errRes.reason === ERROR_REASON_METRICS_NOT_FOUND) {
          const msg = '選択したメトリクスは存在しません。ページを更新し、再度お試しください';
          emit('shouldReport', ERROR_GROUP_USER, msg, err);
        } else if (errStatus === 400 && errRes.reason === ERROR_REASON_ACCESS_GROUP_NOT_FOUND) {
          const msg = '選択したメトリクスグループは存在しません。ページを更新し、再度お試しください';
          emit('shouldReport', ERROR_GROUP_USER, msg, err);
        } else if (errStatus === 400 && errRes.reason === ERROR_REASON_LOGISCOPE_WORKPLACE_NOT_FOUND) {
          const msg = '選択した集計用センターは存在しません。ページを更新し、再度お試しください';
          emit('shouldReport', ERROR_GROUP_USER, msg, err);
        } else if ((errStatus === 400 && errRes.reason === ERROR_REASON_INVALID) || errStatus === 409) {
          emit('shouldReport', ERROR_GROUP_USER, errRes.message, err);
        } else {
          const msg = `メトリクスの${operationWord}に失敗しました。管理者に連絡してください。`;
          // TODO: notifyのオプションと統合して、引数が長くならないように、ページ間で異ならないように統一する
          emit('shouldReport', ERROR_GROUP_SYSTEM, msg, err, errId);
        }
      }
    };

    onMounted(async () => {
      // Vue 2x 暫定措置 3x系の場合はonUnmountedでフラグを戻す
      // Vue 2x ではonUnmountedがdestroyedに対するフックのエイリアスであるためonMountedの先頭に記述している
      state.isLoaded = false;
      state.showMetricsAccessGroupSelectForm = false;

      await Promise.all([
        loadMetricsAccessGroupsOnMetrics(state.metrics),
        setWorkplaceOptions(),
        setOperandMetricsMap(),
      ]);

      // TODO: 経過観察: 元の実装では変更をwatchしていた 恐らくは変更されるタイミングではリマウントするので、watchしなくてよいはず
      state.isOperand1DirectInput = state.metrics.operands[0].constant !== null;
      state.isOperand2DirectInput = state.metrics.operands[1].constant !== null;
      state.isOperand3DirectInput = state.metrics.operands[2].constant !== null;

      state.isLoaded = true;
    });

    return {
      props,
      state,
      TIME_SPAN_OPTIONS,
      OPERATORS,
      METRICS_ACCESS_GROUP_MAX_COUNT,
      getError,
      getErrorObject,
      goToAccessGroupSelectForm,
      goToTargetMetricsSelectForm,
      editOperandMetricsAt,
      backToMainForm,
      setSelectedAccessGroups,
      CONFIRM_ACCESS_GROUP_EVENT_KEY,
      triggerAccessGroupConfirmEvent,
      setSelectedMetrics,
      CONFIRM_METRICS_EVENT_KEY,
      triggerMetricsConfirmEvent,
      omitString,
      save,
      close,
      hasOperandId,
      metricsToTypeLocalWord,
      timeSpanToLocalWord,
      scalingOptions,
    };
  },
});
