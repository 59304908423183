
import { PropType, defineComponent } from 'vue'

export default defineComponent({
  props: {
    text: {
      type: String,
      required: true,
    },
    onClick: {
      type: Function as PropType<() => void | undefined>,
      required: false,
    },
    iconClasses: {
      type: String as PropType<string | undefined>,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    return {
      props,
    }
  },
})
