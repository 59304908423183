import {
  Radio,
  Checkbox,
  FgInput,
  Button,
  Dropdown,
  Card,
  Sidebar,
  Modal,
  Pagination,
  Switch,
  DatePicker,
} from 'src/components/UIComponents';
import { Select, Option } from 'element-ui';
import { PluginObject } from 'vue';

/**
 * You can register global components here and use them as a plugin in your main Vue instance
 * Ideally, only small components that are re-used many times across your application should be registered here.
 * For plugins and bigger components local registration is preferable because it will allow you to do code splitting easier :)
 */

const GlobalComponents: PluginObject<never> = {
  install(Vue) {
    Vue.component(Button.name, Button);
    Vue.component(FgInput.name, FgInput);
    Vue.component(Dropdown.name, Dropdown);
    Vue.component(Checkbox.name, Checkbox);
    Vue.component(Radio.name, Radio);
    Vue.component(Card.name, Card);
    Vue.component(Sidebar.name, Sidebar);
    Vue.component(Modal.name, Modal);
    Vue.component(Pagination.name, Pagination);
    Vue.component(Switch.name, Switch);
    Vue.component(Option.name, Option);
    Vue.component(Select.name, Select);
    Vue.component('DatePicker', DatePicker);
  },
};

export default GlobalComponents;
