
/**
 * DataTable
 * 汎用テーブル表示コンポーネント
 * テーブルの行(row)および列(column)は、セルの内容がヘッダーかデータかに関わらずそれぞれ1からの整数を取る
 */
import { HeaderStructure } from 'src/util/dataHeader'
import { PropType, computed, defineComponent, reactive } from 'vue'
import { CSSProperties } from 'vue/types/jsx'
import MetricsTypeRoundPoint from 'src/components/MetricsTypeRoundPoint.vue'
import { Metrics } from 'src/models/new/metrics'

type TableCell = {
  value: string | null
  row: number
  column: number
  colspan: number
  rowspan: number
  isHeader: boolean
  cellStyle: CSSProperties
  iconClass: string | null
  iconStyle: CSSProperties
  unit: string | null
  isClickable: boolean
  isEditable: boolean
  isBreakdownAccessible: boolean
}

export type DataTableMatrixCell = {
  value: string | null
  unit: string | null
  row: number
  column: number
  cellStyle: CSSProperties
  iconClass: string | null
  iconStyle: CSSProperties
  isClickable: boolean
  isEditable: boolean
  isBreakdownAccessible: boolean
}

type State = {
  tableCells: TableCell[][],
}

export default defineComponent({
  components: {
    MetricsTypeRoundPoint,
  },
  props: {
    head: {
      type: Object as PropType<HeaderStructure>,
      required: true,
    },
    side: {
      type: Object as PropType<HeaderStructure>,
      required: true,
    },
    contents: {
      type: Array as PropType<DataTableMatrixCell[]>,
      required: true,
    },
    findMetrics: {
      type: Function as PropType<(row: number, column: number) => Metrics | null>,
      default: () => {},
    },
    onMetricsEditButtonClicked: {
      type: Function as PropType<(row: number, column: number) => void>,
      default: () => {},
    },
    onBreakdownButtonClicked: {
      type: Function as PropType<(row: number, column: number) => void>,
      default: () => {},
    },
  },
  setup(props) {
    const state: State = reactive({
      tableCells: computed(() => {
        const head = props.head
        const side = props.side
        const headDepth = head.depth
        const headBreadth = head.breadth
        const sideDepth = side.depth
        const sideBreadth = side.breadth
        const contents = props.contents

        if (contents.reduce((maxRow, el) => el.row > maxRow ? el.row : maxRow, 0) > sideBreadth ||
        contents.reduce((maxColumn, el) => el.column > maxColumn ? el.column : maxColumn, 0) > headBreadth
        ) {
          console.error('data size is not matched to header.')
          return [[]]
        }

        const tableCells = [[]] as TableCell[][]

        // テーブル左上の空領域を作成
        tableCells[0].push({
          value: null,
          row: 1,
          column: 1,
          colspan: sideDepth,
          rowspan: headDepth,
          isHeader: true,
          cellStyle: {},
          iconClass: null,
          iconStyle: {},
          unit: null,
          isClickable: false,
          isEditable: false,
          isBreakdownAccessible: false,
        })

        // 上部ヘッダー領域を作成
        // spanが0のセルは他のセルに含まれている領域であるためスキップする
        for (let tableRow = 1; tableRow <= headDepth; tableRow++) {
          if (!tableCells[tableRow - 1]) tableCells[tableRow - 1] = []
          for (let tableColumn = sideDepth + 1; tableColumn <= sideDepth + headBreadth; tableColumn++) {
            const cell = head.data.find(el => el.level === tableRow && el.position === tableColumn - sideDepth)
            if (!cell) throw new Error('header data is not completed.')
            if (cell.span < 1) continue
            tableCells[tableRow - 1].push({
              value: cell.value,
              row: tableRow,
              column: tableColumn,
              colspan: cell.span,
              rowspan: 1,
              isHeader: true,
              cellStyle: {},
              iconClass: null,
              iconStyle: {},
              unit: null,
              isClickable: false,
              isEditable: false,
              isBreakdownAccessible: false,
            })
          }
        }

        // 左部ヘッダー領域を作成
        // spanが0のセルは他のセルに含まれている領域であるためスキップする
        for (let tableRow = headDepth + 1; tableRow <= headDepth + sideBreadth; tableRow++) {
          if (!tableCells[tableRow - 1]) tableCells[tableRow - 1] = []
          for (let tableColumn = 1; tableColumn <= sideDepth; tableColumn++) {
            const cell = side.data.find(el => el.position === tableRow - headDepth && el.level === tableColumn)
            if (!cell) throw new Error('header data is not completed.')
            if (cell.span < 1) continue
            tableCells[tableRow - 1].push({
              value: cell.value,
              row: tableRow,
              column: tableColumn,
              colspan: 1,
              rowspan: cell.span,
              isHeader: true,
              cellStyle: {},
              iconClass: null,
              iconStyle: {},
              unit: null,
              isClickable: false,
              isEditable: false,
              isBreakdownAccessible: false,
            })
          }
        }

        // データ領域を作成
        for (let tableRow = headDepth + 1; tableRow <= headDepth + sideBreadth; tableRow++) {
          // 既に左部ヘッダー領域を作成した際に行ごと配列は作成されているため、空配列の追加は行わない
          for (let tableColumn = sideDepth + 1; tableColumn <= sideDepth + headBreadth; tableColumn++) {
            const cell = contents.find(el => el.row === tableRow - headDepth && el.column === tableColumn - sideDepth) || null
            // 表のセルにメトリクスが設定されていない場合はcellがnullになり、ダミーセルとしての値を代入する
            tableCells[tableRow - 1].push({
              value: cell?.value ?? null,
              row: tableRow,
              column: tableColumn,
              colspan: 1,
              rowspan: 1,
              isHeader: false,
              cellStyle: cell?.cellStyle ?? {},
              iconClass: cell?.iconClass || null,
              iconStyle: cell?.iconStyle ?? {},
              unit: cell?.unit || null,
              isClickable: cell?.isClickable ?? false,
              isEditable: cell?.isEditable ?? false,
              isBreakdownAccessible: cell?.isBreakdownAccessible ?? false,
            })
          }
        }

        return tableCells
      }),
    })

    return { props, state }
  },
})
