
import { computed, defineComponent, onMounted, PropType, reactive } from 'vue'
import { ERROR_GROUP_SYSTEM, ERROR_GROUP_USER } from 'src/consts'
import componentApi from 'src/apis/component'
import { CommentComponent } from 'src/models/new/Component/commentComponent'
import { Report } from 'src/models/new/report'

interface State {
  isReady: boolean
  commentComponent: CommentComponent
  placeholder: string
  value: string
}

export default defineComponent({
  props: {
    report: {
      type: Object as PropType<Report>,
      required: true,
    },
    commentComponent: {
      type: Object as PropType<CommentComponent>,
      required: true,
    },
  },
  emits: ['input', 'updated', 'close', 'shouldReport'],
  setup(props, { emit }) {
    const state: State = reactive({
      isReady: false,
      commentComponent: computed({
        get() {
          return props.commentComponent
        },
        set(next) {
          emit('input', structuredClone(next))
        },
      }),
      placeholder: computed(() => props.commentComponent.placeholder ?? ''),
      value: computed({
        get() {
          return props.commentComponent.value ?? ''
        },
        set(next) {
          state.commentComponent.value = next ?? null
        },
      }),
    })
    const close = (): void => { emit('close') }
    const update = async(): Promise<void> => {
      try {
        await componentApi.updateComment(props.report, state.commentComponent)
        emit('updated', 'コメントを更新しました。')
      } catch (err: any) {
        const errStatus = err.response.status
        if ([403, 404].includes(errStatus)) {
          const msg = 'コメントの変更権限がありません。管理者にお問合せください。'
          emit('shouldReport', ERROR_GROUP_USER, msg, err)
        } else {
          const msg = 'コメントの更新に失敗しました。管理者に連絡してください。'
          emit('shouldReport', ERROR_GROUP_SYSTEM, msg, err)
        }
      }
    }

    onMounted(async() => {
      // Vue 2x 暫定措置 3x系の場合はonUnmountedでフラグを戻す
      // Vue 2x ではonUnmountedがdestroyedに対するフックのエイリアスであるためonMountedの先頭に記述している
      state.isReady = false
      state.isReady = true
    })

    return { state, props, close, update }
  },

})
