import { Comparator } from 'src/util/comparator'
import { LegacyMetricsResponse, convertFromMetricsResponseData } from 'src/models/api/Metrics/metricsResponseData'
import {
  CONDITIONAL_STATEMENT_COMPARABLE_REFERENCE,
  CONDITIONAL_STATEMENT_COMPARABLE_SELF,
  ConditionalStatement,
} from 'src/models/new/conditionalStatement'
import { Metrics } from 'src/models/new/metrics'
import { LegacyMetricsValueResponseData, SearchedDateMap } from '../componentResponseData'
import { formatDate, parseDatetime } from 'src/util/datetime'
import { SYSTEM_DATE_FORMAT } from 'src/util/Datetime/format'
import {
  TimeSpan,
  isTimeSpanDaily,
  isTimeSpanMonthly,
  isTimeSpanWeekly,
  isTimeSpanYearly,
} from 'src/business/timeSpan'

type ValueResponseDataMap = Record<string, LegacyMetricsValueResponseData>

export type ConditionalStatementData = {
  id: number
  row_number: number
  column_number: number
  base_type: string
  base_metrics?: LegacyMetricsResponse & {
    values_idx_dt?: ValueResponseDataMap
  } | null
  // 数値が文字列で返ってくる可能性がある
  base_value: string | number | null
  comparator: Comparator
  disp_order: number
  threshold_type: 'main_metrics' | 'sub_metrics' | 'other_metrics' | 'const'
  threshold_metrics?: LegacyMetricsResponse & {
    values_idx_dt?: ValueResponseDataMap
  } | null
  // 数値が文字列で返ってくる可能性がある
  threshold_value: string | number | null
  style_map: {
    color: string
    font_weight: string
    format_type: string
    icon: string
    version: number
  }
}

// FIXME: 右記とほぼ内容が同じ　src/assets/src/models/api/Report/Component/ConditionalStatement/conditionalStatementResponseData.ts
// レスポンス型が微妙に異なるため統一できないが、レポートの取得を分割する際に考慮して統合する
const computeConditionBase = (
  data: ConditionalStatementData
): number | Metrics | typeof CONDITIONAL_STATEMENT_COMPARABLE_SELF | typeof CONDITIONAL_STATEMENT_COMPARABLE_REFERENCE => {
  switch (data.base_type) {
    case 'main_metrics':
      return CONDITIONAL_STATEMENT_COMPARABLE_SELF
    case 'sub_metrics':
      return CONDITIONAL_STATEMENT_COMPARABLE_REFERENCE
    case 'other_metrics': {
      // base_metricsはbaseのタイプがother_metricsであってもキーが存在しないことがありうる
      // キーが存在しないのは例えば指定していたメトリクスが削除されたような場合である
      // そのような場合は定数0がセットされていたものとして扱う
      // キーが存在するが値が空の状態は想定していないが、その場合も定数0がセットされていたものとして扱う
      return data.base_metrics ? convertFromMetricsResponseData(data.base_metrics!, {}) : 0
    }
    case 'const':
      return Number(data.base_value!)
    default:
      throw Error(`No such base type: ${data.base_type}`)
  }
}
const computeConditionThreshold = (
  data: ConditionalStatementData
): number | Metrics | typeof CONDITIONAL_STATEMENT_COMPARABLE_REFERENCE | null => {
  if (data.comparator === 'is_null') return null

  switch (data.threshold_type) {
    case 'sub_metrics':
      return CONDITIONAL_STATEMENT_COMPARABLE_REFERENCE
    case 'other_metrics': {
      // threshold_metricsはthresholdのタイプがother_metricsであってもキーが存在しないことがありうる
      // キーが存在しないのは例えば指定していたメトリクスが削除されたような場合である
      // そのような場合は定数0がセットされていたものとして扱う
      // キーが存在するが値が空の状態は想定していないが、その場合も定数0がセットされていたものとして扱う
      return data.threshold_metrics ? convertFromMetricsResponseData(data.threshold_metrics!, {}) : 0
    }
    case 'const':
      return Number(data.threshold_value!)
    default:
      throw Error(`No such threshold type: ${data.threshold_type}`)
  }
}

// バックエンドから届くtimeSpanはスネークケースで、フロントエンドではキャメルケースなので厳密には互換性がない
// 現状は1単語なので条件分岐するまでもないが、ロジックとしては変換すべき
const extractDates = (searchedDates: SearchedDateMap, timeSpan: TimeSpan): Date[] => {
  if (isTimeSpanDaily(timeSpan)) {
    return searchedDates.daily.map(dateString => parseDatetime(dateString, SYSTEM_DATE_FORMAT))
  } else if (isTimeSpanMonthly(timeSpan)) {
    return searchedDates.monthly.map(dateString => parseDatetime(dateString, SYSTEM_DATE_FORMAT))
  } else if (isTimeSpanWeekly(timeSpan)) {
    return searchedDates.weekly.map(dateString => parseDatetime(dateString, SYSTEM_DATE_FORMAT))
  } else if (isTimeSpanYearly(timeSpan)) {
    return searchedDates.yearly.map(dateString => parseDatetime(dateString, SYSTEM_DATE_FORMAT))
  }
  throw Error(`No such time span: ${timeSpan}`)
}

// searchDatesはコンポーネント取得時のレスポンスにて、閲覧用に取得した場合のみ存在する
// 編集用に取得した場合はnullを渡すか、デフォルト値を利用して引数を省略する
export const buildConditionalStatementProperties = (
  data: ConditionalStatementData,
  searchedDates: SearchedDateMap | null = null,
): ConditionalStatement<any> => {
  const settings = {}
  if (data.style_map.color) {
    Object.assign(settings, { color: data.style_map.color })
  }
  if (data.style_map.font_weight && data.style_map.format_type === 'text') {
    Object.assign(settings, { fontWeight: data.style_map.font_weight })
  }
  if (data.style_map.icon && data.style_map.format_type === 'icon') {
    Object.assign(settings, { icon: data.style_map.icon })
  }
  const decoration = {
    target: data.style_map.format_type,
    settings: settings,
  }

  const base = computeConditionBase(data)
  const threshold = computeConditionThreshold(data)

  const metricsList: Metrics[] = []

  if (typeof base !== 'string' && typeof base !== 'number') {
    const timeSpan = base.timeSpan
    const dates = searchedDates ? extractDates(searchedDates, timeSpan) : []
    dates.forEach(date => {
      const metrics = structuredClone(base)
      metrics.closingDate = date
      // レコードが存在しない場合、日付に対応するキーが存在しない
      const value = data.base_metrics!.values_idx_dt![formatDate(date, SYSTEM_DATE_FORMAT)]?.value ?? null
      metrics.value = value !== undefined && value !== null ? Number(value) : null
      metricsList.push(metrics)
    })
  }

  if (typeof threshold !== 'string' && typeof threshold !== 'number' && threshold !== null) {
    const timeSpan = threshold.timeSpan
    const dates = searchedDates ? extractDates(searchedDates, timeSpan) : []
    dates.forEach(date => {
      const metrics = structuredClone(threshold)
      metrics.closingDate = date
      // レコードが存在しない場合、日付に対応するキーが存在しない
      const value = data.threshold_metrics!.values_idx_dt![formatDate(date, SYSTEM_DATE_FORMAT)]?.value ?? null
      metrics.value = value !== undefined && value !== null ? Number(value) : null
      metricsList.push(metrics)
    })
  }

  return {
    id: data.id,
    base: computeConditionBase(data),
    threshold: computeConditionThreshold(data),
    comparator: data.comparator,
    decoration: decoration,
    // NOTE: 優先順位の高さと表示順（設定時の表示に関わる）は逆順になる可能性あり、要件によってこちらを変更する
    priority: data.disp_order,
    metricsList,
  }
}
