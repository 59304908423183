import { Metrics, MetricsType } from 'src/models/new/metrics'
import { Scaling } from 'src/business/scaling'
import { LogiSystemDataMetrics, LogiSystemDataMetricsAggregationFunction } from 'src/models/new/Metrics/BasicMetrics/logiSystemDataMetrics'
import { LogimeterDataMetrics, isMetricsLogimeterDataMetrics } from 'src/models/new/Metrics/BasicMetrics/LogiSystemDataMetrics/logimeterDataMetrics'
import { LogiboardDataMetrics, isMetricsLogiboardDataMetrics } from 'src/models/new/Metrics/BasicMetrics/LogiSystemDataMetrics/logiboardDataMetrics'
import { isTimeSpanMonthly, isTimeSpanWeekly, isTimeSpanYearly, TimeSpan } from 'src/business/timeSpan'
import { DirectInputMetrics, isMetricsDirectInputMetrics } from 'src/models/new/Metrics/BasicMetrics/directInputMetrics'
import { isMetricsSummaryMetrics, SummaryMetrics } from 'src/models/new/Metrics/summaryMetrics'
import { AggregateFunction } from 'src/business/aggregateFunction'
import {
  isReferenceMetricsOffsetPeriodMonth,
  isReferenceMetricsOffsetPeriodWeek,
  isReferenceMetricsOffsetPeriodYear,
  ReferenceMetrics,
  isMetricsReferenceMetrics,
  OffsetPeriodUnit,
  internal,
} from 'src/models/new/Metrics/referenceMetrics'
import { BundledMetrics, isMetricsBundledMetrics } from 'src/models/new/Metrics/bundledMetrics'
import { CalculatedMetrics, isMetricsCalculatedMetrics } from 'src/models/new/Metrics/calculatedMetrics'
import { DayOfWeek, dayOfWeekToNumber } from 'src/util/week'
import { Operator } from 'src/util/operator'

const DAY = internal.DAY
const WEEK = internal.WEEK
const MONTH = internal.MONTH
const YEAR = internal.YEAR
const DOW = internal.DOW

const BASIC_METRICS = 'basic_metrics'
const SUMMARY_METRICS = 'summary_metrics'
const CALC_METRICS = 'calc_metrics'
const REFERENCE_METRICS = 'reference_metrics'
const CROSS_BORDER_METRICS = 'cross_border_metrics'
type InternalMetricsType =
  | typeof BASIC_METRICS
  | typeof SUMMARY_METRICS
  | typeof CALC_METRICS
  | typeof REFERENCE_METRICS
  | typeof CROSS_BORDER_METRICS

type DirectInputBasicMetricsProperties = {
  data_source: 'direct_input'
  default_value: number | null
}
type LogiSystemDataBasicMetricsProperties = {
  data_source: 'logimeter' | 'logiboard'
  // ロジシステムデータでは使わない
  // default_value: number | null
  aggr_func: LogiSystemDataMetricsAggregationFunction
  target_budget_group_id: number
  data_source_key_id: number
  basic_metrics_parameters: {
    data_source_parameter_id: number
    value: string
  }[]
}
type SummaryMetricsProperties = {
  target_metrics_id: number
  closing_timing1: number
  closing_timing2: number | null
  aggr_func: AggregateFunction
  is_zero_included : boolean
  wday: Record<DayOfWeek, boolean>
}
type ReferenceMetricsProperties = {
  target_metrics_id: number
  offset_anchor_unit: Extract<OffsetPeriodUnit, typeof WEEK | typeof MONTH | typeof YEAR> | null
  offset_anchor_diff: number | null
  offset_unit1: OffsetPeriodUnit | typeof DOW | null
  offset_value1: number
  offset_unit2: Extract<OffsetPeriodUnit, typeof DAY> | null
  offset_value2: number | null
}
type BundledMetricsProperties = {
  aggr_func: AggregateFunction
  is_zero_included : boolean
  targets: {
    target_metrics_id: number
  }[]
}
type CalculatedMetricsProperties = {
  operand1_id: number | null
  operand1_value: number | null
  operator1: Operator | null
  operand2_id: number | null
  operand2_value: number | null
  operator2: Operator | null
  operand3_id: number | null
  operand3_value: number | null
}
export type ChildMetricsCreateUpdateRequest =
  | LogiSystemDataBasicMetricsProperties
  | DirectInputBasicMetricsProperties
  | SummaryMetricsProperties
  | ReferenceMetricsProperties
  | BundledMetricsProperties
  | CalculatedMetricsProperties

export type MetricsCreateRequestParameters = {
  logiscope_workplace_id: number
  metrics_type: InternalMetricsType
  front_metrics_type: MetricsType
  name: string
  time_span: TimeSpan
  unit_name: string | null
  decimal_places: number
  scaling: Scaling
  is_enabled: boolean
  metrics_access_groups: {
    id: number
  }[]
} & ChildMetricsCreateUpdateRequest

export const convertToMetricsCreateResponse = (metrics: Metrics): MetricsCreateRequestParameters => {
  const getInternalMetricsType = (metrics: Metrics): InternalMetricsType => {
    if (isMetricsDirectInputMetrics(metrics)) return 'basic_metrics'
    if (isMetricsLogimeterDataMetrics(metrics)) return 'basic_metrics'
    if (isMetricsLogiboardDataMetrics(metrics)) return 'basic_metrics'
    if (isMetricsCalculatedMetrics(metrics)) return 'calc_metrics'
    if (isMetricsBundledMetrics(metrics)) return 'cross_border_metrics'
    if (isMetricsSummaryMetrics(metrics)) return 'summary_metrics'
    if (isMetricsReferenceMetrics(metrics)) return 'reference_metrics'

    throw Error('Invalid metrics type.')
  }

  const getChildMetricsParams = (metrics: Metrics): ChildMetricsCreateUpdateRequest => {
    if (isMetricsDirectInputMetrics(metrics)) return buildChildFromDirectInputMetrics(metrics as DirectInputMetrics)
    if (isMetricsLogimeterDataMetrics(metrics)) return buildChildFromLogimeterDataMetrics(metrics as LogimeterDataMetrics)
    if (isMetricsLogiboardDataMetrics(metrics)) return buildChildFromLogiboardDataMetrics(metrics as LogiboardDataMetrics)
    if (isMetricsCalculatedMetrics(metrics)) return buildChildFromCalculatedMetrics(metrics as CalculatedMetrics)
    if (isMetricsBundledMetrics(metrics)) return buildChildFromBundledMetrics(metrics as BundledMetrics)
    if (isMetricsSummaryMetrics(metrics)) return buildChildFromSummaryMetrics(metrics as SummaryMetrics)
    if (isMetricsReferenceMetrics(metrics)) return buildChildFromReferenceMetrics(metrics as ReferenceMetrics)

    throw Error('Invalid metrics type.')
  }

  return {
    ...getChildMetricsParams(metrics),
    logiscope_workplace_id: metrics.workplaceId,
    metrics_type: getInternalMetricsType(metrics),
    front_metrics_type: metrics.metricsType,
    name: metrics.name,
    time_span: metrics.timeSpan,
    unit_name: metrics.unit,
    decimal_places: metrics.decimalPlaces,
    scaling: metrics.scaling,
    is_enabled: metrics.isEnabled,
    metrics_access_groups: metrics.accessGroupIds.map(el => {
      return {
        id: el,
      }
    }),
  }
}

const buildChildFromLogiSystemDataMetrics = (metrics: LogiSystemDataMetrics): Omit<LogiSystemDataBasicMetricsProperties, 'data_source'> => {
  return {
    aggr_func: metrics.aggrFunc,
    target_budget_group_id: metrics.budgetGroupId,
    data_source_key_id: metrics.logiDataSourceId,
    basic_metrics_parameters: metrics.queryParameters.map(el => {
      return {
        data_source_parameter_id: el.parameterId,
        value: el.value,
      }
    }),
  }
}
const buildChildFromLogimeterDataMetrics = (metrics: LogimeterDataMetrics): LogiSystemDataBasicMetricsProperties => {
  return { ...buildChildFromLogiSystemDataMetrics(metrics), data_source: 'logimeter' }
}
const buildChildFromLogiboardDataMetrics = (metrics: LogiboardDataMetrics): LogiSystemDataBasicMetricsProperties => {
  return { ...buildChildFromLogiSystemDataMetrics(metrics), data_source: 'logiboard' }
}

const buildChildFromDirectInputMetrics = (metrics: DirectInputMetrics): DirectInputBasicMetricsProperties => {
  return {
    data_source: 'direct_input',
    default_value: metrics.defaultValue,
  }
}

const buildChildFromSummaryMetrics = (metrics: SummaryMetrics): SummaryMetricsProperties => {
  // closingの変換
  let closingTiming1 = 1
  let closingTiming2 = null
  if (isTimeSpanWeekly(metrics.timeSpan)) {
    closingTiming1 = dayOfWeekToNumber(metrics.closingDow!)
  } else if (isTimeSpanMonthly(metrics.timeSpan)) {
    closingTiming1 = metrics.closingDay!
  } else if (isTimeSpanYearly(metrics.timeSpan)) {
    closingTiming1 = metrics.closingMonth!
    closingTiming2 = metrics.closingDay
  }

  return {
    target_metrics_id: metrics.targetMetricsId,
    closing_timing1: closingTiming1,
    closing_timing2: closingTiming2,
    aggr_func: metrics.aggrFunc,
    is_zero_included: metrics.isZeroIncluded,
    wday: metrics.weekDays,
  }
}

const buildReferenceMetricsOffsetProperties = (metrics: ReferenceMetrics): Omit<ReferenceMetricsProperties, 'target_metrics_id'> => {
  const base = {
    offset_anchor_unit: null,
    offset_anchor_diff: null,
    offset_unit1: null,
    offset_value1: null,
    offset_unit2: null,
    offset_value2: null,
  }
  if (
    isReferenceMetricsOffsetPeriodYear(metrics.offsetPeriod) &&
    (metrics.referencePointMonth !== null || metrics.referencePointDay !== null)
  ) {
    return {
      offset_anchor_unit: YEAR,
      offset_anchor_diff: metrics.offsetPeriod.value!,
      offset_unit1: MONTH,
      offset_value1: metrics.referencePointMonth!,
      offset_unit2: metrics.referencePointDay ? DAY : null, // 月次の場合は日を設定しない
      offset_value2: metrics.referencePointDay,
    }
  } else if (isReferenceMetricsOffsetPeriodYear(metrics.offsetPeriod)) {
    return {
      ...base,
      offset_unit1: YEAR,
      offset_value1: metrics.offsetPeriod.value!,
    }
  } else if (isReferenceMetricsOffsetPeriodMonth(metrics.offsetPeriod) && metrics.referencePointDay) {
    return {
      ...base,
      offset_anchor_unit: MONTH,
      offset_anchor_diff: metrics.offsetPeriod.value!,
      offset_unit1: DAY,
      offset_value1: metrics.referencePointDay!,
    }
  } else if (isReferenceMetricsOffsetPeriodMonth(metrics.offsetPeriod)) {
    return {
      ...base,
      offset_unit1: MONTH,
      offset_value1: metrics.offsetPeriod.value!,
    }
  } else if (isReferenceMetricsOffsetPeriodWeek(metrics.offsetPeriod) && metrics.referencePointDow) {
    return {
      ...base,
      offset_anchor_unit: WEEK,
      offset_anchor_diff: metrics.offsetPeriod.value!,
      offset_unit1: DOW,
      offset_value1: dayOfWeekToNumber(metrics.referencePointDow),
    }
  } else if (isReferenceMetricsOffsetPeriodWeek(metrics.offsetPeriod)) {
    return {
      ...base,
      offset_unit1: WEEK,
      offset_value1: metrics.offsetPeriod.value!,
    }
  } else {
    return {
      ...base,
      offset_unit1: DAY,
      offset_value1: metrics.offsetPeriod.value!,
    }
  }
}

const buildChildFromReferenceMetrics = (metrics: ReferenceMetrics): ReferenceMetricsProperties => {
  return {
    target_metrics_id: metrics.targetMetricsId,
    ...buildReferenceMetricsOffsetProperties(metrics),
  }
}

const buildChildFromBundledMetrics = (metrics: BundledMetrics): BundledMetricsProperties => {
  return {
    aggr_func: metrics.aggrFunc,
    is_zero_included: metrics.isZeroIncluded,
    targets: metrics.targetMetricsIds.map(el => {
      return {
        target_metrics_id: el,
      }
    }),
  }
}

const buildChildFromCalculatedMetrics = (metrics: CalculatedMetrics): CalculatedMetricsProperties => {
  const operand1Value = metrics.operands[0].constant
  const operand1Id = metrics.operands[0].metricsId
  const operand2Value = metrics.operands[1].constant
  const operand2Id = metrics.operands[1].metricsId
  const operand3Value = metrics.operands[2].constant
  const operand3Id = metrics.operands[2].metricsId

  return {
    operand1_id: operand1Id,
    operand1_value: operand1Value,
    operator1: metrics.operators[0],
    operand2_id: operand2Id,
    operand2_value: operand2Value,
    operator2: metrics.operators[1],
    operand3_id: operand3Id,
    operand3_value: operand3Value,
  }
}
