
import { splitStringInHalf } from 'src/util/text_decorator';
import { getFontSizeBasedOnLength, calculateFontSizeForNumericString } from 'src/util/responsive';
import Vue, {
  ref,
  PropType,
  computed,
  defineComponent,
  getCurrentInstance,
  reactive,
  watch,
  onMounted,
  onUnmounted,
} from 'vue';
import { CSSProperties } from 'vue/types/jsx';
import { formatMetricsValue, Metrics } from 'src/models/new/metrics';
import {
  MetricsCardComponent,
  applyConditionalStatementToMetricsCardComponentIconClass,
  applyConditionalStatementToMetricsCardComponentIconStyle,
  applyConditionalStatementToMetricsCardComponentTextStyle,
  displayUnitFromMeticsCardComponent,
} from 'src/models/new/Component/MetricsComponent/metricsCardComponent';
import { isMetricsDirectInputMetrics } from 'src/models/new/Metrics/BasicMetrics/directInputMetrics';
import { formatDate } from 'src/util/Datetime/parse';
import { isMetricsSummaryMetrics } from 'src/models/new/Metrics/summaryMetrics';
import { isMetricsCalculatedMetrics } from 'src/models/new/Metrics/calculatedMetrics';
import { isMetricsReferenceMetrics } from 'src/models/new/Metrics/referenceMetrics';
import { isMetricsBundledMetrics } from 'src/models/new/Metrics/bundledMetrics';
import MetricsTypeRoundPoint from 'src/components/MetricsTypeRoundPoint.vue';
import TooltipButton from 'src/components/UIComponents/Tooltip/TooltipButton.vue';
import { ComponentLink } from 'src/models/new/Link/componentLink';
import componentLinkApi from 'src/apis/componentLink';
import { TooltipMenuSettings } from 'src/components/UIComponents/Tooltip/TooltipContent/TooltipContent.vue';

type IconProperties = {
  display: boolean;
  code: string | null;
  style: Pick<CSSProperties, 'color'>;
};

type TitleProperties = {
  original: string;
  firstPart: string;
  lastPart: string;
  style: Pick<CSSProperties, 'fontSize'>;
};

type ValueViewProperties = {
  value: string;
  style: Pick<CSSProperties, 'color' | 'backgroundColor' | 'fontSize'>;
};

type SubValueViewProperties = {
  value: string;
  style: Pick<CSSProperties, 'fontSize'>;
  title: TitleProperties;
};

interface State {
  icon: IconProperties;
  title: TitleProperties;
  unit: string;
  valueFontSize: string;
  valueView: ValueViewProperties;
  subValueFontSize: string;
  subValueView: SubValueViewProperties;
  metrics: Metrics;
  componentLinks: ComponentLink[];
  componentLinkTooltipMenus: TooltipMenuSettings[];
}

const VALUE_BASE_FONT_SIZE = 36;
const SUB_VALUE_BASE_FONT_SIZE = 14;

function setupState(root: Vue): State {
  const state: State = reactive({
    icon: computed(() => {
      const component = root.$props.component as MetricsCardComponent;
      const matchedCode = applyConditionalStatementToMetricsCardComponentIconClass(component);
      const matchedStyle = applyConditionalStatementToMetricsCardComponentIconStyle(component);
      return { display: !!matchedCode, code: matchedCode, style: matchedStyle };
    }),
    title: computed(() => {
      const component = root.$props.component as MetricsCardComponent;
      const original = component.name;
      const halvedTitle = splitStringInHalf(original);
      return {
        original,
        firstPart: halvedTitle.firstHalf,
        lastPart: halvedTitle.secondHalf,
        style: { fontSize: getFontSizeBasedOnLength(`${original}(pcs)`, 20, 24) },
      };
    }),
    unit: computed(() => {
      const component = root.$props.component as MetricsCardComponent;
      return displayUnitFromMeticsCardComponent(component);
    }),
    valueFontSize: VALUE_BASE_FONT_SIZE + 'px',
    valueView: computed(() => {
      const component = root.$props.component as MetricsCardComponent;
      return {
        value: formatMetricsValue(component.metrics),
        style: {
          ...applyConditionalStatementToMetricsCardComponentTextStyle(component),
          fontSize: state.valueFontSize,
        },
      };
    }),
    subValueFontSize: SUB_VALUE_BASE_FONT_SIZE + 'px',
    subValueView: computed(() => {
      const component = root.$props.component as MetricsCardComponent;
      const originalTitle = component.subMetricsName || '';
      const halvedTitle = splitStringInHalf(originalTitle);
      return {
        value: component.subMetrics ? formatMetricsValue(component.subMetrics) : '',
        style: { fontSize: state.subValueFontSize },
        title: {
          original: originalTitle,
          firstPart: halvedTitle.firstHalf,
          lastPart: halvedTitle.secondHalf,
          style: { fontSize: getFontSizeBasedOnLength(component.subMetricsName || '', 14, 20) },
        },
      };
    }),
    metrics: computed(() => {
      const component: MetricsCardComponent = root.$props.component;
      return component.metrics;
    }),
    componentLinks: [],
    componentLinkTooltipMenus: computed(() => {
      return state.componentLinks.map((el) => {
        return {
          text: el.name,
          link: {
            to: {
              path: el.url,
              query: { dt: root.$route.query.dt },
            },
            target: '_blank',
          },
        };
      });
    }),
  });
  return state;
}

export default defineComponent({
  components: {
    TooltipButton,
    MetricsTypeRoundPoint,
  },
  props: {
    component: {
      type: Object as PropType<MetricsCardComponent>,
      required: true,
    },
    reportId: {
      type: Number,
      required: true,
    },
    edit: {
      type: Function as PropType<(metrics: Metrics, editValueLabel: string) => void>,
      required: true,
    },
  },
  setup(props) {
    const root = getCurrentInstance()!.proxy;
    const state = setupState(root);
    const name = 'MetricsCardComponent';

    const loadComponentLinks = async () => {
      state.componentLinks = (await componentLinkApi.index(props.reportId, props.component.id)).sort(
        (a, b) => a.sequentialOrder - b.sequentialOrder,
      );
    };

    const onLinkTooltipButtonClick = (): void => {
      loadComponentLinks();
    };

    const onMetricsEditButtonClick = (): void => {
      if (!isMetricsDirectInputMetrics(state.metrics)) {
        return;
      }
      props.edit(state.metrics, props.component.name);
    };

    const onBreakdownButtonClick = (metrics: Metrics): void => {
      if (!metrics.closingDate) {
        throw Error('Metrics closingDate is required.');
      }

      root.$router.push({
        name: 'MetricsBreakdown',
        params: {
          metricsId: String(metrics.id),
        },
        query: { dt: formatDate(metrics.closingDate, 'yyyy-MM-dd') },
      });
    };

    const isBreakdownAccessible = (metrics: Metrics): boolean => {
      return (
        isMetricsSummaryMetrics(metrics) ||
        isMetricsCalculatedMetrics(metrics) ||
        isMetricsReferenceMetrics(metrics) ||
        isMetricsBundledMetrics(metrics)
      );
    };

    const valueContainer = ref<HTMLDivElement>();
    const subFirstTitle = ref<HTMLSpanElement>();
    const subLastTitle = ref<HTMLSpanElement>();

    const setValueFontSize = () => {
      if (!valueContainer.value) {
        return;
      }
      const valueContainerWidth = valueContainer.value.getBoundingClientRect().width;
      state.valueFontSize =
        calculateFontSizeForNumericString(state.valueView.value, VALUE_BASE_FONT_SIZE, valueContainerWidth) + 'px';
    };

    const setSubValueFontSize = () => {
      if (!valueContainer.value || !subFirstTitle.value || !subLastTitle.value) {
        return;
      }
      const valueContainerWidth = valueContainer.value.getBoundingClientRect().width;
      const subFirstTitleWidth = subFirstTitle.value.getBoundingClientRect().width;
      const subLastTitleWidth = subLastTitle.value.getBoundingClientRect().width;
      state.subValueFontSize =
        calculateFontSizeForNumericString(
          state.subValueView.value,
          SUB_VALUE_BASE_FONT_SIZE,
          valueContainerWidth - subFirstTitleWidth - subLastTitleWidth,
        ) + 'px';
    };

    watch(() => state.valueView.value, setValueFontSize);

    watch(() => state.subValueView.value, setSubValueFontSize);

    const observer = new ResizeObserver(() => {
      setValueFontSize();
      setSubValueFontSize();
    });

    onMounted(() => {
      if (valueContainer.value) {
        observer.observe(valueContainer.value);
      }
    });

    onUnmounted(() => {
      if (valueContainer.value) {
        observer.unobserve(valueContainer.value);
      }
    });

    return {
      name,
      state,
      props,
      onLinkTooltipButtonClick,
      onMetricsEditButtonClick,
      onBreakdownButtonClick,
      isMetricsDirectInputMetrics,
      isBreakdownAccessible,
      valueContainer,
      subFirstTitle,
      subLastTitle,
    };
  },
});
