import { DATA_HEADER_KEY_WORD_DAY_HEADER, MatrixHeaders, constructMinimumEmptyMatrixHeaders } from 'src/util/dataHeader'
import { Metrics } from 'src/models/new//metrics'
import { MetricsComponent } from 'src/models/new/Component/metricsComponent'
import { MetricsTransitionComponentProperties } from 'src/models/new/Component/MetricsComponent/metricsTransitionComponentProperties'
import { MetricsListComponentConditionalStatement } from 'src/models/new/ConditionalStatement/metricsListComponentConditionalStatement'
import { CSSProperties } from 'vue/types/jsx'
import {
  baseValueFromConditionalStatement,
  isConditionalStatementBaseReference,
  isConditionalStatementBaseSelf,
  isConditionalStatementComparatorIsNull,
  isConditionalStatementTargetBackground,
  isConditionalStatementTargetIcon,
  isConditionalStatementTargetText,
  isConditionalStatementThresholdReference,
  thresholdValueFromConditionalStatement,
} from 'src/models/new/conditionalStatement'
import { compareWithComparator } from 'src/util/comparator'
import { Component, internal } from 'src/models/new/component'
import { constructPeriod, PERIOD_THIS_WEEK } from 'src/business/period'
import { SYSTEM_DATE_FORMAT } from 'src/util/Datetime/format'
import { IconDecoration, classFromIconDecoration, styleFromIconDecoration } from '../../ConditionalStatement/Decorations/iconDecoration'
import { TextDecoration, styleFromTextDecoration } from '../../ConditionalStatement/Decorations/textDecoration'
import { BackgroundDecoration, styleFromBackgroundDecoration } from '../../ConditionalStatement/Decorations/backgroundDecoration'
import { TimeSpan, isTimeSpanLarger } from 'src/business/timeSpan'

const METRICS_LIST = internal.METRICS_LIST

// TODO: デバッグ用で制限緩和中(終わったら36に戻す)
export const METRICS_LIST_COMPONENT_MAX_LAYOUT_CELL = 99999

export const METRICS_LIST_COMPONENT_MIN_WIDTH = 1
export const METRICS_LIST_COMPONENT_MAX_WIDTH = 6
export const METRICS_LIST_COMPONENT_DEFAULT_WIDTH = 6
export const METRICS_LIST_COMPONENT_MIN_HEIGHT = 1
export const METRICS_LIST_COMPONENT_MAX_HEIGHT = 12
export const METRICS_LIST_COMPONENT_DEFAULT_HEIGHT = 2

export type MetricsListComponentLayoutListCell = {
  sequentialOrder: number
  isSubMetrics: boolean
  // 1から始まる同一sequentialOrder内でのサブメトリクスの表示順番号
  subMetricsNumber: number | null
  metrics: Metrics
  conditionalStatements: MetricsListComponentConditionalStatement[]
}
export type MetricsListComponentComputedListCell = {
  row: number
  column: number
  metrics: Metrics
  closingDate: Date
  conditionalStatements: MetricsListComponentConditionalStatement[]
}

export type MetricsListComponent = MetricsComponent & MetricsTransitionComponentProperties & {
  isVertical: boolean
  headers: MatrixHeaders
  isUnitIndividualDisplayed: boolean
  closingDates: Date[]
  data: {
    layout: MetricsListComponentLayoutListCell[]
    computed: MetricsListComponentComputedListCell[] | null
  }
}

export const constructEmptyMetricsListComponent = (): MetricsListComponent => {
  const matrixHeaders = constructMinimumEmptyMatrixHeaders()
  matrixHeaders.head.layout.data
    .find(el => el.level === 1 && el.position === 1)!.value = DATA_HEADER_KEY_WORD_DAY_HEADER

  return {
    id: 0,
    sectionId: 0,
    componentType: METRICS_LIST,
    abscissa: 0,
    ordinate: 0,
    width: METRICS_LIST_COMPONENT_DEFAULT_WIDTH,
    height: METRICS_LIST_COMPONENT_DEFAULT_HEIGHT,
    referenceDate: null,
    period: constructPeriod(PERIOD_THIS_WEEK),
    isTodayDisplayed: true,
    isFutureDisplayed: false,
    isDescendingDateOrder: false,
    dateFormat: SYSTEM_DATE_FORMAT,
    dispDaysRewindPeriod: null,
    name: '',
    isVertical: false,
    headers: constructMinimumEmptyMatrixHeaders(),
    isUnitIndividualDisplayed: false,
    closingDates: [],
    data: {
      layout: [],
      computed: null,
    }
  }
}

export const isComponentTypeMetricsList = (component: Component): component is MetricsListComponent => {
  return component.componentType === METRICS_LIST
}

const getReferenceMetrics = (component: MetricsListComponent, row: number, column: number): Metrics | null => {
  const referencePosition = component.closingDates.length + 1
  // モデルの構造上は参考値が複数持てるようになっているが、現在の仕様としては1つだけであり
  // 条件付き書式における参考値は先頭の参考値を指す
  // また参考値から見ても参考値とは先頭の参考値である
  // ただし実際には参考値に対して参考値を参照する条件付き書式を設定することは現状できないようになっている
  if (!component.isVertical) {
    return component.data.computed?.find(el => el.row === row && el.column === referencePosition)?.metrics ?? null
  } else if (component.isVertical) {
    return component.data.computed?.find(el => el.row === referencePosition && el.column === column)?.metrics ?? null
  }
  return null
}

const getBaseValue = (
  component: MetricsListComponent,
  cell: MetricsListComponentComputedListCell,
  conditionalStatement: MetricsListComponentConditionalStatement,
): number | null => {
  if (isConditionalStatementBaseSelf(conditionalStatement)) return cell.metrics.value ?? null
  if (isConditionalStatementBaseReference(conditionalStatement)) {
    return getReferenceMetrics(component, cell.row, cell.column)?.value ?? null
  }
  return baseValueFromConditionalStatement(conditionalStatement, cell.closingDate)
}

const getThresholdValue = (
  component: MetricsListComponent,
  cell: MetricsListComponentComputedListCell,
  conditionalStatement: MetricsListComponentConditionalStatement
): number | null => {
  if (isConditionalStatementThresholdReference(conditionalStatement)) {
    return getReferenceMetrics(component, cell.row, cell.column)?.value ?? null
  }
  return thresholdValueFromConditionalStatement(conditionalStatement, cell.closingDate)
}

const extractPriorTargetDecoration = (
  component: MetricsListComponent, row: number, column: number,
  targetCheckFunction: (conditionalStatement: MetricsListComponentConditionalStatement) => boolean
): MetricsListComponentConditionalStatement | null => {
  const cell = component.data.computed?.find(el => el.row === row && el.column === column) ?? null
  return cell?.conditionalStatements
    .filter(el => targetCheckFunction(el))
    .sort((a, b) => b.priority - a.priority)
    .reduce((found: MetricsListComponentConditionalStatement | null, conditionalStatement: MetricsListComponentConditionalStatement) => {
      if (found) return found
      const base = getBaseValue(component, cell, conditionalStatement)
      if (isConditionalStatementComparatorIsNull(conditionalStatement)) {
        return compareWithComparator(base, conditionalStatement.comparator, null)
          ? conditionalStatement
          : null
      }
      const threshold = getThresholdValue(component, cell, conditionalStatement)
      if (base === null || threshold === null) return found
      if (!compareWithComparator(base, conditionalStatement.comparator, threshold)) return found
      return conditionalStatement
    }, null) ?? null
}

export const applyConditionalStatementToMetricsListComponentTextStyle = (
  component: MetricsListComponent, row: number, column: number): CSSProperties => {
  if (!component.referenceDate) return {}
  const decoration = extractPriorTargetDecoration(
    component,
    row,
    column,
    isConditionalStatementTargetText,
  )?.decoration
  return decoration ? styleFromTextDecoration(decoration as TextDecoration) : {}
}
export const applyConditionalStatementToMetricsListComponentIconStyle = (
  component: MetricsListComponent, row: number, column: number): CSSProperties => {
  if (!component.referenceDate) return {}
  const decoration = extractPriorTargetDecoration(
    component,
    row,
    column,
    isConditionalStatementTargetIcon,
  )?.decoration
  return decoration ? styleFromIconDecoration(decoration as IconDecoration) : {}
}
export const applyConditionalStatementToMetricsListComponentIconClass = (
  component: MetricsListComponent, row: number, column: number): string | null => {
  if (!component.referenceDate) return null
  const decoration = extractPriorTargetDecoration(
    component,
    row,
    column,
    isConditionalStatementTargetIcon
  )?.decoration
  return decoration ? classFromIconDecoration(decoration as IconDecoration) : null
}
export const applyConditionalStatementToMetricsListComponentBackgroundStyle = (
  component: MetricsListComponent, row: number, column: number): CSSProperties => {
  if (!component.referenceDate) return {}
  const decoration = extractPriorTargetDecoration(
    component,
    row,
    column,
    isConditionalStatementTargetBackground,
  )?.decoration
  return decoration ? styleFromBackgroundDecoration(decoration as BackgroundDecoration) : {}
}

export const specifyMetricsListComponentDominantTimeSpan = (component: MetricsListComponent): TimeSpan | null => {
  return component.data.layout
    .reduce((dominantTimeSpan: TimeSpan | null, cell: MetricsListComponentLayoutListCell) => {
      if (cell.isSubMetrics) return dominantTimeSpan
      const timeSpan = cell.metrics.timeSpan
      if (!dominantTimeSpan) return timeSpan
      return isTimeSpanLarger(timeSpan, dominantTimeSpan) ? timeSpan : dominantTimeSpan
    }, null)
}

export const clearMetricsListComponentMainMetrics = (component: MetricsListComponent): MetricsListComponent => {
  return {
    ...component,
    data: {
      ...component.data,
      layout: component.data.layout.filter(el => el.isSubMetrics),
    }
  }
}
