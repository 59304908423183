export const transpose = (matrix: any[][]) => {
  return matrix[0].map((_, columnIndex) => matrix.map(row => row[columnIndex]))
}

export const swap = (array: any[], index1: number, index2: number): any[] => {
  const newArray = [...array]
  newArray[index1] = array[index2]
  newArray[index2] = array[index1]
  return newArray
}
