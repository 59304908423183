import axios from 'axios';
import { DataSourceParameterValue } from 'src/models/dataSourceParameterValue';
import { StringOrNumber } from 'src/models/common';
import { buildBackendUrl } from 'src/apis/util/url';

const buildBasePath = (): string => '/masters/data_source_parameter_values';

interface DataSourceParameterValueIndexRequest {
  workplace_id: StringOrNumber;
  data_source_parameter_id: number;
  budget_group_id: StringOrNumber;
}

export default {
  async index(data: DataSourceParameterValueIndexRequest): Promise<DataSourceParameterValue[]> {
    const url = buildBackendUrl(buildBasePath());
    const { data: resData } = await axios.get(url, { params: data });
    return resData;
  },
};
