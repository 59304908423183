
import Vue, { computed, defineComponent, getCurrentInstance, onMounted, reactive, ref } from 'vue';
import { setPageName } from 'src/hooks/displayPageNameHook';
import { wrappedMapGetters } from 'src/hooks/storeHook';
import { ensureUserRefreshAndMasters } from 'src/hooks/masterHook';
import metricsValueApi from 'src/apis/metricsValue';
import type { MetricsValueCheckAuthorizationRequestParameters } from 'src/models/api/Metrics/MetricsValue/metricsValueCheckAuthorizationRequestParameters';
import type { MetricsValueUpsertRequestParameters } from 'src/models/api/Metrics/MetricsValue/metricsValueUpsertRequestParameters';
import workplaceApi from 'src/apis/masters/workplace';
import dataFetchStatusApi from 'src/apis/dataFetchStatus';
import type { DataFetchStatusCreateByManualRequestParameters } from 'src/models/api/DataFetchStatus/dataFetchStatusCreateByManualRequestParameters';
import { notifyError1, notifySuccess1 } from 'src/hooks/notificationHook';
import { ERROR_REASON_INVALID_PARAMS, ERROR_REASON_MAX_PROCESSING_JOBS, ERROR_REASON_INVALID } from 'src/consts';
import { FgInput } from 'src/components/UIComponents';
import MetricsSearchPanel, {
  type SearchStateWithDates as MetricsSearchPanelSearchState,
} from 'src/components/MetricsSearchPanel/index.vue';
import {
  type Metrics,
  metricsToTypeLocalWord,
  metricsToTypeImageColor,
  formatMetricsValue,
} from 'src/models/new/metrics';
import { timeSpanToLocalWord } from 'src/business/timeSpan';
import { isMetricsDirectInputMetrics } from 'src/models/new/Metrics/BasicMetrics/directInputMetrics';
import type { MetricsValueIndexRequestParameters } from 'src/models/api/Metrics/MetricsValue/metricsValueIndexRequestParameters';
import type { Workplace } from 'src/models/new/workplace';
import { dateRangeFromEndDate, dateRangeToLocalWord } from 'src/util/Datetime/dateRange';
import { formatDate, isSameDay } from 'src/util/datetime';
import MetricsBadge from 'src/components/MetricsBadge.vue';
import { DATE_FNS_DATE_TIME_FORMAT, SYSTEM_DATE_FORMAT } from 'src/util/Datetime/format';
import { usePaginationContainer } from 'src/components/UIComponents/PaginationContainer.vue';

const FETCH_METRICS_MESSAGE_MANUAL = 'メトリクス作成・更新を開始しました';
const FETCH_METRICS_MESSAGE_CSV = 'CSVデータ取込を開始しました';
const VALUE_TEXT = 'メトリクス値';
const ITEM_PER_PAGE = 50;

// 表の表示の為メトリクスモデルを拡張した構造を定義する
// 表示行をコンポーネントに切り出して算出プロパティにすれば不要だが、それは今後の課題とする
type ExtendedMetrics = Metrics & {
  uniqueKey: string;
  spanDisp: string;
  workplaceName: string;
  formattedValue: string;
  updatedAtDisp: string;
};

const metricsFromExtendedMetrics = (extendedMetrics: ExtendedMetrics) => {
  const { uniqueKey, spanDisp, workplaceName, formattedValue, updatedAtDisp, ...metrics } = extendedMetrics;
  return metrics;
};

interface State {
  pageName: string | null;
  userId: number | null;
  hasMetricsAdminRole: boolean;
  hasMetricsGtReadWriteRole: boolean;

  workplaceOptions: Workplace[];
  metricsItems: Metrics[];
  extendedMetricsItems: ExtendedMetrics[];
  maxSpanDispLen: number;

  updateMetricsValueCandidate: Metrics | null;

  canBatchUpdateMetricsValues: boolean;
  canCsvUpload: any;
  isCsvUploading: boolean;
  hasList: boolean;
  showEditValueModal: boolean;

  hasPageAccessRole: boolean;

  dataLoadState: { availableTotal: number; loadedSlices: number };
  searchParams: MetricsSearchPanelSearchState;
  // 検索実行時のsearchParams保持用に定義
  lastSearchParams: MetricsSearchPanelSearchState;
}

const initialSearchParams = {
  name: null,
  workplaceId: null,
  timeSpan: null,
  metricsType: null,
  startDate: null,
  endDate: null,
};

function setupState(root: Vue): State {
  const state: State = reactive({
    ...wrappedMapGetters(root.$store, 'displayPageName', ['pageName']),
    userId: wrappedMapGetters(root.$store, 'user', ['id']).id,
    hasMetricsAdminRole: computed(() => root.$store.getters['user/hasMetricsAdminRole']),
    hasMetricsGtReadWriteRole: computed(() => root.$store.getters['user/hasMetricsGtReadWriteRole']),
    workplaceOptions: [],
    metricsItems: [],
    extendedMetricsItems: computed(() => {
      if (state.workplaceOptions.length === 0) {
        return [];
      }
      return state.metricsItems.map((metrics) => {
        return {
          ...metrics,
          uniqueKey: `${metrics.id}-${formatDate(metrics.closingDate!, 'yyyyMMdd')}`,
          workplaceName: state.workplaceOptions.find((el) => el.id === metrics.workplaceId)?.name || '',
          spanDisp: dateRangeToLocalWord(dateRangeFromEndDate(metrics.closingDate!, metrics.timeSpan)),
          formattedValue: formatMetricsValue(metrics),
          updatedAtDisp: metrics.updatedAt ? formatDate(metrics.updatedAt, DATE_FNS_DATE_TIME_FORMAT) : '',
        };
      });
    }),
    maxSpanDispLen: computed(() => {
      if (state.extendedMetricsItems.length === 0) {
        return 0;
      }
      return state.extendedMetricsItems.reduce((max, el) => Math.max(max, el.spanDisp.length), 0);
    }),
    updateMetricsValueCandidate: null,

    hasList: computed(() => {
      return state.extendedMetricsItems.length > 0;
    }),
    canBatchUpdateMetricsValues: false,
    canCsvUpload: computed(() => {
      return !state.isCsvUploading;
    }),
    isCsvUploading: false,
    showEditValueModal: false,

    hasPageAccessRole: true,

    dataLoadState: { availableTotal: 0, loadedSlices: 0 },
    searchParams: { ...initialSearchParams },
    lastSearchParams: { ...initialSearchParams },
  });
  return state;
}

export default defineComponent({
  components: {
    MetricsSearchPanel,
    MetricsBadge,
    PaginationContainer: usePaginationContainer<ExtendedMetrics>(),
  },
  setup() {
    const root = getCurrentInstance()!.proxy;
    setPageName(root, 'メトリクス');
    const state = setupState(root);
    const batchUpdateButtonTooltip = computed(() => {
      if (state.metricsItems.length === 0) {
        return '';
      }
      return state.canBatchUpdateMetricsValues
        ? ''
        : '※メトリクス作成・更新を実行するには検索日付範囲を一日にしてください';
    });
    const refCsvImport = ref<InstanceType<typeof FgInput>>();

    const buildMetricsValueIndexQueryParams = (
      searchParams: MetricsSearchPanelSearchState,
      page: number | null,
    ): MetricsValueIndexRequestParameters => {
      return {
        page: page,
        name: searchParams.name,
        logiscope_workplace_id: searchParams.workplaceId,
        time_span: searchParams.timeSpan,
        metrics_type: searchParams.metricsType,
        start_dt: searchParams.startDate !== null ? formatDate(searchParams.startDate, SYSTEM_DATE_FORMAT) : null,
        end_dt: searchParams.endDate !== null ? formatDate(searchParams.endDate, SYSTEM_DATE_FORMAT) : null,
      };
    };

    const loadMetrics = async (searchParams: MetricsSearchPanelSearchState): Promise<void> => {
      state.dataLoadState.loadedSlices = 1;
      const queryParams = buildMetricsValueIndexQueryParams(searchParams, 1);

      try {
        // TODO apiから戻ってきた時点でDateStringはDateになっているべき
        const response = await metricsValueApi.index(queryParams);
        state.metricsItems = response.result;
        state.dataLoadState.availableTotal = response.pagination.total;
        if ('startDate' in searchParams && 'endDate' in searchParams) {
          // リストがあり、且つ日付範囲が一日
          state.canBatchUpdateMetricsValues =
            state.metricsItems.length > 0 &&
            searchParams.startDate !== null &&
            searchParams.endDate !== null &&
            isSameDay(searchParams.startDate, searchParams.endDate);
        }
      } catch (err: any) {
        const errStatus = err.response?.status;
        const errRes = err.response?.data || {};
        if (errStatus === 400 && errRes.reason === ERROR_REASON_INVALID_PARAMS) {
          notifyError1(root, errRes.message, { err });
        } else if (errStatus === 403) {
          state.hasPageAccessRole = false;
        } else {
          const msg = 'メトリクスの取得に失敗しました。管理者に連絡してください。' + `(ERR: ${state.pageName})`;
          notifyError1(root, msg, { err });
        }
        // 失敗した場合はページコンポーネントをいじれないようにしておく???
        state.canBatchUpdateMetricsValues = false;
      }
    };

    const loadMetricsNextSlice = async (): Promise<void> => {
      state.dataLoadState.loadedSlices += 1;
      const queryParams = buildMetricsValueIndexQueryParams(state.lastSearchParams, state.dataLoadState.loadedSlices);

      try {
        // TODO apiから戻ってきた時点でDateStringはDateになっているべき
        const response = await metricsValueApi.index(queryParams);
        state.dataLoadState.availableTotal = response.pagination.total;
        state.metricsItems = [...state.metricsItems, ...response.result];
      } catch (err: any) {
        const errStatus = err.response?.status;
        const errRes = err.response?.data || {};
        if (errStatus === 400 && errRes.reason === ERROR_REASON_INVALID_PARAMS) {
          notifyError1(root, errRes.message, { err });
        } else if (errStatus === 403) {
          state.hasPageAccessRole = false;
        } else {
          const msg = 'メトリクスの取得に失敗しました。管理者に連絡してください。' + `(ERR: ${state.pageName})`;
          notifyError1(root, msg, { err });
        }
        // 失敗した場合はページコンポーネントをいじれないようにしておく???
        state.canBatchUpdateMetricsValues = false;
      }
    };

    const onSearch = async (): Promise<void> => {
      state.lastSearchParams = { ...state.searchParams };
      await loadMetrics(state.searchParams);
    };

    const openEditValueModal = async (extendedMetrics: ExtendedMetrics): Promise<void> => {
      const metrics = metricsFromExtendedMetrics(extendedMetrics);
      const reqParams: MetricsValueCheckAuthorizationRequestParameters = {
        metrics_id: metrics.id,
      };
      try {
        // 「メトリクス作成・更新」の実行を不可にする
        state.canBatchUpdateMetricsValues = false;

        await metricsValueApi.checkAuthorization(reqParams);
        state.showEditValueModal = true;
        state.updateMetricsValueCandidate = metrics;
      } catch (err: any) {
        const errStatus = err.response?.status;
        if ([403, 404].includes(errStatus)) {
          const msg = `${metrics.name}の書き込み権限がありません。管理者にお問合せください。`;
          notifyError1(root, msg, { err });
        } else {
          const msg = `${state.pageName}の更新に失敗しました。管理者に連絡してください。` + `(ERR: ${state.pageName})`;
          notifyError1(root, msg, { err });
        }
      }
    };

    const updateMetricsValue = async (): Promise<void> => {
      if (!state.updateMetricsValueCandidate || !state.updateMetricsValueCandidate.closingDate) {
        closeEditValueModal();
        return;
      }

      const reqParams: MetricsValueUpsertRequestParameters = {
        dt: state.updateMetricsValueCandidate.closingDate,
        metrics_id: state.updateMetricsValueCandidate.id,
        value: state.updateMetricsValueCandidate.value,
      };
      try {
        const updatedMetricsValue = await metricsValueApi.upsert(reqParams);
        // 丸ごと取り直すのはもったいないので、戻ってきた値を取り込んで画面をリフレッシュ
        state.updateMetricsValueCandidate.value = updatedMetricsValue.value;

        const itemKey = state.metricsItems.findIndex((metrics) => {
          return (
            metrics.id === updatedMetricsValue.id &&
            metrics.closingDate!.valueOf() === updatedMetricsValue.closingDate!.valueOf()
          );
        });
        Vue.set(state.metricsItems, itemKey, state.updateMetricsValueCandidate);

        notifySuccess1(root, `${state.updateMetricsValueCandidate.name}の${VALUE_TEXT}を更新しました。`);
        closeEditValueModal();
      } catch (err: any) {
        const errStatus = err.response?.status;
        const errRes = err.response?.data || {};
        if ([403, 404].includes(errStatus)) {
          const msg = `${state.updateMetricsValueCandidate.name}の書き込み権限がありません。管理者にお問合せください。`;
          notifyError1(root, msg, { err });
        } else if (errStatus === 400 && [ERROR_REASON_INVALID_PARAMS, ERROR_REASON_INVALID].includes(errRes.reason)) {
          notifyError1(root, errRes.message, { err });
        } else {
          const msg =
            `${state.pageName}の${VALUE_TEXT}の更新に失敗しました。管理者に連絡してください。` +
            `(ERR: ${state.pageName})`;
          notifyError1(root, msg, { err });
        }
      }
    };

    const closeEditValueModal = (): void => {
      state.showEditValueModal = false;
      state.updateMetricsValueCandidate = null;
    };

    const openFileDialog = (): void => {
      (refCsvImport.value!.$el.children[0] as HTMLButtonElement).click();
    };

    const metricsValueCreateOrUpdateErrorHandler = (err: any): void => {
      const errStatus: number = err.response?.status;
      const errRes = err.response?.data || {};
      if (errStatus === 400 && errRes.reason === ERROR_REASON_MAX_PROCESSING_JOBS) {
        const msg = 'お客様の一括処理のリクエスト制限数を超えています。しばらくしてから再度実施ください。';
        notifyError1(root, msg, { err });
      } else if (errStatus === 400 && errRes.reason === ERROR_REASON_INVALID_PARAMS) {
        notifyError1(root, errRes.message, { err });
      } else {
        const msg =
          `${state.pageName}のメトリクス作成・更新に失敗しました。管理者に連絡してください。` +
          `(ERR: ${state.pageName})`;
        notifyError1(root, msg, { err });
      }
    };

    const onCsvUpload = async (e: any): Promise<void> => {
      state.isCsvUploading = true;
      try {
        const data = new FormData();
        data.append('csv', e.target.files[0]);
        await dataFetchStatusApi.createByCsv(data);
        notifySuccess1(root, FETCH_METRICS_MESSAGE_CSV);
      } catch (err) {
        metricsValueCreateOrUpdateErrorHandler(err);
      }
      state.isCsvUploading = false;
    };

    const batchUpdateMetricsValues = async (): Promise<void> => {
      if (!('startDate' in state.lastSearchParams && 'endDate' in state.lastSearchParams)) {
        return;
      }

      const searchParams = state.lastSearchParams;
      const metricsIds = [...new Set(state.metricsItems.map((e) => e.id))];
      if (searchParams.startDate === null || searchParams.endDate === null || metricsIds.length === 0) {
        return;
      }

      const params: DataFetchStatusCreateByManualRequestParameters = {
        start_dt: state.searchParams.startDate !== null ? formatDate(searchParams.startDate, SYSTEM_DATE_FORMAT) : '',
        end_dt: searchParams.endDate !== null ? formatDate(searchParams.endDate, SYSTEM_DATE_FORMAT) : '',
        metrics_ids: metricsIds,
      };
      try {
        await dataFetchStatusApi.createByManual(params);
        notifySuccess1(root, FETCH_METRICS_MESSAGE_MANUAL);
      } catch (err) {
        metricsValueCreateOrUpdateErrorHandler(err);
      }
    };

    onMounted(async () => {
      // ログインユーザー情報をAPIで再取得
      await ensureUserRefreshAndMasters(root);
      state.workplaceOptions = await workplaceApi.index();
    });

    return {
      state,
      openEditValueModal,
      updateMetricsValue,
      batchUpdateMetricsValues,
      onCsvUpload,
      openFileDialog,
      closeEditValueModal,
      refCsvImport,
      onSearch,
      batchUpdateButtonTooltip,
      metricsToTypeImageColor,
      metricsToTypeLocalWord,
      timeSpanToLocalWord,
      isMetricsDirectInputMetrics,
      loadMetricsNextSlice,
      ITEM_PER_PAGE,
    };
  },
});
