import { addDays, addMonths, addYears, format, isLastDayOfMonth, parse, startOfDay, startOfMonth } from 'date-fns'
import { SYSTEM_DATE_FORMAT } from './format'
import { TIME_SPAN_DAILY, TIME_SPAN_WEEKLY, TimeSpan } from 'src/business/timeSpan'
import { TIME_SPAN_MONTHLY, TIME_SPAN_YEARLY } from 'src/consts'
import { formatDate } from './parse'

export type DateRange = {
  startDate: Date,
  endDate: Date,
}

/**
 * 形式が日付範囲に分解可能なものであるかをチェックする
 * 月や日が実際に存在するものであるかや、範囲の前と後の大小関係はチェックしない
 */
export const isValidAsDateRangeString = (rangeString: string): boolean => {
  return !!rangeString.match(/^[0-9]{4}-[0-9]{2}-[0-9]{2}\.\.[0-9]{4}-[0-9]{2}-[0-9]{2}$/)
}
export const dateRangeToString = (range: DateRange): string => {
  return `${format(range.startDate, SYSTEM_DATE_FORMAT)}..${format(range.endDate, SYSTEM_DATE_FORMAT)}`
}
export const dateRangeFromString = (rangeString: string): DateRange => {
  if (!isValidAsDateRangeString(rangeString)) throw new Error(`Given string is not date range. ${rangeString}`)
  const [startDateString, endDateString] = rangeString.split('..')
  const startDate = parse(startDateString, SYSTEM_DATE_FORMAT, new Date())
  const endDate = parse(endDateString, SYSTEM_DATE_FORMAT, new Date())
  if (startDate.valueOf() > endDate.valueOf()) throw new Error(`Start date is after end date. ${rangeString}`)
  return { startDate, endDate }
}

export const dateRangeFromEndDate = (endDate: Date, timeSpan: TimeSpan = TIME_SPAN_DAILY): DateRange => {
  switch (timeSpan) {
    case TIME_SPAN_DAILY:
      return { startDate: endDate, endDate }
    case TIME_SPAN_WEEKLY:
      return { startDate: addDays(endDate, -6), endDate }
    case TIME_SPAN_MONTHLY:
      return {
        startDate: isLastDayOfMonth(endDate)
          ? startOfMonth(endDate)
          : addDays(addMonths(endDate, -1), 1),
        endDate,
      }
    case TIME_SPAN_YEARLY:
      return { startDate: addDays(addYears(endDate, -1), 1), endDate }
    default:
      throw new Error(`Unexpected time span. ${timeSpan}`)
  }
}

export const dateRangeToDateList = (range: DateRange, timeSpan: TimeSpan = TIME_SPAN_DAILY, fromEndDate: boolean = false): Date[] => {
  const list = []
  let current = fromEndDate ? range.endDate : range.startDate
  while (fromEndDate ? current >= range.startDate : current <= range.endDate) {
    list.push(current)
    switch (timeSpan) {
      case TIME_SPAN_DAILY:
        current = addDays(current, fromEndDate ? -1 : 1)
        break
      case TIME_SPAN_WEEKLY:
        current = addDays(current, fromEndDate ? -7 : 7)
        break
      case TIME_SPAN_MONTHLY:
        current = addMonths(current, fromEndDate ? -1 : 1)
        break
      case TIME_SPAN_YEARLY:
        current = addYears(current, fromEndDate ? -1 : 1)
        break
      default:
        throw new Error(`Unexpected time span. ${timeSpan}`)
    }
  }
  if (fromEndDate) list.reverse()

  return list
}
export const dateRangeFromDateList = (list: Date[]): DateRange => {
  if (list.length === 0) throw new Error('Given list is empty.')
  const startDate = startOfDay(list[0])
  const endDate = startOfDay(list[list.length - 1])
  if (startDate.valueOf !== list[0].valueOf ||
    endDate.valueOf !== list[list.length - 1].valueOf
  ) console.warn('Given list has time information. It may be out of proper use case.')
  return { startDate, endDate }
}

// この形のフォーマットをアプリケーション内の汎用フォーマットとして捉えた
// もしページごとにフォーマットが異なるのであれば、Vueコンポーネント側に移設する
export const dateRangeToLocalWord = (range: DateRange): string => {
  const startFormat = 'yyyy/MM/dd'
  if (range.startDate.valueOf() === range.endDate.valueOf()) {
    return formatDate(range.startDate, startFormat)
  }
  const spanEndFormat = range.startDate.getFullYear() === range.endDate.getFullYear()
    ? 'MM/dd'
    : 'yyyy/MM/dd'
  return `${formatDate(range.startDate, startFormat)} ～ ${formatDate(range.endDate, spanEndFormat)}`
}
