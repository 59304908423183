
import { PropType, defineComponent } from 'vue'
import { Location } from 'vue-router'
import GeneralLink from 'src/components/UIComponents/GeneralLink.vue'
import TooltipCell from './TooltipCell.vue'

export type TooltipMenuSettings = {
  text: string
  iconClasses?: string
  link?: {
    to: Location,
    target?: string
  }
  onClick?: () => void
  hidden?: boolean
  disabled?: boolean
}
export default defineComponent({
  components: {
    GeneralLink,
    TooltipCell,
  },
  props: {
    menus: {
      type: Array as PropType<TooltipMenuSettings[]>,
      required: true,
    },
  },
  setup(props) {
    return {
      props,
    }
  },
})
