
import { PropType, computed, defineComponent, getCurrentInstance, reactive } from 'vue';
import { CSSProperties } from 'vue/types/jsx';
import { Component } from 'src/models/new/component';
import {
  MetricsCardComponent,
  isComponentTypeMetricsCard,
} from 'src/models/new/Component/MetricsComponent/metricsCardComponent';
import { classFromIcon } from 'src/util/icon';
import { isComponentTypeMetricsList } from 'src/models/new/Component/MetricsComponent/metricsListComponent';
import {
  IconDecoration,
  styleFromIconDecoration,
} from 'src/models/new/ConditionalStatement/Decorations/iconDecoration';
import { isConditionalStatementTargetIcon } from 'src/models/new/conditionalStatement';
import { isComponentTypeMetricsPieChart } from 'src/models/new/Component/MetricsComponent/GraphMetricsComponent/metricsPieChartComponent';
import { isComponentTypeMetricsGraph } from 'src/models/new/Component/MetricsComponent/metricsGraphComponent';
import { isComponentTypeMetricsTransitionGraph } from 'src/models/new/Component/MetricsComponent/GraphMetricsComponent/metricsTransitionGraphComponent';
import { isComponentTypeMetricsBarGraph } from 'src/models/new/Component/MetricsComponent/GraphMetricsComponent/metricsBarGraphComponent';
import { isComponentTypeComment } from 'src/models/new/Component/commentComponent';
import { isComponentTypeMetricsGroupedGraph } from 'src/models/new/Component/MetricsComponent/GraphMetricsComponent/metricsGroupedGraphComponent';
import { isComponentTypeMetricsTable } from 'src/models/new/Component/MetricsComponent/metricsTableComponent';
import { TooltipMenuSettings } from 'src/components/UIComponents/Tooltip/TooltipContent/TooltipContent.vue';
import TooltipButton from 'src/components/UIComponents/Tooltip/TooltipButton.vue';

interface State {
  iconClass: string;
  iconStyle: CSSProperties;
  label: string;
  isWithConditionalStatement: boolean;
  tooltipMenus: TooltipMenuSettings[];

  urlParams: { reportId: number | null };
}

export default defineComponent({
  components: {
    TooltipButton,
  },
  props: {
    reportId: {
      type: Number,
      required: true,
    },
    component: {
      type: Object as PropType<Component>,
      required: true,
    },
    edit: {
      type: Function as PropType<(component: Component) => void>,
      required: true,
    },
    del: {
      type: Function as PropType<(component: Component) => void>,
      required: true,
    },
    copy: {
      type: Function as PropType<(component: Component) => void>,
      required: true,
    },
    isLayoutEditMode: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const root = getCurrentInstance()!.proxy;
    const state: State = reactive({
      iconClass: computed(() => {
        const component = props.component as Component;
        if (isComponentTypeMetricsCard(component)) {
          const base = 'fa fa-lg fas';
          const userSelectedIcon = (
            [...(component as MetricsCardComponent).conditionalStatements]
              .reverse()
              .find((el) => isConditionalStatementTargetIcon(el))?.decoration as IconDecoration | undefined
          )?.settings.icon;
          return userSelectedIcon ? `${base} ${classFromIcon(userSelectedIcon)}` : base;
        } else if (isComponentTypeMetricsTable(component)) {
          return 'fas fa-table opacity-0-5';
        } else if (isComponentTypeMetricsList(component)) {
          return 'fas fa-line-columns opacity-0-5';
        } else if (isComponentTypeMetricsPieChart(component)) {
          return 'fas fa-chart-pie opacity-0-5';
        } else if (isComponentTypeMetricsBarGraph(component)) {
          return 'fas fa-signal-4 fa-rotate-90 opacity-0-5';
        } else if (isComponentTypeMetricsGroupedGraph(component)) {
          return 'fas fa-signal-4 opacity-0-5';
        } else if (isComponentTypeMetricsTransitionGraph(component)) {
          return 'fas fa-chart-line opacity-0-5';
        } else if (isComponentTypeComment(component)) {
          return 'fas fa-comment-alt opacity-0-5';
        }
        return '';
      }),
      iconStyle: computed(() => {
        const component = props.component;
        if (isComponentTypeMetricsCard(component)) {
          const decoration = [...(component as MetricsCardComponent).conditionalStatements]
            .reverse()
            .find((el) => isConditionalStatementTargetIcon(el))?.decoration as IconDecoration | undefined;
          return decoration ? styleFromIconDecoration(decoration) : {};
        }
        return {};
      }),
      label: computed(() => props.component.name),
      isWithConditionalStatement: computed(() => {
        const component = props.component as Component;
        return (
          isComponentTypeMetricsCard(component) ||
          isComponentTypeMetricsTable(component) ||
          isComponentTypeMetricsList(component) ||
          isComponentTypeMetricsGraph(component)
        );
      }),
      tooltipMenus: computed(() => {
        const editMenu: TooltipMenuSettings = {
          text: '編集',
          iconClasses: 'fa fa-pen',
          onClick: () => {
            props.edit(props.component);
          },
        };
        const editConditionalStatementMenu: TooltipMenuSettings = {
          text: '書式設定',
          iconClasses: 'fas fa-text',
          link: {
            to: {
              name: 'SettingsComponentsEditConditionalStatements',
              params: {
                reportId: String(props.reportId),
                componentId: String(props.component.id),
              },
            },
          },
          onClick: () => {
            exportComponent();
          },
        };
        const linkMenu: TooltipMenuSettings = {
          text: 'リンク',
          iconClasses: 'fa fa-link',
          link: {
            to: {
              name: 'SettingsComponentsEditLinks',
              params: {
                reportId: String(props.reportId),
                componentId: String(props.component.id),
              },
            },
          },
          onClick: () => {
            exportComponent();
          },
        };
        const deleteMenu: TooltipMenuSettings = {
          text: '削除',
          iconClasses: 'fal fa-trash-alt',
          onClick: () => {
            props.del(props.component);
          },
        };
        const copyMenu: TooltipMenuSettings = {
          text: 'コピー',
          iconClasses: 'fal fa-copy',
          onClick: () => {
            props.copy(props.component);
          },
        };

        return state.isWithConditionalStatement
          ? [editMenu, editConditionalStatementMenu, linkMenu, deleteMenu, copyMenu]
          : [editMenu, linkMenu, deleteMenu, copyMenu];
      }),

      urlParams: computed(() => {
        return {
          reportId: root.$route.params.reportId ? Number(root.$route.params.reportId) : null,
        };
      }),
    });

    const exportComponent = () => {
      // TODO: 暫定対応 コンポーネントAPIが完成したら削除して遷移先で別途取得する
      sessionStorage.setItem('Tmp._component', JSON.stringify(props.component));
    };

    return {
      props,
      state,
    };
  },
});
