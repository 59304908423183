
import { PropType, computed, defineComponent, getCurrentInstance, reactive } from 'vue';
import { CommentComponent } from 'src/models/new/Component/commentComponent';
import { ComponentLink } from 'src/models/new/Link/componentLink';
import TooltipButton from 'src/components/UIComponents/Tooltip/TooltipButton.vue';
import { TooltipMenuSettings } from 'src/components/UIComponents/Tooltip/TooltipContent/TooltipContent.vue';
import componentLinkApi from 'src/apis/componentLink';

type State = {
  componentLinks: ComponentLink[];
  componentLinkTooltipMenus: TooltipMenuSettings[];
  isValueEmpty: boolean;
};

export default defineComponent({
  components: {
    TooltipButton,
  },
  props: {
    component: {
      type: Object as PropType<CommentComponent>,
      required: true,
    },
    reportId: {
      type: Number,
      required: true,
    },
    edit: {
      type: Function as PropType<(commentComponent: CommentComponent) => void>,
      required: true,
    },
  },
  setup(props) {
    const root = getCurrentInstance()!.proxy;
    const state: State = reactive({
      componentLinks: [],
      componentLinkTooltipMenus: computed(() => {
        return state.componentLinks.map((el) => {
          return {
            text: el.name,
            link: {
              to: {
                path: el.url,
                query: { dt: root.$route.query.dt },
              },
              target: '_blank',
            },
          };
        });
      }),
      isValueEmpty: computed(() => props.component.value === null || props.component.value === ''),
    });

    const loadComponentLinks = async () => {
      state.componentLinks = (await componentLinkApi.index(props.reportId, props.component.id)).sort(
        (a, b) => a.sequentialOrder - b.sequentialOrder,
      );
    };

    const onLinkTooltipButtonClick = (): void => {
      loadComponentLinks();
    };

    const onValueViewDoubleClick = (): void => {
      props.edit(props.component);
    };

    return {
      props,
      state,
      onLinkTooltipButtonClick,
      onValueViewDoubleClick,
    };
  },
});
