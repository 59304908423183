
import { PropType, computed, defineComponent, getCurrentInstance, onMounted, reactive } from 'vue';
import { isComponentTypeMetricsPieChart } from 'src/models/new/Component/MetricsComponent/GraphMetricsComponent/metricsPieChartComponent';
import { MetricsGraphComponent } from 'src/models/new/Component/MetricsComponent/metricsGraphComponent';
import { isComponentTypeMetricsTransitionGraph } from 'src/models/new/Component/MetricsComponent/GraphMetricsComponent/metricsTransitionGraphComponent';
import { isComponentTypeMetricsBarGraph } from 'src/models/new/Component/MetricsComponent/GraphMetricsComponent/metricsBarGraphComponent';
import { isComponentTypeMetricsGroupedGraph } from 'src/models/new/Component/MetricsComponent/GraphMetricsComponent/metricsGroupedGraphComponent';
import MetricsPieChartComponentView from 'src/views/Dashboard/ReportValues/Detail/components/SectionView/ComponentView/MetricsGraphComponent/MetricsPieChartComponentView.vue';
import MetricsBarGraphComponentView from 'src/views/Dashboard/ReportValues/Detail/components/SectionView/ComponentView/MetricsGraphComponent/MetricsBarGraphComponentView.vue';
import MetricsGroupedGraphComponentView from 'src/views/Dashboard/ReportValues/Detail/components/SectionView/ComponentView/MetricsGraphComponent/MetricsGroupedGraphComponentView.vue';
import MetricsTransitionGraphComponentView from 'src/views/Dashboard/ReportValues/Detail/components/SectionView/ComponentView/MetricsGraphComponent/MetricsTransitionGraphComponentView.vue';
import TooltipButton from 'src/components/UIComponents/Tooltip/TooltipButton.vue';
import { ComponentLink } from 'src/models/new/Link/componentLink';
import componentLinkApi from 'src/apis/componentLink';
import { TooltipMenuSettings } from 'src/components/UIComponents/Tooltip/TooltipContent/TooltipContent.vue';

type State = {
  title: string;
  tooltipId: string;
  componentLinks: ComponentLink[];
  componentLinkTooltipMenus: TooltipMenuSettings[];
};

export default defineComponent({
  components: {
    TooltipButton,
    MetricsPieChartComponentView,
    MetricsBarGraphComponentView,
    MetricsGroupedGraphComponentView,
    MetricsTransitionGraphComponentView,
  },
  props: {
    component: {
      type: Object as PropType<MetricsGraphComponent>,
      required: true,
    },
    reportId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const root = getCurrentInstance()!.proxy;
    const state: State = reactive({
      title: computed(() => props.component.name),
      tooltipId: '',
      componentLinks: [],
      componentLinkTooltipMenus: computed(() => {
        return state.componentLinks.map((el) => {
          return {
            text: el.name,
            link: {
              to: {
                path: el.url,
                query: { dt: root.$route.query.dt },
              },
              target: '_blank',
            },
          };
        });
      }),
    });

    const loadComponentLinks = async () => {
      state.componentLinks = (await componentLinkApi.index(props.reportId, props.component.id)).sort(
        (a, b) => a.sequentialOrder - b.sequentialOrder,
      );
    };

    const onLinkTooltipButtonClick = (): void => {
      loadComponentLinks();
    };

    onMounted(() => {
      state.tooltipId = `tooltip-${crypto.randomUUID()}`;
    });

    return {
      state,
      props,
      onLinkTooltipButtonClick,
      isComponentTypeMetricsPieChart,
      isComponentTypeMetricsBarGraph,
      isComponentTypeMetricsGroupedGraph,
      isComponentTypeMetricsTransitionGraph,
    };
  },
});
