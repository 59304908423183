
import { notifyError1 } from 'src/hooks/notificationHook';
import Draggable from 'vuedraggable';
import { vvGetError, vvGetErrorObject, vvHasError, vvReset, vvValidate } from 'src/util/vee_validate';
import { MapObject } from 'vee-validate';
import { requestTimeout } from 'src/util/request_animation_frame';
import MetricsSearchPanel, {
  MetricsSearchPanelMode,
  TimeSpanOption,
} from 'src/components/MetricsSearchPanel/index.vue';
import { Workplace } from 'src/models/new/workplace';
import { computed, defineComponent, getCurrentInstance, onMounted, PropType, reactive, ref, watch } from 'vue';
import PButton from 'src/components/UIComponents/Button.vue';
import metricsApi from 'src/apis/masters/metrics';
import metricsAccessGroupApi from 'src/apis/masters/metricsAccessGroup';
import reportAccessGroupApi from 'src/apis/masters/reportAccessGroup';
import { Pagination } from 'src/models/api/shared/paginationResponseData';
import {
  METRICS_TYPE_LOCAL_WORDS,
  METRICS_TYPES,
  Metrics,
  MetricsType,
  isMetricsReferBorderless,
  metricsToTypeLocalWord,
} from 'src/models/new/metrics';
import { MetricsIndexRequestParameters } from 'src/models/api/Metrics/metricsIndexRequestParameters';
import {
  TIME_SPAN_DAILY,
  TIME_SPAN_MONTHLY,
  TIME_SPAN_WEEKLY,
  TIME_SPAN_YEARLY,
  TimeSpan,
  timeSpanToLocalWord,
} from 'src/business/timeSpan';
import { AccessGroupIndexRequestParameters } from 'src/models/api/AccessGroup/accessGroupIndexRequestParameters';
import {
  getCalculatedMetricsReferableMetricsTypes,
  isMetricsCalculatedMetrics,
} from 'src/models/new/Metrics/calculatedMetrics';
import {
  availableTargetTimeSpanFromSummaryMetrics,
  getSummaryMetricsReferableMetricsTypes,
  isMetricsSummaryMetrics,
} from 'src/models/new/Metrics/summaryMetrics';
import {
  getBundledMetricsReferableMetricsTypes,
  isMetricsBundledBorderless,
  isMetricsBundledMetrics,
} from 'src/models/new/Metrics/bundledMetrics';
import {
  getReferenceMetricsReferableMetricsTypes,
  isMetricsReferenceMetrics,
} from 'src/models/new/Metrics/referenceMetrics';
import MetricsBadge from 'src/components/MetricsBadge.vue';
import MetricsTypeRoundPoint from 'src/components/MetricsTypeRoundPoint.vue';
import { STORED_PARAMETERS_PAGE_TEMPORARY, getStoredParameters, setStoredParameters } from 'src/util/storedParameters';
import InputWarn from 'src/components/InputWarn.vue';
import InputError from 'src/components/InputError.vue';
import { useWorkplaces } from 'src/composables/asyncResources/useWorkplaces';
import { usePaginationContainer } from 'src/components/UIComponents/PaginationContainer.vue';

type ItemBase = {
  id: number;
  name: string;
};

// 選択対象の項目に追加の属性を実装する場合、ここに加える
/** name: string */
const EXTRA_PROPERTY_NAME = 'name';
/** color: string */
const EXTRA_PROPERTY_COLOR = 'color';
/** order: number */
const EXTRA_PROPERTY_ORDER = 'order';
export type SelectItemFormExtraProperty =
  | typeof EXTRA_PROPERTY_NAME
  | typeof EXTRA_PROPERTY_COLOR
  | typeof EXTRA_PROPERTY_ORDER;
export type SelectItemFormExtraSyncProperty = typeof EXTRA_PROPERTY_NAME | typeof EXTRA_PROPERTY_COLOR;

/**
 * SelectItemFormItemはインターフェースであり、呼び出し元ではSelectItemFormItemの交差型を定義して
 * 実際に使用する追加プロパティを定義する
 * また、追加プロパティ毎の型はstring | numberとなっているが、これもマップを利用して適切な型をを定義する
 */
export type SelectItemFormItem = ItemBase & {
  xExtraProperties?: Partial<Record<SelectItemFormExtraProperty, string | number>>;
  // FIXME: メトリクスの場合のロジックで、期間が異なるメトリクスを混ぜて設定できないようにしている
  // そのためtimeSpanプロパティも必要になっている
  timeSpan?: TimeSpan;
};

type FetchResponse = {
  result: {
    id: number;
    // nameが存在しないモデルは現時点では考慮しない
    name: string;
  }[];
  pagination?: Pagination;
};

export type PartitionOption = {
  value: string;
  label: string;
};

const PARAMETER_KEY = 'metricsSelectItemForm';
type StoreParameters = {
  searchParams: object;
};

interface State {
  isReady: boolean;
  items: SelectItemFormItem[];
  selectedItems: SelectItemFormItem[];
  dataLoadState: { availableTotal: number; loadedSlices: number };
  total: number;
  validations: Record<string, object>;
  hasError: boolean;
  isProcessingResult: boolean;
  hasExtraNameProperty: boolean;
  isExtraNamePropertySync: boolean; // 名称を常に同じものにするかどうか
  hasExtraColorProperty: boolean;
  isExtraColorPropertySync: boolean; // 色を常に同じものにするかどうか
  hasExtraOrderProperty: boolean;
  hasExtraProperties: boolean;
  isAddingItemAllowed: boolean;
  isItemTypeMetrics: boolean;
  isItemTypeMetricsAccessGroup: boolean;
  isItemTypeReportAccessGroup: boolean;
  isItemTypeAccessGroup: boolean;
  itemTypeName: string;
  sectionColumnClasses: string[];

  // 現状はメトリクスのケースでしか検索パラメータを使用しておらず型をもっと限定できるが
  // モジュールの特性上メトリクスの検索APIに依存しないようobjectとした
  searchParams: object;
  // 検索実行時のsearchParams保持用に定義
  lastSearchParams: object;

  // 対象がメトリクスを想定、取得可能なメトリクスの周期を制限する
  timeSpans: TimeSpan[];
  initialSearchTimeSpan: TimeSpan;
  timeSpanOptions: TimeSpanOption[];
}

function getValidationMap(props: any, state: State): Record<string, object> {
  const validation = {};
  if (state.hasExtraNameProperty) {
    const nameValidations = props.extraPropertiesValidations.name ?? { required: false, max: 50 };
    Object.assign(validation, { name: nameValidations });
  }
  if (state.hasExtraColorProperty) {
    Object.assign(validation, { color: { required: true } });
  }
  return validation;
}

export const ITEM_TYPE_METRICS = 'metrics';
export const ITEM_TYPE_METRICS_ACCESS_GROUP = 'metrics_access_group';
export const ITEM_TYPE_REPORT_ACCESS_GROUP = 'report_access_group';

export default defineComponent({
  components: {
    PButton,
    Draggable,
    MetricsSearchPanel,
    MetricsBadge,
    MetricsTypeRoundPoint,
    InputWarn,
    InputError,
    PaginationContainer: usePaginationContainer<SelectItemFormItem>(),
  },
  props: {
    backToForward: {
      type: Function as PropType<() => void>,
      required: true,
    },
    onItemAdded: {
      type: Function as PropType<(items: SelectItemFormItem[]) => void>,
      default: () => {},
    },
    itemType: {
      type: String as PropType<
        typeof ITEM_TYPE_METRICS | typeof ITEM_TYPE_METRICS_ACCESS_GROUP | typeof ITEM_TYPE_REPORT_ACCESS_GROUP
      >,
      required: true,
    },
    selectedItems: {
      type: Array as PropType<SelectItemFormItem[]>,
      required: true,
    },
    enabledExtraProperties: {
      type: Array as PropType<SelectItemFormExtraProperty[]>,
      default: () => [] as SelectItemFormExtraProperty[],
    },
    syncedExtraProperties: {
      type: Array as PropType<SelectItemFormExtraSyncProperty[]>,
      default: () => [] as SelectItemFormExtraSyncProperty[],
    },
    extraPropertiesValidations: {
      type: Object as PropType<MapObject>,
      required: false,
      default: () => ({}),
    },
    fetchParams: {
      type: Object,
      required: false,
    },
    setItemsWithItems: {
      // 拡張した型を渡してもエラー表示が出てしまうのでanyにしている
      type: Function as PropType<(items: any[]) => Promise<void>>,
      required: true,
    },
    itemLength: {
      type: Number as PropType<number>,
      required: true,
    },
    btnText: {
      type: String as PropType<string>,
      required: false,
      default: '設定',
    },
    isDisabled: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    workplaceCandidates: {
      type: Array as PropType<Workplace[]>,
      required: false,
    },
    timeSpans: {
      type: Array as PropType<TimeSpan[]>,
      required: false,
    },
    metrics: {
      type: Object as PropType<Metrics>,
      required: false,
    },
    // NOTE: 元々グラフ上のメトリクス設定時に周期をチェックする用途で実装されたが、現在は使われていない
    // 拡張性を見込んで残しているが代替実装がなされたら削除して問題ない
    checkDisableRule: {
      type: Function as PropType<(item: SelectItemFormItem, items: SelectItemFormItem[]) => boolean>,
      default: () => false,
    },
    partitions: {
      type: Array as PropType<PartitionOption[]>,
      default: () => [] as PartitionOption[],
    },
    selectedPartition: {
      type: Object as PropType<PartitionOption | null>,
      default: null,
    },
    onPartitionSelect: {
      // 拡張した型を渡してもエラー表示が出てしまうのでanyにしている
      type: Function as PropType<(partition: any, items: any[]) => Promise<void>>,
      default: () => {},
    },
    computeDuplicatedIds: {
      type: Function as PropType<(items: any[]) => number[]>,
      default: () => [],
    },
  },
  setup(props) {
    const root = getCurrentInstance()!.proxy;
    const state: State = reactive({
      isReady: false,
      items: [],
      selectedItems: props.selectedItems,
      dataLoadState: { availableTotal: 0, loadedSlices: 0 },
      total: computed(() => {
        if (state.isItemTypeMetrics) {
          return state.dataLoadState.availableTotal;
        }
        return state.items.length;
      }),
      validations: computed(() => getValidationMap(props, state)),
      hasError: computed(() => vvHasError(root)),
      isProcessingResult: false,
      hasExtraNameProperty: computed(() => props.enabledExtraProperties.includes(EXTRA_PROPERTY_NAME)),
      isExtraNamePropertySync: computed(() => props.syncedExtraProperties.includes(EXTRA_PROPERTY_NAME)),
      hasExtraColorProperty: computed(() => props.enabledExtraProperties.includes(EXTRA_PROPERTY_COLOR)),
      isExtraColorPropertySync: computed(() => props.syncedExtraProperties.includes(EXTRA_PROPERTY_COLOR)),
      hasExtraOrderProperty: computed(() => props.enabledExtraProperties.includes(EXTRA_PROPERTY_ORDER)),
      hasExtraProperties: computed(() => props.enabledExtraProperties.length > 0),
      selectedPartition: null,
      isAddingItemAllowed: computed(() => state.selectedItems.length < props.itemLength),
      isItemTypeMetrics: computed(() => props.itemType === ITEM_TYPE_METRICS),
      isItemTypeMetricsAccessGroup: computed(() => props.itemType === ITEM_TYPE_METRICS_ACCESS_GROUP),
      isItemTypeReportAccessGroup: computed(() => props.itemType === ITEM_TYPE_REPORT_ACCESS_GROUP),
      isItemTypeAccessGroup: computed(() => state.isItemTypeMetricsAccessGroup || state.isItemTypeReportAccessGroup),
      itemTypeName: computed(() => {
        if (state.isItemTypeMetrics) {
          return 'メトリクス';
        }
        if (state.isItemTypeMetricsAccessGroup) {
          return 'メトリクスグループ';
        }
        if (state.isItemTypeReportAccessGroup) {
          return 'レポートグループ';
        }
        return '';
      }),
      sectionColumnClasses: computed(() => {
        if (state.isItemTypeAccessGroup) {
          return ['is-access-group'];
        }
        if (state.isItemTypeMetrics) {
          const classes = ['is-metrics'];
          if (state.hasExtraProperties) {
            classes.push('has-extra-properties');
          }
          return classes;
        }

        return [];
      }),

      timeSpans: computed(() => {
        const allTimeSpans: TimeSpan[] = [TIME_SPAN_DAILY, TIME_SPAN_WEEKLY, TIME_SPAN_MONTHLY, TIME_SPAN_YEARLY];

        if (props.timeSpans && props.timeSpans.length > 0) {
          // props.timeSpansを並べ替えずに使用するので注意
          return props.timeSpans;
        } else if (props.metrics) {
          return [props.metrics.timeSpan];
        } else {
          return allTimeSpans;
        }
      }),

      searchParams: {},
      lastSearchParams: {},

      initialSearchTimeSpan: computed(() => state.timeSpans[0]),
      timeSpanOptions: computed(() => {
        return state.timeSpans.map((timeSpan) => {
          return {
            value: timeSpan,
            label: timeSpanToLocalWord(timeSpan),
          };
        });
      }),
    });

    const dispColors = window.master.lovs.color_set.vals.filter((e) => e.group === 'dark');

    const getError = (fieldName: string): string | null => vvGetError(root, fieldName);
    const getErrorObject = (fieldName: string): object | null => vvGetErrorObject(root, fieldName);
    const clearErrors = (): void => {
      vvReset(root);
    };

    const overrideFetchParams = (params: Record<string, any>): Record<string, any> => {
      if (props.fetchParams) {
        Object.assign(params, props.fetchParams);
      }
      return params;
    };

    const fetchMetrics = async (searchParams: object, page: number): Promise<FetchResponse | null> => {
      const queryParams = {
        page: page,
        name: null as string | null,
        logiscope_workplace_id: null as number | null,
        // 周期が選択されてない場合は選択可能な周期を全て指定して検索する
        time_spans: 'timeSpan' in searchParams && searchParams.timeSpan ? [searchParams.timeSpan] : state.timeSpans,
      };
      if ('name' in searchParams) {
        queryParams.name = String(searchParams.name);
      }
      if ('workplaceId' in searchParams) {
        queryParams.logiscope_workplace_id = Number(searchParams.workplaceId);
      }

      // 参照可能なメトリクスタイプ
      const metricsTypes = getReferableMetricsTypes(props.metrics!);
      // 検索パラメータでメトリクスタイプが選択されている場合はそれを優先する
      if ('metricsType' in searchParams && searchParams.metricsType) {
        Object.assign(queryParams, { metrics_types: [searchParams.metricsType] });
      } else if (metricsTypes.length !== METRICS_TYPES.length) {
        Object.assign(queryParams, { metrics_types: metricsTypes });
      }

      try {
        return metricsApi.index(overrideFetchParams(queryParams) as MetricsIndexRequestParameters);
      } catch (err: any) {
        const errStatus = err.response.status;
        if (errStatus === 403) {
          // メトリクス閲覧権限グループに所属していない場合は403が返る(一つでも所属している場合は200)
          // ユーザーからの見え方としては、特に警告なくメトリクスがリストに1つも表示されない状態
        } else {
          const msg = 'メトリクス取得に失敗しました。管理者に連絡してください。';
          notifyError1(root, msg, { err });
        }
        return null;
      }
    };

    const fetchMetricsAccessGroups = async (searchParams: object): Promise<FetchResponse | null> => {
      const params: AccessGroupIndexRequestParameters = {};
      if ('name' in searchParams) {
        params.name = String(searchParams.name);
      }
      try {
        return await metricsAccessGroupApi.index(overrideFetchParams(params) as AccessGroupIndexRequestParameters);
      } catch (e) {
        return null;
      }
    };

    const fetchReportAccessGroups = async (searchParams: object): Promise<FetchResponse | null> => {
      const params: AccessGroupIndexRequestParameters = {};
      if ('name' in searchParams) {
        params.name = String(searchParams.name);
      }
      try {
        return await reportAccessGroupApi.index(overrideFetchParams(params) as AccessGroupIndexRequestParameters);
      } catch (e) {
        return null;
      }
    };

    const fetchList = async (searchParams: object): Promise<void> => {
      let response: FetchResponse | null = null;

      if (state.isItemTypeMetrics) {
        response = await fetchMetrics(searchParams, state.dataLoadState.loadedSlices + 1);
      } else if (state.isItemTypeMetricsAccessGroup) {
        response = await fetchMetricsAccessGroups(searchParams);
      } else if (state.isItemTypeReportAccessGroup) {
        response = await fetchReportAccessGroups(searchParams);
      }

      if (!response) {
        return;
      }

      state.dataLoadState.loadedSlices += 1;

      const items = response.result.map((el) => {
        if (props.enabledExtraProperties.length === 0) {
          return el;
        }
        return { ...el, xExtraProperties: {} };
      });

      if (state.dataLoadState.loadedSlices === 1) {
        state.items = items;
      } else {
        state.items = [...state.items, ...items];
      }

      if (response.pagination) {
        state.dataLoadState.availableTotal = response.pagination.total;
      }
    };

    const loadItems = async () => {
      state.dataLoadState.loadedSlices = 0;
      state.lastSearchParams = { ...state.searchParams };
      await fetchList(state.searchParams);
    };

    watch(
      () => [props.selectedPartition],
      async () => {
        // 検索条件のうち何かしらが選択可能なものから外れてしまっていた場合に検索条件を変更して検索し直す
        // 現状、パーティションを利用するケースで該当がtimeSpanだけなのでtimeSpanのみをチェックしているが
        // 機能拡張の際は関係する検索パラメータを精査して必要なら実装を追加する
        if (
          'timeSpan' in state.searchParams &&
          state.searchParams.timeSpan &&
          state.timeSpans.includes(state.searchParams.timeSpan as TimeSpan)
        ) {
          return;
        } else if ('timeSpan' in state.searchParams) {
          state.searchParams.timeSpan = state.initialSearchTimeSpan;
        }
        await loadItems();
      },
    );

    const syncName = (name: string): void => {
      state.selectedItems = [
        ...state.selectedItems.map((item) => {
          return { ...item, xExtraProperties: { ...item.xExtraProperties, name } };
        }),
      ];
    };
    const onNameChange = (name: string): void => {
      if (state.isExtraNamePropertySync) {
        syncName(name);
        if (state.hasError) {
          clearErrors();
          vvValidate(root);
        }
      }
    };

    const syncColor = (color: string): void => {
      state.selectedItems = [
        ...state.selectedItems.map((item) => {
          return { ...item, xExtraProperties: { ...item.xExtraProperties, color } };
        }),
      ];
    };
    const onColorChange = (color: string): void => {
      if (state.isExtraColorPropertySync) {
        syncColor(color);
        if (state.hasError) {
          clearErrors();
          vvValidate(root);
        }
      }
    };

    const getInitialColorOnSelect = (items: SelectItemFormItem[]): string => {
      // 色選択肢のうち、まだ選択されてない中で最も先頭側の色を探して返す.
      // 全て選択済みの場合は諦めてユーザーに選択してもらう.
      const selectedColorSet = new Set(items.map((item) => item.xExtraProperties?.color));
      for (const dispColor of dispColors) {
        if (!selectedColorSet.has(dispColor.key)) {
          return dispColor.key;
        }
      }
      return '';
    };

    const updateOrder = (): void => {
      state.selectedItems.forEach((item, i) => {
        item.xExtraProperties!.order = i + 1;
      });
      state.selectedItems = [...state.selectedItems];
    };

    const isSelectedItem = (item: SelectItemFormItem): boolean => state.selectedItems.some((el) => el.id === item.id);
    const isItemDisabled = (item: SelectItemFormItem): boolean => props.checkDisableRule(item, state.selectedItems);
    const selectItem = (item: SelectItemFormItem): void => {
      if (props.isDisabled || isSelectedItem(item) || isItemDisabled(item)) {
        return;
      }

      if (!state.isAddingItemAllowed) {
        notifyError1(root, `設定できる${state.itemTypeName}数は${props.itemLength}個までです。`);
        return;
      }

      state.selectedItems.push(item);

      if (state.hasExtraNameProperty) {
        item.xExtraProperties!.name = '';
      }
      if (state.hasExtraNameProperty && state.isExtraNamePropertySync && state.selectedItems.length > 0) {
        syncName(state.selectedItems[0].xExtraProperties!.name! as string);
      }
      if (state.hasExtraColorProperty) {
        item.xExtraProperties!.color = getInitialColorOnSelect(state.selectedItems);
      }
      if (state.hasExtraColorProperty && state.isExtraColorPropertySync && state.selectedItems.length > 0) {
        syncColor(state.selectedItems[0].xExtraProperties!.color! as string);
      }
      if (state.hasExtraOrderProperty) {
        updateOrder();
        // updateOrderは変数itemに依存しないためitemを更新する必要がないが
        // 以降で実行されるアイテム追加時のフック実行時のためにorderを設定する
        item.xExtraProperties!.order = state.selectedItems.length;
      }
      // アイテム追加時のフックが登録されている場合は実行
      props.onItemAdded(state.selectedItems);
    };

    const removeItem = (item: SelectItemFormItem): void => {
      state.selectedItems = [...state.selectedItems.filter((i) => i.id !== item.id)];

      // 順番のリセット
      if (state.hasExtraOrderProperty) {
        updateOrder();
      }
    };

    const selectPartition = (partition: PartitionOption): void => {
      props.onPartitionSelect(partition, state.selectedItems);
    };

    const setItems = async (): Promise<void> => {
      // 二重処理防止
      if (state.isProcessingResult) {
        return;
      }
      state.isProcessingResult = true;
      requestTimeout(() => {
        state.isProcessingResult = false;
      }, 300);

      if (!(await vvValidate(root))) {
        return;
      }

      await props.setItemsWithItems(state.selectedItems);
      clearErrors();
    };

    // --------------------------------- メトリクス関連 ---------------------------------
    type MetricsTypeOption = {
      value: MetricsType;
      label: string;
    };

    const { workplacesRef } = useWorkplaces();

    const METRICS_TYPE_OPTIONS: MetricsTypeOption[] = Object.keys(METRICS_TYPE_LOCAL_WORDS).map((key) => {
      return {
        value: key as MetricsType,
        label: METRICS_TYPE_LOCAL_WORDS[key as MetricsType],
      };
    });

    const getMetricsSearchPanelMode = (): MetricsSearchPanelMode | null => {
      if (state.isItemTypeAccessGroup) {
        return null;
      }
      if (!props.metrics) {
        return 'mode2';
      }
      if (isMetricsBundledMetrics(props.metrics) && isMetricsBundledBorderless(props.metrics)) {
        return 'mode1';
      }
      if (isMetricsBundledMetrics(props.metrics) && !isMetricsBundledBorderless(props.metrics)) {
        return 'mode2';
      }
      if (isMetricsReferenceMetrics(props.metrics)) {
        return 'mode2';
      }
      if (isMetricsCalculatedMetrics(props.metrics)) {
        return 'mode2';
      }
      if (isMetricsSummaryMetrics(props.metrics)) {
        return 'mode2';
      }
      return null;
    };

    const getReferableMetricsTypes = (metrics: Metrics): MetricsType[] => {
      if (!metrics) {
        return [];
      }
      if (isMetricsCalculatedMetrics(metrics)) {
        return getCalculatedMetricsReferableMetricsTypes();
      }
      if (isMetricsBundledMetrics(metrics)) {
        return getBundledMetricsReferableMetricsTypes(metrics.isBorderless);
      }
      if (isMetricsSummaryMetrics(metrics)) {
        return getSummaryMetricsReferableMetricsTypes();
      }
      if (isMetricsReferenceMetrics(metrics)) {
        return getReferenceMetricsReferableMetricsTypes();
      }
      return [];
    };

    const getMetricsTypeOptions = (): { value: MetricsType; label: string }[] => {
      const metrics = props.metrics;
      if (!metrics) {
        return METRICS_TYPE_OPTIONS;
      }

      const referableMetricsTypes = getReferableMetricsTypes(metrics);
      return METRICS_TYPE_OPTIONS.filter((v) => referableMetricsTypes.includes(v.value));
    };

    const getWorkplaceOptionsCandidates = (): Workplace[] => {
      // FIXME: props.workplaceCandidates ?? workplacesRef.valueのロジックは他でも使う
      // 全体的にメトリクスが登場する場合のロジックを分離したいが、その際はうまい共通化を考える
      const workplaces = props.workplaceCandidates ?? workplacesRef.value;
      const metrics = props.metrics;
      if (!metrics) {
        return workplaces;
      }

      return isMetricsReferBorderless(metrics) ? workplaces : workplaces.filter((v) => v.id === metrics.workplaceId);
    };

    const metricsSearchPanelMode = ref<MetricsSearchPanelMode | null>(getMetricsSearchPanelMode());
    const filteredMetricsTypes = computed((): { value: MetricsType; label: string }[] => getMetricsTypeOptions());
    const filteredWorkplaceOptionsCandidates = computed((): Workplace[] => getWorkplaceOptionsCandidates());

    const onSearch = async () => {
      await loadItems();

      if (state.isItemTypeMetrics) {
        const storeParameters: StoreParameters = { searchParams: state.searchParams };
        setStoredParameters(root, storeParameters, STORED_PARAMETERS_PAGE_TEMPORARY, PARAMETER_KEY);
      }
    };

    const ITEM_PER_PAGE = 50;

    /**
     * 次のバッチをロードする関数。
     * アクセスグループの場合はページングがないため、何もしない。
     */
    const onLazyLoadRequired = async () => {
      if (!state.isItemTypeMetrics) {
        return;
      }
      await fetchList(state.lastSearchParams);
    };

    const initializeMetricsSearchParams = (): void => {
      props.metrics
        ? initializeMetricsSearchParamsWithMetrics(props.metrics)
        : initializeMetricsSearchParamsWithoutMetrics();
    };

    const initializeMetricsSearchParamsWithMetrics = (metrics: Metrics) => {
      if (loadStoredParameters()) {
        return;
      }

      const workplaceId = metrics.workplaceId;
      const timeSpan = metrics.timeSpan;
      if (isMetricsBundledMetrics(metrics) && isMetricsBundledBorderless(metrics)) {
        state.searchParams = { metricsType: filteredMetricsTypes.value[0].value, timeSpan };
      } else if (isMetricsBundledMetrics(metrics)) {
        state.searchParams = { workplaceId, timeSpan };
      } else if (isMetricsReferenceMetrics(metrics)) {
        state.searchParams = { workplaceId, timeSpan };
      } else if (isMetricsCalculatedMetrics(metrics)) {
        state.searchParams = { workplaceId, timeSpan };
      } else if (isMetricsSummaryMetrics(metrics)) {
        state.searchParams = { workplaceId, timeSpan: availableTargetTimeSpanFromSummaryMetrics(metrics) };
      } else {
        state.searchParams = {
          workplaceId: filteredWorkplaceOptionsCandidates.value[0]?.id,
          timeSpan: state.initialSearchTimeSpan,
        };
      }
    };

    const initializeMetricsSearchParamsWithoutMetrics = () => {
      if (loadStoredParameters()) {
        return;
      }

      state.searchParams = {
        workplaceId: filteredWorkplaceOptionsCandidates.value[0]?.id,
        timeSpan: state.initialSearchTimeSpan,
      };
    };

    const loadStoredParameters = (): boolean => {
      const storedParameters = getStoredParameters<StoreParameters>(
        root,
        STORED_PARAMETERS_PAGE_TEMPORARY,
        PARAMETER_KEY,
      );
      if (storedParameters.searchParams) {
        if (isValidStoreParameter(storedParameters)) {
          state.searchParams = storedParameters.searchParams;
          return true;
        } else {
          setStoredParameters(root, {}, STORED_PARAMETERS_PAGE_TEMPORARY, PARAMETER_KEY);
        }
      }
      return false;
    };

    const isValidStoreParameter = (storedParameters: StoreParameters): boolean => {
      const searchParams = storedParameters.searchParams;
      if ('workplaceId' in searchParams && searchParams.workplaceId) {
        const workplaceOptions = filteredWorkplaceOptionsCandidates.value.map((workplace) => workplace.id);
        if (!workplaceOptions.includes(Number(searchParams.workplaceId))) {
          return false;
        }
      }
      if ('metricsType' in searchParams && searchParams.metricsType) {
        const metricsTypeOptions = filteredMetricsTypes.value.map((metricsType) => metricsType.value);
        if (!metricsTypeOptions.includes(searchParams.metricsType as MetricsType)) {
          return false;
        }
      }
      if ('timeSpan' in searchParams && searchParams.timeSpan) {
        const timeSpanOptions = state.timeSpanOptions.map((timeSpan) => timeSpan.value);
        if (!timeSpanOptions.includes(searchParams.timeSpan as TimeSpan)) {
          return false;
        }
      }
      return true;
    };

    // テンプレートからアイテムごとに呼び出す方式を取る
    // SelectItemFormのアイテムがメトリクスの型を持っていないので
    // 算出プロパティとしてWorkplaceNameを埋め込んだ形に変換するわけにはいかない為
    const getWorkplaceName = (metrics: Metrics): string => {
      // FIXME: props.workplaceCandidates ?? workplacesRef.valueのロジックは他でも使う
      // 全体的にメトリクスが登場する場合のロジックを分離したいが、その際はうまい共通化を考える
      const workplaces = props.workplaceCandidates ?? workplacesRef.value;
      return workplaces.find((v) => v.id === metrics.workplaceId)?.name || '';
    };
    // --------------------------------- メトリクス関連 ---------------------------------

    onMounted(async () => {
      // Vue 2x 暫定措置 3x系の場合はonUnmountedでフラグを戻す
      // Vue 2x ではonUnmountedがdestroyedに対するフックのエイリアスであるためonMountedの先頭に記述している
      state.isReady = false;
      // グラフコンポーネントの初期化
      // メトリクスの場合はこの時点で検索しないで、MetricsSearchPanelのsearchをトリガーとする
      if (state.isItemTypeMetrics) {
        initializeMetricsSearchParams();
      }
      await loadItems();

      state.selectedItems = props.selectedItems;

      state.isReady = true;
    });

    watch(
      () => props.selectedItems,
      (values) => {
        state.selectedItems = values;
      },
    );

    return {
      props,
      state,
      getError,
      getErrorObject,
      selectItem,
      removeItem,
      setItems,
      isSelectedItem,
      updateOrder,
      onSearch,
      ITEM_PER_PAGE,
      onLazyLoadRequired,
      metricsSearchPanelMode,
      filteredMetricsTypes,
      filteredWorkplaceOptionsCandidates,
      isItemDisabled,
      dispColors,
      onNameChange,
      onColorChange,
      getWorkplaceName,
      timeSpanToLocalWord,
      selectPartition,
      metricsToTypeLocalWord,
    };
  },
});
