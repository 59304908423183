import { Comparator } from 'src/util/comparator'
import { Metrics } from './metrics'
import { BackgroundDecoration, DECORATION_TARGET_BACKGROUND } from './ConditionalStatement/Decorations/backgroundDecoration'
import { DECORATION_TARGET_TEXT, TextDecoration } from './ConditionalStatement/Decorations/textDecoration'
import { DECORATION_TARGET_ICON, IconDecoration } from './ConditionalStatement/Decorations/iconDecoration'
import { DECORATION_TARGET_GRAPH, GraphDecoration } from './ConditionalStatement/Decorations/graphDecoration'

export const CONDITIONAL_STATEMENT_COMPARABLE_SELF = 'self'
export const CONDITIONAL_STATEMENT_COMPARABLE_REFERENCE = 'reference'

export type ConditionalStatement<DT> = {
  id: number
  base: number | Metrics | typeof CONDITIONAL_STATEMENT_COMPARABLE_SELF | typeof CONDITIONAL_STATEMENT_COMPARABLE_REFERENCE
  threshold: number | Metrics | typeof CONDITIONAL_STATEMENT_COMPARABLE_REFERENCE | null
  comparator: Comparator
  decoration: DT
  // 優先度: 値が大きいほど優先度が高くスタイルの適用箇所が重複している場合優先度の高いスタイルのみ適用される
  priority: number
  // 閲覧用にロードされた場合に配列になり、baseかthresholdがOTHERのとき値が入る
  metricsList: Metrics[] | null
}

export type Decoration = BackgroundDecoration | TextDecoration | IconDecoration | GraphDecoration

export const isConditionalStatementComparatorIsNull = (conditionalStatement: ConditionalStatement<Decoration>): boolean => {
  // FIXME: 暫定対応、Comparatorで定義して使う方がベター
  return conditionalStatement.comparator === 'is_null'
}

export const isConditionalStatementBaseSelf = (conditionalStatement: ConditionalStatement<Decoration>): boolean => {
  return typeof conditionalStatement.base === 'string' &&
    conditionalStatement.base === CONDITIONAL_STATEMENT_COMPARABLE_SELF
}
export const isConditionalStatementBaseReference = (conditionalStatement: ConditionalStatement<Decoration>): boolean => {
  return typeof conditionalStatement.base === 'string' &&
    conditionalStatement.base === CONDITIONAL_STATEMENT_COMPARABLE_REFERENCE
}
export const isConditionalStatementBaseConstant = (conditionalStatement: ConditionalStatement<Decoration>): boolean => {
  return typeof conditionalStatement.base === 'number'
}
export const isConditionalStatementBaseMetrics = (conditionalStatement: ConditionalStatement<Decoration>): boolean => {
  return !isConditionalStatementBaseConstant(conditionalStatement) &&
    !isConditionalStatementBaseSelf(conditionalStatement) &&
    !isConditionalStatementBaseReference(conditionalStatement)
}
export const isConditionalStatementThresholdReference = (conditionalStatement: ConditionalStatement<Decoration>): boolean => {
  return typeof conditionalStatement.threshold === 'string' &&
    conditionalStatement.threshold === CONDITIONAL_STATEMENT_COMPARABLE_REFERENCE
}
export const isConditionalStatementThresholdConstant = (conditionalStatement: ConditionalStatement<Decoration>): boolean => {
  return typeof conditionalStatement.threshold === 'number'
}
export const isConditionalStatementThresholdMetrics = (conditionalStatement: ConditionalStatement<Decoration>): boolean => {
  return !isConditionalStatementThresholdConstant(conditionalStatement) &&
    !isConditionalStatementThresholdReference(conditionalStatement) &&
    conditionalStatement.threshold !== null
}
export const baseValueFromConditionalStatement = (
  conditionalStatement: ConditionalStatement<Decoration>,
  // 推移コンポーネントの条件付き書式で他のメトリクスを参照する場合のみclosingDateを指定する
  // 指定がない場合は最も新しい日付のメトリクスの値を返す
  closingDate: Date | null = null,
): number | null => {
  if (isConditionalStatementBaseConstant(conditionalStatement)) return conditionalStatement.base as number
  // 閲覧用にロードされている場合はmetricsListとその中のmetricsにclosingDateが存在する
  // 編集用にロードされたか、新しいオブジェクトとして作成された場合はmetricsListがnullである
  // また、metricsListは名称の通り複数のメトリクスを持ちうる変数だが、非推移コンポーネントの場合
  // 同じidの値入りメトリクスが必ず1つであり、推移コンポーネントの場合は複数入っている
  if (isConditionalStatementBaseMetrics(conditionalStatement)) {
    const sampleMetrics = conditionalStatement.base as Metrics
    if (closingDate) {
      return conditionalStatement.metricsList
        ?.find(metrics => {
          return metrics.closingDate?.valueOf() === closingDate.valueOf() &&
            metrics.id === sampleMetrics.id
        })?.value ?? null
    }
    const lastMetrics = conditionalStatement.metricsList?.reduce((last, metrics) => {
      return (!last || metrics.closingDate! > last.closingDate!) &&
        metrics.id === sampleMetrics.id
        ? metrics
        : last
    }, null as Metrics | null)
    return lastMetrics?.value ?? null
  }
  console.error('Invalid base type. Or use own metrics value if refer self or reference.')
  return null
}
export const thresholdValueFromConditionalStatement = (
  conditionalStatement: ConditionalStatement<Decoration>,
  // 推移コンポーネントの条件付き書式で他のメトリクスを参照する場合のみclosingDateを指定する
  // 指定がない場合は最も新しい日付のメトリクスの値を返す
  closingDate: Date | null = null,
): number | null => {
  if (isConditionalStatementThresholdConstant(conditionalStatement)) return conditionalStatement.threshold as number
  if (isConditionalStatementThresholdMetrics(conditionalStatement)) {
    const sampleMetrics = conditionalStatement.threshold as Metrics
    if (closingDate) {
      return conditionalStatement.metricsList
        ?.find(metrics => {
          return metrics.closingDate?.valueOf() === closingDate.valueOf() &&
            metrics.id === sampleMetrics.id
        })?.value ?? null
    }
    const lastMetrics = conditionalStatement.metricsList?.reduce((last, metrics) => {
      return (!last || metrics.closingDate! > last.closingDate!) &&
        metrics.id === sampleMetrics.id
        ? metrics
        : last
    }, null as Metrics | null)
    return lastMetrics?.value ?? null
  }
  console.error('Invalid base type. Or use own metrics value if refer reference.')
  return null
}

type DecorationTarget = typeof DECORATION_TARGET_BACKGROUND | typeof DECORATION_TARGET_TEXT |
  typeof DECORATION_TARGET_ICON | typeof DECORATION_TARGET_GRAPH

const decorationTargetLabels: Record<DecorationTarget, string> = {
  [DECORATION_TARGET_BACKGROUND]: '背景',
  [DECORATION_TARGET_TEXT]: 'テキスト',
  [DECORATION_TARGET_ICON]: 'アイコン',
  [DECORATION_TARGET_GRAPH]: 'グラフ',
}

export const ConditionalStatementToTargetLabel = (conditionalStatement: ConditionalStatement<Decoration>): string => {
  return decorationTargetLabels[conditionalStatement.decoration.target]
}

export const isConditionalStatementTargetBackground = (
  conditionalStatement: ConditionalStatement<Decoration>,
): conditionalStatement is ConditionalStatement<BackgroundDecoration> => {
  return conditionalStatement.decoration.target === DECORATION_TARGET_BACKGROUND
}
export const isConditionalStatementTargetText = (
  conditionalStatement: ConditionalStatement<Decoration>,
): conditionalStatement is ConditionalStatement<TextDecoration> => {
  return conditionalStatement.decoration.target === DECORATION_TARGET_TEXT
}
export const isConditionalStatementTargetIcon = (
  conditionalStatement: ConditionalStatement<Decoration>,
): conditionalStatement is ConditionalStatement<IconDecoration> => {
  return conditionalStatement.decoration.target === DECORATION_TARGET_ICON
}
export const isConditionalStatementTargetGraph = (
  conditionalStatement: ConditionalStatement<Decoration>,
): conditionalStatement is ConditionalStatement<IconDecoration> => {
  return conditionalStatement.decoration.target === DECORATION_TARGET_GRAPH
}

// 条件付き書式をコンポーネントと独立で操作する場合にコンポーネント内の位置を特定するための識別子
// コンポーネントのタイプに関わらずprimaryとsecondaryで表現できるが、それぞれの意味はコンポーネントのタイプによって異なる
export type ContainerPositionIdentifier = {
  primaryPositionId: number
  secondaryPositionId: number
}
export type ConditionalStatementWithContainerPosition<DT> = ConditionalStatement<DT> & ContainerPositionIdentifier

export const constructEmptyConditionalStatementWithContainerPosition = (): ConditionalStatementWithContainerPosition<Decoration> => {
  return {
    id: 0,
    primaryPositionId: 1,
    secondaryPositionId: 1,
    base: CONDITIONAL_STATEMENT_COMPARABLE_SELF,
    threshold: CONDITIONAL_STATEMENT_COMPARABLE_REFERENCE,
    // FIXME: 暫定対応、Comparatorで定義して使う方がベター
    comparator: 'gt',
    decoration: {
      target: DECORATION_TARGET_BACKGROUND,
      settings: {
        color: '3b3838',
      },
    },
    // 優先度: 値が大きいほど優先度が高くスタイルの適用箇所が重複している場合優先度の高いスタイルのみ適用される
    priority: 1,
    metricsList: null,
  }
}
