
import { PropType, computed, defineComponent, reactive, getCurrentInstance } from 'vue';
import {
  MetricsTableComponent,
  applyConditionalStatementToMetricsTableComponentTextStyle,
  applyConditionalStatementToMetricsTableComponentBackgroundStyle,
  applyConditionalStatementToMetricsTableComponentIconClass,
  applyConditionalStatementToMetricsTableComponentIconStyle,
} from 'src/models/new/Component/MetricsComponent/metricsTableComponent';
import { formatMetricsValue, Metrics } from 'src/models/new/metrics';
import DataTable, {
  DataTableMatrixCell,
} from 'src/views/Dashboard/ReportValues/Detail/components/SectionView/ComponentView/DataTable.vue';
import { isMetricsDirectInputMetrics } from 'src/models/new/Metrics/BasicMetrics/directInputMetrics';
import { formatDate } from 'src/util/Datetime/parse';
import { isMetricsSummaryMetrics } from 'src/models/new/Metrics/summaryMetrics';
import { isMetricsCalculatedMetrics } from 'src/models/new/Metrics/calculatedMetrics';
import { isMetricsReferenceMetrics } from 'src/models/new/Metrics/referenceMetrics';
import { isMetricsBundledMetrics } from 'src/models/new/Metrics/bundledMetrics';
import TooltipButton from 'src/components/UIComponents/Tooltip/TooltipButton.vue';
import { ComponentLink } from 'src/models/new/Link/componentLink';
import componentLinkApi from 'src/apis/componentLink';
import { TooltipMenuSettings } from 'src/components/UIComponents/Tooltip/TooltipContent/TooltipContent.vue';

type State = {
  contents: DataTableMatrixCell[];
  componentLinks: ComponentLink[];
  componentLinkTooltipMenus: TooltipMenuSettings[];
};

export default defineComponent({
  components: {
    TooltipButton,
    DataTable,
  },
  props: {
    component: {
      type: Object as PropType<MetricsTableComponent>,
      required: true,
    },
    reportId: {
      type: Number,
      required: true,
    },
    edit: {
      type: Function as PropType<(metrics: Metrics, editValueLabel: string) => void>,
      required: true,
    },
  },
  setup(props) {
    const root = getCurrentInstance()!.proxy;
    const state: State = reactive({
      contents: computed(() => {
        const component = props.component;
        return component.data.map((el) => {
          const textStyle = applyConditionalStatementToMetricsTableComponentTextStyle(component, el.row, el.column);
          const backgroundStyle = applyConditionalStatementToMetricsTableComponentBackgroundStyle(
            component,
            el.row,
            el.column,
          );
          return {
            value: formatMetricsValue(el.metrics),
            unit: component.isUnitIndividualDisplayed ? el.metrics.unit : null,
            row: el.row,
            column: el.column,
            cellStyle: { ...textStyle, ...backgroundStyle },
            iconClass: applyConditionalStatementToMetricsTableComponentIconClass(component, el.row, el.column),
            iconStyle: applyConditionalStatementToMetricsTableComponentIconStyle(component, el.row, el.column),
            isClickable: !!el.metrics,
            isEditable: isMetricsDirectInputMetrics(el.metrics),
            isBreakdownAccessible: isBreakdownAccessible(el.metrics),
          };
        });
      }),
      componentLinks: [],
      componentLinkTooltipMenus: computed(() => {
        return state.componentLinks.map((el) => {
          return {
            text: el.name,
            link: {
              to: {
                path: el.url,
                query: { dt: root.$route.query.dt },
              },
              target: '_blank',
            },
          };
        });
      }),
    });

    const loadComponentLinks = async () => {
      state.componentLinks = (await componentLinkApi.index(props.reportId, props.component.id)).sort(
        (a, b) => a.sequentialOrder - b.sequentialOrder,
      );
    };

    const onLinkTooltipButtonClick = (): void => {
      loadComponentLinks();
    };

    const findMetrics = (row: number, column: number): Metrics | null => {
      const targetData = props.component.data.find((el) => {
        return el.row === row && el.column === column;
      });
      if (!targetData) {
        return null;
      }

      return targetData.metrics;
    };

    const onMetricsEditButtonClicked = (row: number, column: number): void => {
      const targetMetrics = findMetrics(row, column);
      if (!targetMetrics) {
        throw Error('Metrics is required.');
      }
      if (!isMetricsDirectInputMetrics(targetMetrics)) {
        throw Error('Metrics type is not direct_input.');
      }

      const label = `${row}行${column}列目`;
      props.edit(targetMetrics, label);
    };

    const onBreakdownButtonClicked = (row: number, column: number): void => {
      const targetMetrics = findMetrics(row, column);
      if (!targetMetrics) {
        throw Error('Metrics is required.');
      }
      if (!targetMetrics.closingDate) {
        throw Error('Metrics closingDate is required.');
      }

      root.$router.push({
        name: 'MetricsBreakdown',
        params: {
          metricsId: String(targetMetrics.id),
        },
        query: { dt: formatDate(targetMetrics.closingDate, 'yyyy-MM-dd') },
      });
    };

    const isBreakdownAccessible = (metrics: Metrics): boolean => {
      return (
        isMetricsSummaryMetrics(metrics) ||
        isMetricsCalculatedMetrics(metrics) ||
        isMetricsReferenceMetrics(metrics) ||
        isMetricsBundledMetrics(metrics)
      );
    };

    return {
      state,
      props,
      onLinkTooltipButtonClick,
      findMetrics,
      onMetricsEditButtonClicked,
      onBreakdownButtonClicked,
    };
  },
});
