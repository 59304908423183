
import Vue, { computed, defineComponent, getCurrentInstance, onMounted, provide, reactive } from 'vue'
import Draggable from 'vuedraggable'
import { setPageName } from 'src/hooks/displayPageNameHook'
import { ensureUserRefreshAndMasters } from 'src/hooks/masterHook'
import ConditionalStatementFormModal from 'src/views/Dashboard/Settings/Reports/Components/ConditionalStatements/components/ConditionalStatementFormModal.vue'
import ConditionalStatementCopyModal from 'src/views/Dashboard/Settings/Reports/Components/ConditionalStatements/components/ConditionalStatementCopyModal.vue'
import { Component } from 'src/models/new/component'
import { constructEmptyMetricsCardComponent, isComponentTypeMetricsCard } from 'src/models/new/Component/MetricsComponent/metricsCardComponent'
import { ConditionalStatementContainer, getContainersFromComponent } from 'src/views/Dashboard/Settings/Reports/Components/ConditionalStatements/logics/ConditionalStatementContainer'
import AppLink from 'src/components/UIComponents/AppLink.vue'
import conditionalStatementApi from 'src/apis/conditionalStatement'
import workplaceApi from 'src/apis/masters/workplace'
import {
  ConditionalStatementWithContainerPosition,
  constructEmptyConditionalStatementWithContainerPosition,
} from 'src/models/new/conditionalStatement'
import { getTemporaryRecordId } from 'src/util/recordId'
import { notifyError1, notifySuccess1 } from 'src/hooks/notificationHook'
import { ERROR_GROUP_SYSTEM, ERROR_GROUP_USER, ErrorGroup } from 'src/consts'
import { omitString } from 'src/util/text_decorator'
import { Workplace } from 'src/models/new/workplace'
import { Metrics } from 'src/models/new/metrics'
import { wrappedMapGetters } from 'src/hooks/storeHook'
import { timeSpanToLocalWord } from 'src/business/timeSpan'
import ConditionalStatementListRow from 'src/views/Dashboard/Settings/Reports/Components/ConditionalStatements/components/ConditionalStatementListRow.vue'
import ConditionalStatementDeleteModal from './components/ConditionalStatementDeleteModal.vue'
import { isComponentTypeMetricsTable } from 'src/models/new/Component/MetricsComponent/metricsTableComponent'
import { isComponentTypeMetricsList } from 'src/models/new/Component/MetricsComponent/metricsListComponent'
import { isComponentTypeMetricsPieChart } from 'src/models/new/Component/MetricsComponent/GraphMetricsComponent/metricsPieChartComponent'
import { isComponentTypeMetricsGraph } from 'src/models/new/Component/MetricsComponent/metricsGraphComponent'
import { DECORATION_TARGET_TEXT } from 'src/models/new/ConditionalStatement/Decorations/textDecoration'
import { DECORATION_TARGET_GRAPH } from 'src/models/new/ConditionalStatement/Decorations/graphDecoration'
import { LoginUser } from 'src/models/new/User/loginUser'

type CheckboxState = {
  id: number
  checked: boolean
}

interface State {
  userId: number
  pageName: string
  isLoaded: boolean
  hasReportGtManagerRole: boolean

  component: Component
  containers: ConditionalStatementContainer[]
  conditionalStatements: ConditionalStatementWithContainerPosition<any>[]
  conditionalStatementsInSelectedContainer: ConditionalStatementWithContainerPosition<any>[]

  conditionMetrics: Metrics[]
  workplaces: Workplace[]

  showFormModal: boolean
  showDeleteModal: boolean
  showCopyModal: boolean

  selectedContainer: ConditionalStatementContainer | null
  selectedConditionalStatement: ConditionalStatementWithContainerPosition<any> | null
  selectedContainerDetailLabelElements: {
    position: string
    mainName: string
    workplace?: string
    timeSpan?: string
  }

  checkboxStates: CheckboxState[]
  isAnythingChecked: boolean
  checkedConditionalStatements: ConditionalStatementWithContainerPosition<any>[]

  availableContainersForCopy: ConditionalStatementContainer[]

  urlParams: {
    reportId: number
    componentId: number
  }
}

const setupState = (root: Vue): State => {
  const state: State = reactive({
    ...wrappedMapGetters(root.$store, 'displayPageName', [
      'pageName',
    ]),
    userId: computed(() => (root.$store.getters['loginUser/data'] as LoginUser).id),
    isLoaded: false,
    // 廃止予定、レポートの単体取得APIのマージ次第ロジックを変更する
    hasReportGtManagerRole: computed(() => root.$store.getters['user/hasReportGtManagerRole']),

    // 対象データのロードが完了するまで初期値としてダミーコンポーネントを代入する
    component: constructEmptyMetricsCardComponent(),
    containers: computed(() => getContainersFromComponent(state.component)),
    conditionalStatements: [],
    // vue-draggableと連動させるため、算出プロパティを用いることができない
    conditionalStatementsInSelectedContainer: [],

    // conditionalStatementsに含まれるメトリクス
    // 表示の際に集計センター名を表示する為に使用する
    conditionMetrics: [],
    // メトリクスの集計センター名を表示する為に使用する
    workplaces: [],

    showFormModal: false,
    showDeleteModal: false,
    showCopyModal: false,

    selectedContainer: null,
    selectedConditionalStatement: null,
    selectedContainerDetailLabelElements: computed(() => {
      const container = state.selectedContainer
      if (!container) return { position: '', mainName: '' }

      const metrics = container.metrics
      const workplace = state.workplaces.find(e => e.id === container.metrics.workplaceId)

      const elements = {
        position: container.label.position,
        mainName: container.label.name,
      }

      if (container.label.name === metrics.name) {
        Object.assign(elements, {
          workplace: workplace?.name,
          timeSpan: timeSpanToLocalWord(metrics.timeSpan),
        })
      }

      return elements
    }),

    checkboxStates: [],
    isAnythingChecked: computed(() => state.checkboxStates.some(e => e.checked)),
    checkedConditionalStatements: computed(() => {
      return state.conditionalStatementsInSelectedContainer.filter(el => {
        return state.checkboxStates.find(checkbox => checkbox.id === el.id)?.checked
      })
    }),

    availableContainersForCopy: computed(() => {
      if (!isComponentTypeMetricsList(state.component)) return state.containers
      // リストコンポーネントの場合のみ、設置主箇所主メトリクスの条件付き書式は主メトリクスに、
      // 設置主箇所参考値の条件付き書式は参考値にのみコピーできる
      return state.containers.filter(container => {
        return container.secondaryPositionId === state.selectedContainer?.secondaryPositionId
      })
    }),

    urlParams: computed(() => {
      return {
        reportId: Number(root.$route.params.reportId),
        componentId: Number(root.$route.params.componentId),
      }
    }),
  })
  return state
}

export default defineComponent({
  components: {
    AppLink,
    Draggable,
    ConditionalStatementListRow,
    ConditionalStatementFormModal,
    ConditionalStatementDeleteModal,
    ConditionalStatementCopyModal,
  },
  setup() {
    const root = getCurrentInstance()!.proxy
    const state = setupState(root)

    setPageName(root, 'レポート編集')

    provide('injectWorkplaces', () => state.workplaces)

    const redirectIfNotAuthorized = (): void => {
      if (!state.hasReportGtManagerRole) {
        location.href = '/settings/reports'
      }
    }

    const loadConditionalStatements = async(): Promise<void> => {
      state.conditionalStatements = await conditionalStatementApi.index(
        state.urlParams.reportId,
        state.urlParams.componentId,
      )
    }

    // TODO: コンポーネント単体取得APIがマージされ次第こちらの関数を経由してコンポーネントを取得する
    // だったが、開発予定が無くなったので当面はWebストレージ経由でコンポーネントを取得する
    // const loadComponent = async(): Promise<void> => {
    //   state.component = await componentApi.show(state.urlParams.componentId)
    // }

    const loadWorkplaces = async(): Promise<void> => {
      state.workplaces = await workplaceApi.index()
    }

    const selectContainer = (index: number) => {
      state.selectedContainer = state.containers[index]

      if (!state.selectedContainer) return

      initializeContainer()
    }

    const initializeContainer = () => {
      state.conditionalStatementsInSelectedContainer = state.conditionalStatements.filter(e => {
        return e.primaryPositionId === state.selectedContainer?.primaryPositionId &&
          e.secondaryPositionId === state.selectedContainer?.secondaryPositionId
      })

      state.checkboxStates = state.conditionalStatementsInSelectedContainer.map(el => {
        return {
          id: el.id,
          checked: false,
        }
      })
    }

    const onCheckboxChange = (conditionalStatement: ConditionalStatementWithContainerPosition<any>, checked: boolean) => {
      state.checkboxStates = state.checkboxStates.map(el => {
        if (el.id === conditionalStatement.id) return { ...el, checked }
        return el
      })
    }

    const openFormModal = () => {
      state.showFormModal = true
    }

    const closeFormModal = () => {
      state.showFormModal = false
    }

    const createNewConditionalStatement = () => {
      if (!state.selectedContainer) return
      if (state.conditionalStatementsInSelectedContainer.length >= 6) {
        notifyError1(root, '条件付き書式の登録は設置位置ごとに6つまでです。')
        return
      }

      const defaultConditionalStatement = constructEmptyConditionalStatementWithContainerPosition()
      defaultConditionalStatement.id = getTemporaryRecordId()
      defaultConditionalStatement.primaryPositionId = state.selectedContainer.primaryPositionId
      defaultConditionalStatement.secondaryPositionId = state.selectedContainer.secondaryPositionId
      const defaultColor = window.master.lovs.color_set.vals.filter(e => e.group === 'dark')[0]?.value ?? '3b3838'
      defaultConditionalStatement.decoration = {
        target: DECORATION_TARGET_TEXT,
        settings: { color: defaultColor, fontWeight: 'normal' },
      }

      if (isComponentTypeMetricsCard(state.component)) {
        //
      } else if (isComponentTypeMetricsTable(state.component)) {
        // メトリクス表コンポーネントは参考値が存在しないので、境界値の初期値を定数に変更する
        defaultConditionalStatement.threshold = 0
      } else if (isComponentTypeMetricsList(state.component)) {
        if (state.selectedContainer.secondaryPositionId !== 1) {
          // 参考値の場合、初期値を定数に変更する
          defaultConditionalStatement.threshold = 0
        }
        //
      } else if (isComponentTypeMetricsGraph(state.component)) {
        // グラフの書式適用箇所に背景はないので、グラフを指定する
        defaultConditionalStatement.decoration = {
          target: DECORATION_TARGET_GRAPH,
          settings: { color: defaultColor },
        }
        if (isComponentTypeMetricsPieChart(state.component)) {
          // 円グラフのみ参考値が存在しないので、境界値の初期値を定数に変更する
          defaultConditionalStatement.threshold = 0
        }
      }

      state.selectedConditionalStatement = defaultConditionalStatement

      openFormModal()
    }

    const selectToEdit = (conditionalStatement: ConditionalStatementWithContainerPosition<any>) => {
      state.selectedConditionalStatement = conditionalStatement
      openFormModal()
    }

    const onUpdated = async(message: string): Promise<void> => {
      await loadConditionalStatements()
      initializeContainer()
      closeFormModal()
      notifySuccess1(root, message)
    }

    const selectToDelete = (conditionalStatement: ConditionalStatementWithContainerPosition<any>) => {
      state.selectedConditionalStatement = conditionalStatement
      openDeleteModal()
    }

    const openDeleteModal = () => {
      state.showDeleteModal = true
    }

    const closeDeleteModal = () => {
      state.showDeleteModal = false
    }

    const onDeleted = async(message: string): Promise<void> => {
      await loadConditionalStatements()
      initializeContainer()
      closeDeleteModal()
      notifySuccess1(root, message)
    }

    const openCopyModal = () => {
      state.showCopyModal = true
    }

    const closeCopyModal = () => {
      state.showCopyModal = false
    }

    const onCopied = async(message: string): Promise<void> => {
      await loadConditionalStatements()
      initializeContainer()
      closeCopyModal()
      notifySuccess1(root, message)
    }

    const updateOrder = async() => {
      const orderedConditionalStatements = state.conditionalStatementsInSelectedContainer.map((el, index) => {
        el.priority = index + 1
        return el
      })

      try {
        await conditionalStatementApi.bulkUpdatePriority(
          state.urlParams.reportId,
          state.urlParams.componentId,
          state.selectedContainer!.primaryPositionId,
          state.selectedContainer!.secondaryPositionId,
          orderedConditionalStatements,
        )
        // 並び順の変更に関しては成功してもトーストを表示しない
      } catch (err: any) {
        const errStatus = err.response.status
        if ([403, 404].includes(errStatus)) {
          const msg = '操作権限がありません。管理者にお問合せください。'
          reportError(ERROR_GROUP_USER, msg, err, '')
        } else if (errStatus === 409) {
          const msg = '条件付き書式の並び替えに失敗しました。コンポーネントが編集されています。'
          reportError(ERROR_GROUP_USER, msg, err, '')
        } else {
          reportError(ERROR_GROUP_SYSTEM, '', err, '')
        }

        // 並び替えに失敗しても画面上の並びが変更されてしまっているので、リストを初期化する
        loadConditionalStatements()
        initializeContainer()
      }
    }

    const reportError = async(errorGroup: ErrorGroup, message: string, error: any, errorId: string) => {
      const formattedMessage = errorGroup === ERROR_GROUP_SYSTEM
        ? `(ERR: ${state.pageName} ${errorId ? ` ${errorId}` : ''}, user_id:${state.userId})`
        : message

      notifyError1(root, formattedMessage, error)
    }

    onMounted(async() => {
      // Vue 2x 暫定措置 3x系の場合はonUnmountedでフラグを戻す
      // Vue 2x ではonUnmountedがdestroyedに対するフックのエイリアスであるためonMountedの先頭に記述している
      state.isLoaded = false

      // ログインユーザー情報をAPIで再取得
      await ensureUserRefreshAndMasters(root)

      redirectIfNotAuthorized()

      await Promise.all([
        ensureUserRefreshAndMasters(root),
        // 本来はここでコンポーネントの取得APIへリクエストする

        loadConditionalStatements(),
        loadWorkplaces(),
      ])
      // 並行開発の為、暫定的にWebストレージ経由でコンポーネントを取得する
      try {
        const component = JSON.parse(sessionStorage.getItem('Tmp._component') ?? '')
        state.component = component
      } catch {
        location.href = '/settings/reports'
      }

      selectContainer(0)

      state.isLoaded = true
    })

    return {
      state,
      selectContainer,
      onCheckboxChange,
      createNewConditionalStatement,
      selectToEdit,
      closeFormModal,
      selectToDelete,
      closeDeleteModal,
      onDeleted,
      openCopyModal,
      closeCopyModal,
      onCopied,
      updateOrder,
      onUpdated,
      reportError,
      omitString,
    }
  },
})
