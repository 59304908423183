import { TIME_SPAN_DAILY } from 'src/business/timeSpan'
import { SYSTEM_MAX_VALUE } from 'src/consts'
import { Metrics, MetricsType, internal } from 'src/models/new/metrics'
import { Operator } from 'src/util/operator'

const DIRECT_INPUT = internal.DIRECT_INPUT
const LOGIMETER = internal.LOGIMETER
const LOGIBOARD = internal.LOGIBOARD
const CALC = internal.CALC
const SUMMARY = internal.SUMMARY
const CROSS = internal.CROSS
const EXT_CROSS = internal.EXT_CROSS
const REFERENCE = internal.REFERENCE

// FIXME: 計算前の値に対する最大値がシステムの最大値と同じ？
// もしシステム最大値を許容するなら、計算後に確実にシステム最大値にならないようにチェックを入れるか、
// 計算中に最大値を超えた場合は最大値でデータを格納する仕様とする取り決めが必要
export const CALCULATED_METRICS_MAX_OPERAND_VALUE = SYSTEM_MAX_VALUE
export const CALCULATED_METRICS_MIN_OPERAND_VALUE = 0

// metricsIdかconstantのどちらかはnumberで、もう一方はnull
type Operand = {
  metricsId: number | null
  constant: number | null
}

export type CalculatedMetrics = Metrics & {
  operands: Operand[]
  operators: Array<Operator | null>
}

export const isMetricsCalculatedMetrics = (metrics: Metrics): metrics is CalculatedMetrics => {
  return metrics.metricsType === CALC
}

const CALCULATED_METRICS_REFERABLE_METRICS_TYPES = [
  DIRECT_INPUT,
  LOGIMETER,
  LOGIBOARD,
  CALC,
  SUMMARY,
  CROSS,
  EXT_CROSS,
  REFERENCE,
]

export const constructEmptyCalculatedMetrics = (): CalculatedMetrics => {
  return {
    id: 0,
    workplaceId: 0,
    metricsType: CALC,
    name: '',
    timeSpan: TIME_SPAN_DAILY,
    decimalPlaces: 0,
    scaling: 'one',
    unit: null,
    isEnabled: true,
    closingDate: null,
    value: null,
    accessGroupIds: [],
    // FIXME: 実質的には長さが3で中身がnull有なら成立するが、可変長にしておいた方が拡張性が高い
    // 現時点では編集フォームとAPIからの変換が対応していないので初期値を3つ入れてしまっている
    operands: [{ metricsId: null, constant: null }, { metricsId: null, constant: null }, { metricsId: null, constant: null }],
    operators: [],
    updatedAt: null,
  }
}

export const getCalculatedMetricsReferableMetricsTypes = (): MetricsType[] => CALCULATED_METRICS_REFERABLE_METRICS_TYPES
