import reportApi from 'src/apis/masters/report'
import { ref } from 'vue'
import { useUrlParams } from 'src/composables/useUrlParams'
import { Report, constructEmptyReport } from 'src/models/new/report'

const urlParamsTemplate = {
  reportId: 0,
}

export const useReport = () => {
  const reportRef = ref<Report>(constructEmptyReport())

  const {
    enableUrlParams,
    urlParamsRef,
  } = useUrlParams<typeof urlParamsTemplate>()
  enableUrlParams(urlParamsTemplate)

  const loadReport = async() => {
    const reportId = urlParamsRef.value.reportId
    if (reportId) {
      reportRef.value = await reportApi.show(reportId)
    } else {
      console.error('reportId is not in url params')
    }
  }

  return {
    reportRef,
    loadReport,
  }
}
