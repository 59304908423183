// NOTE: 現時点ではcomponentを直接操作するAPIは存在しないが、今後実装が予定されている
// 実装の際は現在のreportの中に含まれる形式と異なることが想定されるが、その際こちらの実装も変更する

import {
  TimeSpan,
  isTimeSpanDaily,
  isTimeSpanMonthly,
  isTimeSpanWeekly,
  isTimeSpanYearly,
} from 'src/business/timeSpan';
import { Component, ComponentType, internal as componentInternal } from 'src/models/new/component';
import { LegacyMetricsResponse } from 'src/models/api/Metrics/metricsResponseData';
import { Comparator } from 'src/util/comparator';
import { PeriodUnit } from 'src/business/period';
import { MetricsTransitionComponentRewindPeriodUnit } from 'src/models/new/Component/MetricsComponent/metricsTransitionComponentProperties';
import { buildMetricsCardComponentProperties } from 'src/models/api/Report/Component/propertyBuilders/buildMetricsCardComponentProperties';
import { buildMetricsListComponentProperties } from 'src/models/api/Report/Component/propertyBuilders/buildMetricsListComponentProperties';
import { buildMetricsPieChartComponentProperties } from 'src/models/api/Report/Component/propertyBuilders/buildMetricsPieChartComponentProperties';
import { DateString } from 'src/models/common';
import { buildMetricsTransitionGraphComponentProperties } from './propertyBuilders/buildMetricsTransitionGraphComponentProperties';
import { buildCommentComponentProperties } from 'src/models/api/Report/Component/propertyBuilders/buildCommentComponentProperties';
import { buildMetricsBarGraphComponentProperties } from './propertyBuilders/buildMetricsBarGraphComponentProperties';
import { buildMetricsGroupedGraphComponentProperties } from './propertyBuilders/buildMetricsGroupedGraphComponentProperties';
import { buildMetricsTableComponentProperties } from 'src/models/api/Report/Component/propertyBuilders/buildMetricsTableComponentProperties';
import { parseYmdDate } from 'src/util/datetime';

const METRICS_CARD = componentInternal.METRICS_CARD;
const METRICS_LIST = componentInternal.METRICS_LIST;
const METRICS_TABLE = componentInternal.METRICS_TABLE;
const METRICS_GRAPH_PIE_CHART = componentInternal.METRICS_GRAPH_PIE_CHART;
const METRICS_GRAPH_BAR_GRAPH = componentInternal.METRICS_GRAPH_BAR_GRAPH;
const METRICS_GRAPH_GROUPED_GRAPH = componentInternal.METRICS_GRAPH_GROUPED_GRAPH;
const METRICS_GRAPH_TRANSITION_GRAPH = componentInternal.METRICS_GRAPH_TRANSITION_GRAPH;
const COMMENT = componentInternal.COMMENT;

// 現状、メトリクス値のデータがコンポーネントに付いてくるのでここで定義している
// 仮実装の為にexportしているので、少なくともView側やmixinのロジックではインポートしないこと
export type SearchedDateMap = Record<string, string[]>;

// 周期に応じた最も新しい日付を抽出する
// 現状は1単語なので条件分岐するまでもないが、バックエンドから届くtimeSpanはスネークケースで
// フロントエンドではキャメルケースなので厳密には互換性がなくロジックとしては変換すべき
export const extractLastSearchedDate = (searchedDateMap: SearchedDateMap, timeSpan: TimeSpan): Date => {
  const convertKey = (timeSpan: TimeSpan): string => {
    if (isTimeSpanDaily(timeSpan)) {
      return 'daily';
    }
    if (isTimeSpanWeekly(timeSpan)) {
      return 'weekly';
    }
    if (isTimeSpanMonthly(timeSpan)) {
      return 'monthly';
    }
    if (isTimeSpanYearly(timeSpan)) {
      return 'yearly';
    }
    throw Error(`No such time span: ${timeSpan}`);
  };
  const key = convertKey(timeSpan);
  return extractLastSearchedDateByRawKey(searchedDateMap, key);
};

// 周期に応じた最も新しい日付を抽出する
// バックエンドのレスポンスに含まれるtime_spanのキーをそのまま使いたい場合はこちらを利用する
export const extractLastSearchedDateByRawKey = (searchedDateMap: SearchedDateMap, key: string): Date => {
  const lastDate = searchedDateMap[key]?.reduce((last, dateString) => {
    const date = parseYmdDate(dateString);
    return !last || date > last ? date : last;
  }, null as Date | null);

  if (!lastDate) {
    console.error('No search date found in response');
    return new Date(0);
  } else {
    return lastDate;
  }
};

// TODO: コンポーネント側にはこの値を含めない
export type LegacyMetricsValueResponseData = {
  id: number; // 使用予定なし
  dt: DateString;
  metrics_id: any; // 使用予定なし
  metrics: any;
  memo: string;
  record_count: any; //  要調査
  status: any; // 要調査
  value: string | number | null; // 数値に変換する
  metrics_value_key: string; // idがない箱を返す場合があるので、その際のキー. dt#metrics_id
  span_start_dt: DateString; // 周期の開始日
  span_end_dt: DateString; // 周期の終了日(締日) == dt
  updated_at: DateString;
};
type SingleValueComponentResponseProperties = {
  id: number;
  component_id: any; // 使用予定なし
  matched_condition_statement_style_maps: any; // 使用予定なし
  metrics: LegacyMetricsResponse;
  metrics_id: any; // 使用予定なし
  sub_metrics?: LegacyMetricsResponse;
  sub_metrics_id: any; // 使用予定なし
  sub_disp_name: string | null;
  target_metrics_value?: LegacyMetricsValueResponseData;
  target_sub_metrics_value?: LegacyMetricsValueResponseData;
  unit_disp_name: string;
  metrics_value_searched_dates_map?: SearchedDateMap;
};

type ListComponentResponseProperties = {
  id: number;
  component_id: number;
  period_unit: PeriodUnit;
  period_value: number | null;
  date_format: string;
  disp_days_rewind_unit: MetricsTransitionComponentRewindPeriodUnit | null;
  disp_days_rewind_value: number | null;
  has_sub_metrics: boolean;
  header: {
    cols: string[][];
    rows: string[][];
  };
  is_descend: boolean;
  is_future_displayed: boolean;
  is_today_displayed: boolean;
  is_unit_indivisual_displayed: boolean;
  is_vertical: boolean;
  metrics_list_components: {
    id: number;
    list_component_id: number;
    metrics_id: number;
    metrics: LegacyMetricsResponse;
    metrics_name: string; // metrics.nameと同じ？
    sequential_order: number;
    is_sub_metrics: boolean;
    matched_condition_statement_style_maps: any; // 使用予定なし
  }[];
  // テーブルの同名のキーと中身の構造が異なるので注意（こちらは配列）
  metrics_values_idx_metrics_id: Record<number, LegacyMetricsValueResponseData[]>;
  metrics_value_searched_dates_map?: SearchedDateMap;
};

type TableComponentResponseProperties = {
  id: number;
  component_id: number;
  header: {
    cols: string[][];
    rows: string[][];
  };
  is_unit_indivisual_displayed: boolean;
  metrics_table_components: {
    id: number;
    table_component_id: number;
    metrics_id: number;
    metrics: LegacyMetricsResponse;
    metrics_name: string; // metrics.nameと同じ？
    row_number: number;
    column_number: number;
    matched_condition_statement_style_maps: any; // 使用予定なし
  }[];
  // リストの同名のキーと中身の構造が異なるので注意（こちらはオブジェクト）
  metrics_values_idx_metrics_id: Record<number, LegacyMetricsValueResponseData>;
  metrics_value_searched_dates_map?: SearchedDateMap;
};

type GraphComponentResponseProperties = {
  id: number;
  component_id: number;
  graph_type1: 'circle' | 'bar' | 'horizontal_bar' | 'line';
  graph_type2: 'bar' | 'line'; // 2軸グラフ（グループグラフまたは推移グラフ）のみ有効
  comparison_type: 'ratio' | 'transition'; // 使わなくてもコンポーネントの種別は判別可能
  date_format: string;
  disp_content: 'rate' | 'num' | 'hidden'; // 円グラフのみ有効、値の表示方法を指定する
  disp_days_rewind_unit: MetricsTransitionComponentRewindPeriodUnit | null; // 推移グラフのみ有効、型の詳細は未確認
  disp_days_rewind_value: number | null; // 推移グラフのみ有効、型の詳細は未確認
  is_descend: boolean; // 推移グラフのみ有効
  is_future_displayed: boolean; // 未実装
  is_today_displayed: boolean; // 推移グラフのみ有効
  legend_name_l1: string | null;
  legend_name_l2: string | null;
  legend_name_r1: string | null;
  legend_name_r2: string | null;
  metrics_graph_components: {
    disp_color: string;
    disp_name: string | null;
    graph_component_id: number;
    graph_no: 'l1' | 'l2' | 'r1' | 'r2';
    id: number;
    metrics: LegacyMetricsResponse;
    metrics_id: number;
    metrics_name: string; // metrics.nameと同じ？
    registration_order: number; // 登録順、円グラフのみ別途表示順がソートされるが、それ以外は表示順と同じ
  }[];
  period_unit: PeriodUnit | null; // 推移グラフのみ有効
  period_value: number | null; // 推移グラフのみ有効
  sub_disp_color: string | null; // 詳細不明
  sub_disp_name: string | null; // 詳細不明
  sub_metrics_id: number | null; // 詳細不明
  sub_metrics: LegacyMetricsResponse | null;
  unit_disp_name1: string | null;
  unit_disp_name2: string | null;
  // テーブル、リストの場合とキー名が異なるので注意
  metrics_values_idx_dt: Record<string, LegacyMetricsValueResponseData[]>; // キーのstringはYYYY-MM-DD形式
  metrics_value_searched_dates_map?: SearchedDateMap;
};

type CommentComponentResponseProperties = {
  id: number;
  component_id: any;
  component_size: number; // 未使用
  placeholder: string;
  target_comment_component_value?: { content: string };
};

type ConditionalStatementData = {
  id: number;
  row_number: number;
  column_number: number;
  base_type: string;
  base_metrics:
    | (LegacyMetricsResponse & {
        values_idx_dt?: Record<string, LegacyMetricsValueResponseData>; // キーのstringはYYYY-MM-DD形式
      })
    | null;
  base_value: number | null;
  comparator: Comparator;
  disp_order: number;
  threshold_type: 'main_metrics' | 'sub_metrics' | 'other_metrics' | 'const';
  threshold_metrics:
    | (LegacyMetricsResponse & {
        values_idx_dt?: Record<string, LegacyMetricsValueResponseData>; // キーのstringはYYYY-MM-DD形式
      })
    | null;
  threshold_value: number | null;
  style_map: {
    color: string;
    font_weight: string;
    format_type: string;
    icon: string;
    version: number;
  };
};

export type ComponentResponseData = {
  id: number;
  component_type: string;
  section_id: number;
  name: string;
  abscissa: number;
  ordinate: number;
  width: number;
  height: number;
  component_conditional_statements: ConditionalStatementData[];
  // component_link_ids: number[]
  single_value_component?: SingleValueComponentResponseProperties;
  table_component?: TableComponentResponseProperties;
  list_component?: ListComponentResponseProperties;
  graph_component?: GraphComponentResponseProperties;
  comment_component?: CommentComponentResponseProperties;
  includes_links?: boolean;
};

// 本来はレスポンスの各データを引数に渡すが、当面はReportApiのレスポンスに含まれるcomponentを渡して変換する
// referenceDateは現時点でレスポンスから取得不能なので別途受け取るが、最終的には削除する
// timeSpanは現時点でレスポンスから取得不能なので別途受け取るが、最終的には引数から削除する
export const convertFromComponentResponseData = (
  data: ComponentResponseData,
  referenceDate: Date | null,
): Component => {
  const componentType = computeComponentType(data);
  switch (componentType) {
    case METRICS_CARD:
      return buildMetricsCardComponentProperties(data, referenceDate);
    case METRICS_LIST:
      return buildMetricsListComponentProperties(data, referenceDate);
    case METRICS_TABLE:
      return buildMetricsTableComponentProperties(data, referenceDate);
    case METRICS_GRAPH_PIE_CHART:
      return buildMetricsPieChartComponentProperties(data, referenceDate);
    case METRICS_GRAPH_BAR_GRAPH:
      return buildMetricsBarGraphComponentProperties(data, referenceDate);
    case METRICS_GRAPH_GROUPED_GRAPH:
      return buildMetricsGroupedGraphComponentProperties(data, referenceDate);
    case METRICS_GRAPH_TRANSITION_GRAPH:
      return buildMetricsTransitionGraphComponentProperties(data, referenceDate);
    case COMMENT:
      return buildCommentComponentProperties(data);
    default:
      throw Error(`No such component type: ${componentType}`);
  }
};

const computeComponentType = (data: ComponentResponseData): ComponentType => {
  if (data.component_type === 'card' && data.single_value_component) {
    return METRICS_CARD;
  }
  if (data.component_type === 'list' && data.list_component) {
    return METRICS_LIST;
  }
  if (data.component_type === 'table' && data.table_component) {
    return METRICS_TABLE;
  }
  if (data.component_type === 'graph' && data.graph_component && data.graph_component.graph_type1 === 'circle') {
    return METRICS_GRAPH_PIE_CHART;
  }
  if (
    data.component_type === 'graph' &&
    data.graph_component &&
    data.graph_component.graph_type1 === 'horizontal_bar'
  ) {
    return METRICS_GRAPH_BAR_GRAPH;
  }
  if (
    data.component_type === 'graph' &&
    data.graph_component &&
    data.graph_component.comparison_type === 'ratio' &&
    ['bar', 'line'].includes(data.graph_component.graph_type1)
  ) {
    return METRICS_GRAPH_GROUPED_GRAPH;
  }
  if (
    data.component_type === 'graph' &&
    data.graph_component &&
    data.graph_component.comparison_type === 'transition'
  ) {
    return METRICS_GRAPH_TRANSITION_GRAPH;
  }
  if (data.component_type === 'comment' && data.comment_component) {
    return COMMENT;
  }
  throw Error('This is not valid component data.');
};
