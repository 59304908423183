import { Metrics } from 'src/models/new/metrics'
import { MetricsCreateRequestParameters, convertToMetricsCreateResponse } from './metricsCreateRequestParameters'

export type MetricsUpdateRequestParameters = MetricsCreateRequestParameters & {
  id: number
}

export const convertToMetricsUpdateResponse = (metrics: Metrics): MetricsUpdateRequestParameters => {
  const params = convertToMetricsCreateResponse(metrics)
  return {
    ...params,
    id: metrics.id,
  }
}
