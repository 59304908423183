
import { computed, defineComponent, getCurrentInstance, inject, onMounted, PropType, reactive } from 'vue'
import { omitString } from 'src/util/text_decorator'
import { COMPARATOR_LABELS, Comparator, isComparatorIsNull } from 'src/util/comparator'
import { classFromIcon, Icon, iconClasses } from 'src/util/icon'
import {
  ConditionalStatement,
  ConditionalStatementWithContainerPosition,
  isConditionalStatementBaseConstant,
  isConditionalStatementBaseMetrics,
  isConditionalStatementBaseReference,
  isConditionalStatementBaseSelf,
  isConditionalStatementComparatorIsNull,
  isConditionalStatementThresholdConstant,
  isConditionalStatementThresholdMetrics,
  isConditionalStatementThresholdReference,
} from 'src/models/new/conditionalStatement'
import { Metrics } from 'src/models/new/metrics'
import { TEXT_DECORATION_FONT_WEIGHT_LOCAL_WORDS, TextDecorationFontWeight } from 'src/models/new/ConditionalStatement/Decorations/textDecoration'
import SelectItemForm, { ITEM_TYPE_METRICS } from 'src/components/SelectItemForm/index.vue'
import IconSelectionForm from 'src/views/Dashboard/Settings/Reports/Components/ConditionalStatements/components/ConditionalStatementFormModal/IconSelectionForm.vue'
import conditionalStatementApi from 'src/apis/conditionalStatement'
import { ERROR_GROUP_SYSTEM, ERROR_GROUP_USER, SYSTEM_MAX_VALUE } from 'src/consts'
import workplaceApi from 'src/apis/masters/workplace'
import { Workplace } from 'src/models/new/workplace'
import { isRecordIdMeansNotPersisted } from 'src/util/recordId'
import { vvGetError, vvGetErrorObject, vvHasError, vvReset, vvValidate } from 'src/util/vee_validate'
import InputError from 'src/components/InputError.vue'

export type ConditionalStatementInput = {
  baseType: 'self' | 'reference' | 'metrics' | 'constant' | null
  baseValue: number | null
  baseMetrics: Metrics | null
  baseMetricsId: number | null
  thresholdType: 'reference' | 'metrics' | 'constant' | null
  thresholdValue: number | null
  thresholdMetrics: Metrics | null
  thresholdMetricsId: number | null
  comparator: Comparator | null
  target: string | null
  color: string | null
  fontWeight: string | null
  icon: Icon | null
}

const getBaseType = (statement: ConditionalStatement<any>): 'self' | 'reference' | 'metrics' | 'constant' => {
  if (isConditionalStatementBaseSelf(statement)) {
    return 'self'
  } else if (isConditionalStatementBaseReference(statement)) {
    return 'reference'
  } else if (isConditionalStatementBaseConstant(statement)) {
    return 'constant'
  } else if (isConditionalStatementBaseMetrics(statement)) {
    return 'metrics'
  } else {
    throw new Error('invalid conditional statement base type.')
  }
}
const getThresholdType = (statement: ConditionalStatement<any>): 'reference' | 'metrics' | 'constant' | null => {
  if (isConditionalStatementComparatorIsNull(statement)) return null

  if (isConditionalStatementThresholdReference(statement)) {
    return 'reference'
  } else if (isConditionalStatementThresholdConstant(statement)) {
    return 'constant'
  } else if (isConditionalStatementThresholdMetrics(statement)) {
    return 'metrics'
  } else {
    throw new Error('invalid conditional statement threshold type.')
  }
}

export const convertConditionalStatementToInput = (statement: ConditionalStatement<any>): ConditionalStatementInput => {
  const baseType = getBaseType(statement)
  const baseValue = baseType === 'constant' ? statement.base as number : null
  // baseTypeによって型は一意に絞り込まれるが、型システム上は判別できないためMetricsへのキャストが正しくないものとされる
  // unknownを経由してMetricsにキャストする
  const baseMetrics = baseType === 'metrics' ? statement.base as unknown as Metrics : null
  const thresholdType = getThresholdType(statement)
  const thresholdValue = thresholdType === 'constant' ? statement.threshold as number : null
  const thresholdMetrics = thresholdType === 'metrics' ? statement.threshold as unknown as Metrics : null

  return {
    baseType: baseType,
    baseValue: baseValue,
    baseMetrics: baseMetrics,
    baseMetricsId: baseMetrics?.id ?? null,
    thresholdType: thresholdType,
    thresholdValue: thresholdValue,
    thresholdMetrics: thresholdMetrics,
    thresholdMetricsId: thresholdMetrics?.id ?? null,
    comparator: statement.comparator,
    target: statement.decoration.target,
    color: statement.decoration.settings.color,
    fontWeight: statement.decoration.settings.fontWeight,
    icon: statement.decoration.settings.icon,
  }
}

const getBase = (input: ConditionalStatementInput): number | Metrics | 'self' | 'reference' => {
  if (input.baseType === 'self') {
    return 'self'
  } else if (input.baseType === 'reference') {
    return 'reference'
  } else if (input.baseType === 'constant' && input.baseValue !== null) {
    return input.baseValue
  } else if (input.baseType === 'metrics' && input.baseMetrics !== null) {
    return input.baseMetrics
  } else {
    throw new Error('base is not completed.')
  }
}
const getThreshold = (input: ConditionalStatementInput): number | Metrics | 'reference' => {
  if (input.thresholdType === 'reference') {
    return 'reference'
  } else if (input.thresholdType === 'constant' && input.thresholdValue !== null) {
    return input.thresholdValue
  } else if (input.thresholdType === 'metrics' && input.thresholdMetrics !== null) {
    return input.thresholdMetrics
  } else {
    throw new Error('threshold is not completed.')
  }
}

// FIXME: 型がanyになっているので、時間があれば直す
const buildDecoration = (input: ConditionalStatementInput): ConditionalStatement<any>['decoration'] => {
  const target = input.target
  const color = input.color
  const fontWeight = input.fontWeight
  const icon = input.icon

  if (target === 'text') {
    return {
      target: target,
      settings: {
        color: color,
        fontWeight: fontWeight,
      },
    }
  } else if (target === 'icon') {
    return {
      target: target,
      settings: {
        color: color,
        icon: icon,
      },
    }
  } else if (target === 'background') {
    return {
      target: target,
      settings: {
        color: color,
      },
    }
  } else {
    return {
      target: target,
      settings: {
        color: color,
      },
    }
  }
}

export const isInputCompleted = (input: ConditionalStatementInput): boolean => {
  try {
    getBase(input)
    if (!input.comparator) return false
    !isComparatorIsNull(input.comparator) && getThreshold(input)
  } catch (e) {
    return false
  }

  if (!input.target) return false
  if (!input.color) return false
  if (input.target === 'icon' && !input.icon) return false
  if (input.target === 'text' && !input.fontWeight) return false
  return true
}

// ConditionalStatementを入力から生成する
export const convertConditionalStatementPropertiesFromInput = (
  input: ConditionalStatementInput,
): Omit<ConditionalStatement<any>, 'id' | 'priority' | 'metricsList'> => {
  const base = getBase(input)
  const comparator = input.comparator!
  const threshold = !isComparatorIsNull(comparator) ? getThreshold(input) : null
  const decoration = buildDecoration(input)

  return { base, threshold, comparator, decoration }
}

type ComparatorOption = {
  value: Comparator
  label: string
}

const COMPARATOR_OPTIONS: ComparatorOption[] = Object.keys(COMPARATOR_LABELS).map(el => {
  return {
    value: el as Comparator,
    label: COMPARATOR_LABELS[el as Comparator],
  }
})

type FontWeightOption = {
  value: TextDecorationFontWeight
  label: string
}

const FONT_WEIGHT_OPTIONS: FontWeightOption[] = Object.keys(TEXT_DECORATION_FONT_WEIGHT_LOCAL_WORDS).map(el => {
  return {
    value: el as TextDecorationFontWeight,
    label: TEXT_DECORATION_FONT_WEIGHT_LOCAL_WORDS[el as TextDecorationFontWeight],
  }
})

export type OperandPosition = 'base' | 'threshold'

type BaseTypeOption = {
  value: 'self' | 'reference' | 'metrics' | 'constant'
  label: string
}

type ThresholdTypeOption = {
  value: 'reference' | 'metrics' | 'constant'
  label: string
}

type StringOption = {
  value: string
  label: string
}

type State = {
  isReady: boolean

  hasError: boolean
  validations: Record<string, object>

  conditionalStatement: ConditionalStatementWithContainerPosition<any>
  input: ConditionalStatementInput
  // バリデーションのためにinputの値を参照する値
  inputBaseMetricsId: number | null
  inputThresholdMetricsId: number | null

  workplaceOptions: Workplace[]

  isBaseMetrics: boolean
  formerFormLayoutClass: string
  showBaseValueInput: boolean
  showBaseMetricsInput: boolean
  isComparatorIsNull: boolean
  showThresholdValueInput: boolean
  showThresholdMetricsInput: boolean
  showFontWeightInput: boolean
  showIconInput: boolean

  showMetricsSelectionForm: boolean
  showIconSelectionForm: boolean

  selectMetricsFor: 'base' | 'threshold' | null
  // Form都の受け渡しに使用する
  selectedMetrics: Metrics | null
  selectedIcon: Icon | null
}

const dummyInputData: ConditionalStatementInput = {
  baseType: 'self',
  baseValue: null,
  baseMetrics: null,
  baseMetricsId: null,
  thresholdType: 'constant',
  thresholdValue: 0,
  thresholdMetrics: null,
  thresholdMetricsId: null,
  // FIXME: 暫定対応、Comparatorで定義して使う方がベター
  comparator: 'gt',
  target: null,
  color: null,
  fontWeight: null,
  icon: null,
}

export default defineComponent({
  components: {
    SelectItemForm,
    IconSelectionForm,
    InputError,
  },
  props: {
    value: {
      type: Object as PropType<ConditionalStatementWithContainerPosition<any>>,
      required: true,
    },
    reportId: {
      type: Number,
      required: true,
    },
    componentId: {
      type: Number,
      required: true,
    },
    availableBaseTypeOptions: {
      type: Array as PropType<BaseTypeOption[]>,
      required: true,
    },
    availableThresholdTypeOptions: {
      type: Array as PropType<ThresholdTypeOption[]>,
      required: true,
    },
    availableTargetOptions: {
      type: Array as PropType<StringOption[]>,
      required: true,
    },
  },
  emits: ['input', 'updated', 'close', 'shouldReport'],
  setup(props, { emit }) {
    const root = getCurrentInstance()!.proxy.$root!
    const state: State = reactive({
      isReady: false,

      hasError: computed(() => vvHasError(root)),
      validations: computed(() => {
        const metricsIdValidation = { required: true }
        const constantValueValidation = { required: true, floatWithDecimalPlaces: true }
        const validations = {}
        if (state.input.baseType === 'metrics') {
          Object.assign(validations, { baseMetricsId: metricsIdValidation })
        } else if (state.input.baseType === 'constant') {
          Object.assign(validations, { baseValue: constantValueValidation })
        }
        if (state.input.thresholdType === 'metrics') {
          Object.assign(validations, { thresholdMetricsId: metricsIdValidation })
        } else if (state.input.thresholdType === 'constant') {
          Object.assign(validations, { thresholdValue: constantValueValidation })
        }
        return validations
      }),
      conditionalStatement: computed({
        get() {
          return props.value
        },
        set(value) {
          emit('input', { ...value })
        },
      }),
      input: structuredClone(dummyInputData),
      inputBaseMetricsId: computed(() => state.input.baseMetrics?.id ?? null),
      inputThresholdMetricsId: computed(() => state.input.thresholdMetrics?.id ?? null),

      workplaceOptions: [],
      showMetricsSelectionForm: false,
      showIconSelectionForm: false,
      selectMetricsFor: null,
      isBaseMetrics: true,
      formerFormLayoutClass: computed(() => {
        if (state.input.baseType === 'constant') return 'use-base-type-const'
        if (state.input.baseType === 'metrics') return 'use-base-type-metrics'
        return ''
      }),
      showBaseValueInput: computed(() => state.input.baseType === 'constant'),
      showBaseMetricsInput: computed(() => state.input.baseType === 'metrics'),
      isComparatorIsNull: computed(() => !!state.input.comparator && isComparatorIsNull(state.input.comparator)),
      showThresholdValueInput: computed(() => state.input.thresholdType === 'constant'),
      showThresholdMetricsInput: computed(() => state.input.thresholdType === 'metrics'),
      showFontWeightInput: computed(() => state.input.target === 'text'),
      showIconInput: computed(() => state.input.target === 'icon'),

      selectedMetrics: null,
      selectedIcon: null,
    })

    const getError = (fieldName: string): string | null => vvGetError(root, fieldName)
    const getErrorObject = (fieldName: string): object | null => vvGetErrorObject(root, fieldName)
    const clearErrors = (): void => {
      vvReset(root)
    }
    const clearError = (field: string): void => {
      vvReset(root, field)
    }

    const syncModel = (): void => {
      if (isInputCompleted(state.input)) {
        state.conditionalStatement = {
          ...convertConditionalStatementPropertiesFromInput(state.input),
          id: state.conditionalStatement.id,
          primaryPositionId: state.conditionalStatement.primaryPositionId,
          secondaryPositionId: state.conditionalStatement.secondaryPositionId,
          priority: state.conditionalStatement.priority,
          metricsList: state.conditionalStatement.metricsList,
        }
      }
    }

    const onComparatorChange = (): void => {
      if (state.isComparatorIsNull) {
        state.input.thresholdType = null
        state.input.thresholdValue = null
        state.input.thresholdMetrics = null
        clearError('thresholdValue')
        clearError('thresholdMetrics')
      } else if (state.input.thresholdType === null) {
        state.input.thresholdType = props.availableThresholdTypeOptions[0].value
        clearError('thresholdValue')
        clearError('thresholdMetrics')
      }
      syncModel()
    }

    const onBaseTypeChange = (): void => {
      state.input.baseValue = null
      state.input.baseMetrics = null
      clearError('baseValue')
      clearError('baseMetrics')
      syncModel()
    }

    const onBaseValueChange = (): void => {
      if (state.input.baseValue && state.input.baseValue < -SYSTEM_MAX_VALUE) {
        state.input.baseValue = -SYSTEM_MAX_VALUE
      } else if (state.input.baseValue && state.input.baseValue > SYSTEM_MAX_VALUE) {
        state.input.baseValue = SYSTEM_MAX_VALUE
      }
      syncModel()
    }

    const onThresholdTypeChange = (): void => {
      state.input.thresholdValue = null
      state.input.thresholdMetrics = null
      clearError('thresholdValue')
      clearError('thresholdMetrics')
      syncModel()
    }

    const onThresholdValueChange = (): void => {
      if (state.input.thresholdValue && state.input.thresholdValue < -SYSTEM_MAX_VALUE) {
        state.input.thresholdValue = -SYSTEM_MAX_VALUE
      } else if (state.input.thresholdValue && state.input.thresholdValue > SYSTEM_MAX_VALUE) {
        state.input.thresholdValue = SYSTEM_MAX_VALUE
      }
      syncModel()
    }

    const onDecorationTargetChange = (): void => {
      // 書式の適用対象を変更した際、値がなければデフォルト値を設定する
      if (state.input.target === 'text' && !state.input.fontWeight) {
        state.input.fontWeight = 'normal'
      }
      if (state.input.target === 'icon' && !state.input.icon) {
        state.input.icon = 'fa-sun'
      }
      syncModel()
    }

    const selectBaseMetrics = (): void => {
      state.selectedMetrics = state.input.baseMetrics
      state.selectMetricsFor = 'base'
      goToMetricsSelectionForm()
    }

    const selectThresholdMetrics = (): void => {
      state.selectedMetrics = state.input.thresholdMetrics
      state.selectMetricsFor = 'threshold'
      goToMetricsSelectionForm()
    }

    const selectIcon = (): void => {
      state.selectedIcon = state.input.icon
      goToIconSelectionForm()
    }

    const goToMetricsSelectionForm = (): void => {
      state.showMetricsSelectionForm = true
      state.showIconSelectionForm = false
    }

    const goToIconSelectionForm = (): void => {
      state.showIconSelectionForm = true
      state.showMetricsSelectionForm = false
    }

    const backToMainForm = (): void => {
      state.selectedMetrics = null
      state.selectedIcon = null

      state.selectMetricsFor = null

      state.showMetricsSelectionForm = false
      state.showIconSelectionForm = false
    }

    const setSelectedMetrics = async(metrics: Metrics[]): Promise<void> => {
      const selectedMetrics = metrics[0] ?? null

      if (state.selectMetricsFor === 'base') {
        state.input.baseMetrics = selectedMetrics
        state.input.baseMetricsId = selectedMetrics?.id ?? null
      } else if (state.selectMetricsFor === 'threshold') {
        state.input.thresholdMetrics = selectedMetrics
        state.input.thresholdMetricsId = selectedMetrics?.id ?? null
      }

      backToMainForm()
      syncModel()
    }

    const setSelectedIcon = (icon: Icon | null): void => {
      state.input.icon = icon

      backToMainForm()
      syncModel()
    }

    const close = (): void => { emit('close') }
    const register = async(): Promise<void> => {
      if (!(await vvValidate(root))) return
      clearErrors()

      isRecordIdMeansNotPersisted(state.conditionalStatement.id) ? create() : update()
    }
    const create = async(): Promise<void> => {
      try {
        await conditionalStatementApi.create(props.reportId, props.componentId, state.conditionalStatement)
        emit('updated', '条件付き書式を作成しました。')
      } catch (err: any) {
        const errStatus = err.response.status
        const errData = err.response?.data ?? {}
        if ([403, 404].includes(errStatus)) {
          const msg = '作成権限がありません。管理者にお問合せください。'
          emit('shouldReport', ERROR_GROUP_USER, msg, err)
        } else if (errStatus === 409 && errData.reason === 'position_not_exist') {
          const msg = '条件付き書式の更新に失敗しました。コンポーネントが編集されています。'
          emit('shouldReport', ERROR_GROUP_USER, msg, err)
        } else if (errStatus === 409 && errData.reason === 'exceed_max_for_position') {
          const msg = '条件付き書式の更新に失敗しました。画面をリロードして再度お試しください。'
          emit('shouldReport', ERROR_GROUP_USER, msg, err)
        } else if (errStatus === 400) {
          const msg = '条件付き書式の更新に失敗しました。管理者に連絡してください。'
          emit('shouldReport', ERROR_GROUP_USER, msg, err)
        } else {
          emit('shouldReport', ERROR_GROUP_SYSTEM, '', err)
        }
      }
    }
    const update = async(): Promise<void> => {
      try {
        await conditionalStatementApi.update(props.reportId, props.componentId, state.conditionalStatement)
        emit('updated', '条件付き書式を更新しました。')
      } catch (err: any) {
        const errStatus = err.response.status
        if ([403, 404].includes(errStatus)) {
          const msg = '変更権限がありません。管理者にお問合せください。'
          emit('shouldReport', ERROR_GROUP_USER, msg, err)
        } else if (errStatus === 400) {
          const msg = '条件付き書式の更新に失敗しました。管理者に連絡してください。'
          emit('shouldReport', ERROR_GROUP_USER, msg, err)
        } else {
          emit('shouldReport', ERROR_GROUP_SYSTEM, '', err)
        }
      }
    }

    onMounted(async() => {
      // Vue 2x 暫定措置 3x系の場合はonUnmountedでフラグを戻す
      // Vue 2x ではonUnmountedがdestroyedに対するフックのエイリアスであるためonMountedの先頭に記述している
      state.isReady = false
      state.showMetricsSelectionForm = false
      state.showIconSelectionForm = false
      state.selectMetricsFor = null
      state.selectedMetrics = null
      state.selectedIcon = null

      state.workplaceOptions = inject<() => Workplace[]>('injectWorkplaces', () => [])()
      // 親からのprovideがない場合や、取得に失敗している場合にはAPIから取得する
      if (state.workplaceOptions.length === 0) {
        state.workplaceOptions = await workplaceApi.index()
      }

      state.input = convertConditionalStatementToInput(state.conditionalStatement)

      state.isReady = true
    })

    const COLOR_OPTIONS = window.master.lovs.color_set.vals.filter(e => e.group === 'dark')

    return {
      props,
      state,
      omitString,
      getError,
      getErrorObject,
      SYSTEM_MAX_VALUE,
      COMPARATOR_OPTIONS,
      COLOR_OPTIONS,
      FONT_WEIGHT_OPTIONS,
      syncModel,
      onComparatorChange,
      isComparatorIsNull,
      onBaseTypeChange,
      onThresholdTypeChange,
      onBaseValueChange,
      onThresholdValueChange,
      onDecorationTargetChange,
      close,
      register,
      selectBaseMetrics,
      selectThresholdMetrics,
      selectIcon,
      classFromIcon,
      ITEM_TYPE_METRICS,
      goToMetricsSelectionForm,
      goToIconSelectionForm,
      backToMainForm,
      iconClasses,
      setSelectedMetrics,
      setSelectedIcon,
    }
  },
})
