export const DEFAULT_DAY_START_HOUR = 7

export const USER_WORKPLACE_ROLE_W_OWNER = 'w_owner'
export const USER_WORKPLACE_ROLE_W_LEADER = 'w_leader'
export const USER_WORKPLACE_ROLE_W_MEMBER = 'w_member'

export const USER_WORKPLACE_STATUS_SHARED = '01'
export const USER_WORKPLACE_STATUS_PENDING = '11'
export const USER_WORKPLACE_STATUS_DECLINED = '12'

export const ROUTER_DT_PARAM_TODAY = 'today'

export const CODE_READER_TYPE_BARCODE = 'barcode'
export const CODE_READER_TYPE_QRCODE = 'qrcode'

export const QUANTITY_CALC_TYPE_DIRECT_INPUT = '1'
export const QUANTITY_CALC_TYPE_SUM = '2'

export const WORK_START_TIME_INPUT_TYPE_NOT_ROUNDED = '1'
export const WORK_START_TIME_INPUT_TYPE_ROUNDED_UP = '2'
export const WORK_START_TIME_INPUT_TYPE_ROUNDED_DOWN = '3'
export const WORK_END_TIME_INPUT_TYPE_NOT_ROUNDED = '1'
export const WORK_END_TIME_INPUT_TYPE_ROUNDED_UP = '2'
export const WORK_END_TIME_INPUT_TYPE_ROUNDED_DOWN = '3'
export const ACTIVITY_TIME_INPUT_TYPE_NOT_ROUNDED = '1'
export const ACTIVITY_TIME_INPUT_TYPE_ROUNDED_UP = '2'
export const ACTIVITY_TIME_INPUT_TYPE_ROUNDED_DOWN = '3'

// シンプルモード_QRあり
export const TABLET_PATTERN_INPUT_MODE_CONTINUAL = 1
// 詳細モード（旧）
export const TABLET_PATTERN_INPUT_MODE_DETAIL = 2
// シンプルモード_QRなし
export const TABLET_PATTERN_INPUT_MODE_CONTINUAL_NO_QR_CODE = 3
// 詳細モード
export const TABLET_PATTERN_INPUT_MODE_DETAIL_2 = 4

// 検索日付指定
// 62日間選択可能で、MINとMAXの差が61
export const SEARCH_MAX_DATE_RANGE_1_WEEK = 7
export const SEARCH_MAX_DATE_RANGE_1_MONTH = 31
export const SEARCH_MAX_DATE_RANGE_2_MONTHS = 62

// データ画面表示件数
export const DATA_DISPLAY_ITEM_COUNT = 5000
// データ画面一括変更可能件数
export const DATA_MAX_BULK_UPDATE_ITEM_COUNT = 50

// 周期
export const TIME_SPAN_DAILY = 'daily'
export const TIME_SPAN_WEEKLY = 'weekly'
export const TIME_SPAN_MONTHLY = 'monthly'
export const TIME_SPAN_YEARLY = 'yearly'
export type TimeSpan = typeof TIME_SPAN_DAILY | typeof TIME_SPAN_WEEKLY | typeof TIME_SPAN_MONTHLY | typeof TIME_SPAN_YEARLY
export type TimeSpansType = { value: TimeSpan, name: string, weight: number }[]
export const TIME_SPANS: TimeSpansType = [
  { value: TIME_SPAN_DAILY, name: '日次', weight: 1 },
  { value: TIME_SPAN_WEEKLY, name: '週次', weight: 2 },
  { value: TIME_SPAN_MONTHLY, name: '月次', weight: 3 },
  { value: TIME_SPAN_YEARLY, name: '年次', weight: 4 },
]
// エラーグループ
export const ERROR_GROUP_USER = 'user_error'
export const ERROR_GROUP_SYSTEM = 'system_error'
export type ErrorGroup = typeof ERROR_GROUP_USER | typeof ERROR_GROUP_SYSTEM

// エラーコード
export const ERROR_REASON_INVALID_PARAMS = 'invalid_params'
export const ERROR_REASON_MAX_PROCESSING_JOBS = 'max_processing_jobs'
export const ERROR_REASON_INVALID = 'invalid'
export const ERROR_REASON_METRICS_NOT_FOUND = 'metrics_not_found'
export const ERROR_REASON_ACCESS_GROUP_NOT_FOUND = 'access_group_not_found'
export const ERROR_REASON_LOGISCOPE_WORKPLACE_NOT_FOUND = 'logiscope_workplace_not_found'
export const ERROR_REASON_IN_USE = 'in_use'
export const ERROR_REASON_INCLUDED_FORBIDDEN_METRICS = 'included_forbidden_metrics'
export const ERROR_REASON_CANNOT_REMOVE_ADMIN_ROLE = 'cannot_remove_admin_role'
export const ERROR_REASON_INTEGRITY_VIOLATION = 'integrity_violation'
export const ERROR_REASON_UNIQUE = 'unique'
export const ERROR_REASON_FILE_EXPIRED = 'file_expired'

// システム上の制約
export const SYSTEM_MAX_VALUE = 9999999999999.99
export const SYSTEM_MAX_DECIMAL_PLACES = 2
