import { ComputedRef, computed, getCurrentInstance, ref } from 'vue'

/**
 * useUrlParameters
 * Usage:
 *
 * Declare template of url parameters you want to use in your component.
 *    const urlParamsTemplate = {
 *      reportId: 0,
 *    }
 *
 * Import enableUrlParams function and computed urlParamsRef.
 *    const { enableUrlParams, urlParamsRef } = useUrlParams<typeof urlParamsTemplate>()
 *
 * Call enableUrlParams function with urlParamsTemplate.
 *    enableUrlParams(urlParamsTemplate)
 *
 * Now urlParamsRef is enabled.
 */

// FIXME: useUrlParamsでテンプレートの型を指定、enableUrlParamsでテンプレートを指定する
// イマイチなインターフェースだが、型から値を生成できないので型安全のためには現状このような実装とした

type UrlParamsTemplate = Record<string, string | number>

export const useUrlParams = <T extends UrlParamsTemplate>() => {
  const enabledParamsRef = ref({} as T)

  const enableUrlParams = (params: T) => {
    enabledParamsRef.value = params
  }
  const vue = getCurrentInstance()!.proxy
  const urlParamsRef: ComputedRef<T> = computed(() => {
    const declaration = enabledParamsRef.value
    const params = Object.keys(declaration) as Array<keyof T>
    return Object.fromEntries(
      params.map(param => {
        const rawValue = vue.$route.params[param as string]
        const value = typeof declaration[param] === 'number' ? Number(rawValue) : rawValue
        return [param, value]
      }),
    ) as T
  })

  return {
    enableUrlParams,
    urlParamsRef,
  }
}
